const config = {
  NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
  NEXT_PUBLIC_ORGANIZATION: process.env.NEXT_PUBLIC_ORGANIZATION,
  NEXT_PUBLIC_DOMAIN: process.env.NEXT_PUBLIC_DOMAIN,
  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  NEXT_PUBLIC_HASURA_SUBSCRIPTION_URL: `${process.env.NEXT_PUBLIC_HASURA_SUBSCRIPTION_PROTOCOL}://${process.env.NEXT_PUBLIC_HASURA_SUBSCRIPTION_LOCATION}`,
  NEXT_PUBLIC_HASURA_SCHEMA_LOCATION:
    process.env.NEXT_PUBLIC_HASURA_SCHEMA_LOCATION,
  NEXT_PUBLIC_HASURA_GRAPHQL_ADMIN_SECRET:
    process.env.NEXT_PUBLIC_HASURA_GRAPHQL_ADMIN_SECRET,
  NEXT_PUBLIC_SERVER_PREFIX: process.env.NEXT_PUBLIC_SERVER_PREFIX,
  NEXT_PUBLIC_AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  NEXT_PUBLIC_AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  NEXT_PUBLIC_SENTRY_PROJECT_ID: process.env.NEXT_PUBLIC_SENTRY_PROJECT_ID,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_MAPBOX_TOKEN: process.env.NEXT_PUBLIC_MAPBOX_TOKEN,
  NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
  NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
};

export default config;
