import "../styles/tailwind.css";

import { ApolloProvider } from "@apollo/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { createTheme, ThemeProvider } from "@mui/material";
import App, { AppContext, AppProps } from "next/app";
import { SnackbarProvider } from "notistack";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import { Provider as ReduxProvider } from "react-redux";

import {
  FetchOrganizationIdBySlugDocument,
  FetchOrganizationIdBySlugQuery,
  FetchOrganizationIdBySlugQueryVariables,
} from "../api/generated";
import config from "../common/config";
import Alert from "../components/shared/Alert";
import AuthWrapper from "../components/shared/AuthWrapper";
import IntlWrapper from "../components/shared/IntlWrapper";
import Loading from "../components/shared/Loading";
import Notifier from "../components/shared/Notifier";
import OrganizationLoader from "../components/shared/OrganizationLoader";
import ProtectedRoutes from "../components/shared/ProtectedRoutes";
import { getConstantsForOrganization } from "../constants";
import { KaizenAppConstantsProvider } from "../providers/KaizenAppConstantsProvider";
import ApolloService from "../services/ApolloService";
import PostHogClient from "../services/posthog";
import SentryClient from "../services/sentry";
import { usePersistedStore } from "../store/configureStore";
import {
  SetOrganization,
  SetOrganizationID,
  SetOrganizationResidentZipCodes,
  SetOrganizationStorageBuckets,
  SetOrganizationTimeZone,
} from "../store/organization";
import { isProdEnvironment } from "../utils/feature_flag_utils";
import { constructStorageUrl } from "../utils/file_utils";
import logger from "../utils/logger";
import { getOrganizationFromUrl } from "../utils/next_server_utils";
import { useScrollToTop } from "../utils/scroll_utils";

type ComponentWithPageLayout = AppProps & {
  Component: AppProps["Component"] & {
    PageLayout?: React.FC;
  };
  organization: any;
};

function MyApp(props: ComponentWithPageLayout) {
  const [theme, setTheme] = useState();
  const posthog = usePostHog();
  const store = usePersistedStore();
  const organization = props.organization;
  const Component = props.Component;
  const router = props.router;

  useScrollToTop();
  useEffect(() => {
    const loadThemeAndSetOrg = async () => {
      const theme = (await import(`../styles/${organization}/theme`)).default;
      setTheme(theme);
      store.dispatch(SetOrganization(organization));
      store.dispatch(
        SetOrganizationStorageBuckets({
          facilities: constructStorageUrl(organization, "facilities"),
          venues: constructStorageUrl(organization, "venues"),
          base: constructStorageUrl(organization),
        })
      );
    };
    const initializeServices = async () => {
      ApolloService.init(store);
      const StripeService = (await import("../services/StripeService")).default;
      StripeService.init(organization);
      PostHogClient();
      const { data } = await ApolloService.client.query<
        FetchOrganizationIdBySlugQuery,
        FetchOrganizationIdBySlugQueryVariables
      >({
        query: FetchOrganizationIdBySlugDocument,
        variables: {
          slug: organization,
        },
      });
      const organizationID = data?.organizations?.[0]?.id;
      if (!organizationID) {
        throw new Error(
          `OrganizationID for organization "${organization}" is undefined.`
        );
      }
      SentryClient(organizationID);
      store.dispatch(SetOrganizationID(organizationID as string));
      const time_zone = data?.organizations?.[0]?.time_zone;
      if (!time_zone) {
        throw new Error(
          `Timezone for organization "${organization}" is undefined.`
        );
      }
      store.dispatch(SetOrganizationTimeZone(time_zone));
      const resident_zip_codes = data?.organizations?.[0]?.resident_zip_codes;
      if (resident_zip_codes) {
        store.dispatch(SetOrganizationResidentZipCodes(resident_zip_codes));
      }
      // // Capture organization in posthog group
      const organizationName = data?.organizations?.[0]?.name;
      posthog.group("organization", organizationID, {
        name: organizationName ?? undefined,
      });
    };
    if (organization) {
      loadThemeAndSetOrg();
      initializeServices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  if (!organization && !isProdEnvironment()) {
    return <OrganizationLoader />;
  }

  if (!theme) {
    return <Loading organization={organization} fullScreen />;
  }

  return (
    <KaizenAppConstantsProvider.Provider
      value={getConstantsForOrganization(organization)}
    >
      <ReduxProvider store={store}>
        <Auth0Provider
          domain={config.NEXT_PUBLIC_AUTH0_DOMAIN as string} // Configured in Vercel
          clientId={config.NEXT_PUBLIC_AUTH0_CLIENT_ID as string}
          redirectUri={window.location.origin + `/${organization}`}
          audience="hasura"
          municipalOrganization={organization}
          cacheLocation="localstorage"
        >
          <IntlWrapper>
            <AuthWrapper>
              <PostHogProvider client={posthog}>
                <ProtectedRoutes router={router}>
                  <ApolloProvider client={ApolloService.client}>
                    <ThemeProvider theme={createTheme(theme)}>
                      <SnackbarProvider>
                        <Notifier />
                        <Alert />
                        {Component.PageLayout ? (
                          <Component.PageLayout>
                            <Component {...props.pageProps} />
                          </Component.PageLayout>
                        ) : (
                          <Component {...props.pageProps} />
                        )}
                      </SnackbarProvider>
                    </ThemeProvider>
                  </ApolloProvider>
                </ProtectedRoutes>
              </PostHogProvider>
            </AuthWrapper>
          </IntlWrapper>
        </Auth0Provider>
      </ReduxProvider>
    </KaizenAppConstantsProvider.Provider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  logger.debug("[App Component] start getInitialProps");
  const appProps = await App.getInitialProps(appContext);
  const browserOrigin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";
  const serverOrigin = appContext.ctx.req?.headers?.host;
  const organization = getOrganizationFromUrl(
    serverOrigin ?? browserOrigin,
    appContext.ctx.asPath
  );
  logger.debug("[App Component] complete getInitialProps");
  return { ...appProps, organization };
};

export default MyApp;
