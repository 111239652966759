import { useAuth0 } from "@auth0/auth0-react";
import { NextRouter } from "next/router";
import React from "react";
import { useSelector } from "react-redux";

import {
  AccountRoute,
  protectedAccountRoutes,
  protectedManagerRoutes,
} from "../../constants/types";
import { useAuthStateSelector } from "../../store/auth";
import { organizationSelector } from "../../store/organization";
import { routeWithOrganization } from "../../utils/route_utils";
import Loading from "./Loading";

const isBrowser = () => typeof window !== "undefined";
interface ProtectedRouteProps {
  router: NextRouter;
  children: React.ReactNode;
}

const ProtectedRoutes = ({ router, children }: ProtectedRouteProps) => {
  const { isAuthenticated, isLoading } = useAuth0(); // TODO: optimize Auth0 loading to be sub ~700ms
  const { token, isManager, loading } = useAuthStateSelector();
  const organization = useSelector(organizationSelector);
  let pathIsManagerProtected =
    protectedManagerRoutes.some((accountRoute: AccountRoute) => {
      return (
        routeWithOrganization(organization, accountRoute.route) === router.route
      );
    }) || router.route.includes("manager");

  let pathIsAccountProtected =
    protectedAccountRoutes.some((accountRoute: AccountRoute) => {
      return (
        routeWithOrganization(organization, accountRoute.route) === router.route
      );
    }) || router.route.includes("my-account");

  if (!isBrowser()) {
    console.info("No Browser found. Rendering null.");
    return null;
  }

  if (!pathIsAccountProtected && !pathIsManagerProtected) {
    console.info("Path is not a protected route so rendering normally.");
    return <>{children}</>;
  }

  if (
    loading ||
    (isLoading && !isAuthenticated) ||
    (isAuthenticated && !token)
  ) {
    return <Loading organization={organization} />;
  }

  if (!loading && !isLoading && !isManager && pathIsManagerProtected) {
    console.info(
      "User is not a Manager while attempting to access a Manager route. Re-route to /not-authorized"
    );
    router.push(routeWithOrganization(organization, "/not-authorized"));
    return null;
  }

  if (!loading && !isLoading && !isAuthenticated && pathIsAccountProtected) {
    console.info(
      "User is not authenticated while attempting to access an Account route. Re-route to /not-authorized"
    );
    router.push(routeWithOrganization(organization, "/not-authorized"));
    return null;
  }

  return <>{children}</>;
};

export default ProtectedRoutes;
