import numeral from "numeral";

import {
  STRIPE_FEE_ACH_FIXED_CENTS,
  STRIPE_FEE_ACH_MAXIMUM_CENTS,
  STRIPE_FEE_ACH_MINIMUM_CENTS,
  STRIPE_FEE_ACH_PERCENTAGE,
  STRIPE_FEE_CARD_FIXED_CENTS,
  STRIPE_FEE_CARD_PERCENTAGE,
} from "./constants";

/**
 * This function computes the fee to add to the total price charged to the user,
 * passing Stripe's fee to the user and reserving the goal_price_cents for the
 * organization. The fee is higher than the fee computed by getRealStripeFee
 * because we want to pass the fee to the user, and Stripe calculates its fee on
 * the total amount charged to the user.
 *
 * @see https://support.stripe.com/questions/passing-the-stripe-fee-on-to-customers
 *
 * @param goal_price_cents The price in cents that we want to pass to the
 * organization.
 */
export function getMarkedUpStripeCCFee(goal_price_cents: number) {
  if (goal_price_cents === 0) {
    return 0;
  }

  const numerator = goal_price_cents + STRIPE_FEE_CARD_FIXED_CENTS;
  const denominator = 1 - STRIPE_FEE_CARD_PERCENTAGE;
  const chargePrice = Math.round(numerator / denominator);
  return chargePrice - goal_price_cents;
}

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

export function getRealStripeFee(total_price_cents: number) {
  return Math.round(
    total_price_cents * STRIPE_FEE_CARD_PERCENTAGE + STRIPE_FEE_CARD_FIXED_CENTS
  );
}

export function getMarkedUpStripeACHFee(goal_price_cents: number) {
  if (goal_price_cents === 0) {
    return 0;
  }

  const numerator = goal_price_cents + STRIPE_FEE_ACH_FIXED_CENTS;
  const denominator = 1 - STRIPE_FEE_ACH_PERCENTAGE;
  const chargePrice = Math.round(numerator / denominator);
  const fee = chargePrice - goal_price_cents;
  return clamp(fee, STRIPE_FEE_ACH_MINIMUM_CENTS, STRIPE_FEE_ACH_MAXIMUM_CENTS);
}

/**
 * Amount intended to be collected. A positive integer representing how much to charge in the smallest currency unit
 * (e.g., 100 cents to charge $1.00 or 100 to charge ¥100, a zero-decimal currency). The minimum amount is $0.50 US or equivalent in charge currency.
 * The amount value supports up to eight digits (e.g., a value of 99999999 for a USD charge of $999,999.99).
 * @param total_price_cents
 */
export function getPricePlusStripeFeeAsPositiveInteger(
  total_price_cents: number,
  ach: boolean = false
): number {
  const roundedPriceCents = Math.round(total_price_cents);
  if (ach) {
    return roundedPriceCents + getMarkedUpStripeACHFee(roundedPriceCents);
  } else {
    return roundedPriceCents + getMarkedUpStripeCCFee(roundedPriceCents);
  }
}

export function getStripeFeeAsText(ach: boolean) {
  const cardFeeText = `${(STRIPE_FEE_CARD_PERCENTAGE * 100).toFixed(
    2
  )}% + ${formatCents(STRIPE_FEE_CARD_FIXED_CENTS)}`;
  const achFeeText = `${(STRIPE_FEE_ACH_PERCENTAGE * 100).toFixed(
    2
  )}% (${formatCents(STRIPE_FEE_ACH_MAXIMUM_CENTS)} maximum)`;
  if (ach) {
    return achFeeText;
  }
  return cardFeeText;
}

function formatCents(n: number, format = "$0,0.00", suffix?: string): string {
  if (n == null) return "";
  return formatDollars(n / 100, format, suffix);
}

function formatDollars(n: number, format = "$0,0.00", suffix?: string): string {
  if (n == null) return "";
  return `${numeral(n).format(format)}${suffix ? ` ${suffix}` : ""}`;
}
