// align with Hasura Optional fields
// an object type: {a: string}
// alters to type: {a?: string | null | undefined}
type Optional<T> = { [K in keyof T]?: T[K] | null | undefined };

export type RegistrableUnitStatusInput = {
  program: Program;
  registrable_unit_and_related: RegistrableUnitAndRelated;
  datetime: Date;
  submission_statistics?: RegistrableUnitSubmissionStatistics;
};

// RegistrableUnitSizeStatistics
export type RegistrableUnitSizeStatistics = Optional<{
  max_class_size: number;
  waitlist_size: number;
}>;

// RegistrableUnitSubmissionStatistics
export type RegistrableUnitSubmissionStatistics = {
  num_approved: number;
  num_waitlist: number;
  num_rejected: number;
  num_cancelled: number;
  num_in_progress: number;
  num_transferred: number;
};

// RegistrableUnitStatisticsInfo
export type RegistrableUnitStatisticsInfo = {
  registrable_unit_size_statistics: RegistrableUnitSizeStatistics;
  submission_statistics: RegistrableUnitSubmissionStatistics;
  registration_open: boolean;
  no_future_registration_periods: boolean;
  program_occurred: boolean;
  program_occurring: boolean;
};

// RegistrableUnitStatus
export enum RegistrableUnitStatus {
  Unknown = "UNKNOWN",
  Cancelled = "CANCELLED",
  NotYetOpen = "NOT_YET_OPEN",
  Open = "OPEN",
  Waitlist = "WAITLIST",
  Closed = "CLOSED",
  InProgress = "IN_PROGRESS",
  FullyBooked = "FULLY_BOOKED",
  BookingClosed = "BOOKING_CLOSED",
}

// RegistrableUnitVisibility
export enum RegistrableUnitVisibility {
  Published = "PUBLISHED",
  Unpublished = "UNPUBLISHED",
}

// RosterStatus
export enum RosterStatus {
  Approved = "APPROVED",
  Waitlist = "WAITLIST",
  Rejected = "REJECTED",
  Cancelled = "CANCELLED",
  InProgress = "IN_PROGRESS",
  Transferred = "TRANSFERRED",
}

// TemporalEvent
export type TemporalEvent = {
  start_time: string;
  end_time: string;
  start_date: string;
  end_date: string;
};
// ProgramRegistrationScheduleAndRelatedPropertiesFragment
export type Schedule = Optional<{
  associated_temporal_event: TemporalEvent;
  registrable_unit_id: string;
  override_registrable_unit_id: string;
}>;
// Roster
export type Roster = Optional<{
  associated_program_roster_schedule: Schedule;
}>;
// Program
export type Program = Optional<{
  capacity: number;
  waitlist_max_size: number;
  program_registration_schedules: Schedule[];
  admin_approval_required: boolean;
}>;
// RegistrableUnitSubmission
export type RegistrableUnitSubmission = {
  roster_status: string;
};
// RegistrableUnitAndRelated
export type RegistrableUnitAndRelated = {
  id: string;
} & Optional<{
  program_id: string;
  associated_submissions: RegistrableUnitSubmission[];
  override_waitlist_max_size: number;
  override_capacity: number;
  associated_rosters: Roster[];
  associated_override_registration_schedules: Schedule[];
}>;

export type CheckIfDateInRegistrationPeriodsInput = {
  datetime: Date;
  program_occurring: boolean;
  program_occurred: boolean;
} & Optional<{
  program_registration_schedules: Schedule[];
  override_registrable_unit_registration_schedules: Schedule[];
}>;

export type RegistrableUnitStatusAndStatistics = {
  status: RegistrableUnitStatus;
  statistics: RegistrableUnitStatisticsInfo;
};

// getMinimumTemporalEvent
export function getMinimumTemporalEvent(
  temporal_events: TemporalEvent[]
): TemporalEvent {
  return temporal_events.reduce((min_event, curr_event) => {
    const curr_event_start = new Date(
      `${curr_event.start_date} ${curr_event.start_time}`
    ).getTime();
    const min_event_start = new Date(
      `${min_event.start_date} ${min_event.start_time}`
    ).getTime();
    if (curr_event_start < min_event_start) return curr_event;
    return min_event;
  });
}

export function getMaximumTemporalEvent(
  temporal_events: TemporalEvent[]
): TemporalEvent {
  return temporal_events.reduce((max_event, curr_event) => {
    const curr_event_end = new Date(
      `${curr_event.end_date} ${curr_event.end_time}`
    ).getTime();
    const max_event_end = new Date(
      `${max_event.end_date} ${max_event.end_time}`
    ).getTime();
    if (curr_event_end > max_event_end) return curr_event;
    return max_event;
  });
}

// getStartDatetimeFromTemporalEvents
export function getStartDatetimeFromTemporalEvents(
  temporal_events: TemporalEvent[]
): Date {
  const start_event = getMinimumTemporalEvent(temporal_events);
  const start_datetime = new Date(
    `${start_event.start_date} ${start_event.start_time}`
  );
  return start_datetime;
}

export function getEndDatetimeFromTemporalEvents(
  temporal_events: TemporalEvent[]
): Date {
  const end_event = getMaximumTemporalEvent(temporal_events);
  const end_datetime = new Date(`${end_event.end_date} ${end_event.end_time}`);
  return end_datetime;
}

export function getTemporalEventDateBounds(
  temporal_event: TemporalEvent
): [Date, Date] {
  const start_datetime = new Date(
    `${temporal_event.start_date} ${temporal_event.start_time}`
  );
  // create end_datetime
  const end_datetime = new Date(
    `${temporal_event.end_date} ${temporal_event.end_time}`
  );
  return [start_datetime, end_datetime];
}

// checkIfDateInTemporalEvents
export function checkIfDateInTemporalEvents(
  datetime: Date,
  temporal_events: TemporalEvent[]
) {
  // check if the datetime is in any of the schedule temporal events
  return temporal_events.some((event) => {
    if (!event) {
      throw Error(
        "Error in checkIfDateInRegistrationPeriods: One of the temporal events for program registration schedule is undefined"
      );
    }
    const [start_datetime, end_datetime] = getTemporalEventDateBounds(event);
    const result = start_datetime <= datetime && datetime <= end_datetime;
    return result;
  });
}

export function getRegistrableUnitSizeStatistics(
  program: Program,
  registrable_unit_and_related: RegistrableUnitAndRelated
): RegistrableUnitSizeStatistics {
  // initialize the sizes
  let max_class_size: number | null | undefined = undefined;
  let waitlist_size: number | null | undefined = undefined;
  // first grab the stats from the program
  max_class_size = program?.capacity;
  waitlist_size = program?.waitlist_max_size;
  // if necessary, grab from registrable unit itself
  if (
    registrable_unit_and_related?.override_capacity != undefined &&
    registrable_unit_and_related?.override_capacity != null
  ) {
    max_class_size = registrable_unit_and_related?.override_capacity;
  }
  if (
    registrable_unit_and_related?.override_waitlist_max_size != undefined &&
    registrable_unit_and_related?.override_waitlist_max_size != null
  ) {
    waitlist_size = registrable_unit_and_related?.override_waitlist_max_size;
  }
  // convert null to undefined
  if (max_class_size === null) {
    max_class_size = undefined;
  }
  if (waitlist_size === null) {
    waitlist_size = undefined;
  }
  // pass into return object
  const registrable_unit_size_statistics: RegistrableUnitSizeStatistics = {
    max_class_size,
    waitlist_size,
  };
  // return
  return registrable_unit_size_statistics;
}

export function convertStringToRosterStatusesEnum(
  status: string
): RosterStatus {
  switch (status) {
    case "APPROVED": {
      return RosterStatus.Approved;
    }
    case "WAITLIST": {
      return RosterStatus.Waitlist;
    }
    case "REJECTED": {
      return RosterStatus.Rejected;
    }
    case "CANCELLED": {
      return RosterStatus.Cancelled;
    }
    case "IN_PROGRESS": {
      return RosterStatus.InProgress;
    }
    case "TRANSFERRED": {
      // we can think of this state as equivalent to "Rejected", since we are removing from the roster
      return RosterStatus.Transferred;
    }
    default: {
      throw new Error(
        `convertStringToRosterStatusesEnum: Unrecognized roster status: ${status}`
      );
    }
  }
}

export function getRegistrableUnitSubmissionStatistics(
  registrable_unit_submissions: RegistrableUnitSubmission[]
): RegistrableUnitSubmissionStatistics {
  // state breakdown
  let num_approved = 0;
  let num_waitlist = 0;
  let num_rejected = 0;
  let num_cancelled = 0;
  let num_in_progress = 0;
  let num_transferred = 0;
  // loop (for O(n))
  for (let submission of registrable_unit_submissions) {
    const unprocessed_roster_status: string = submission?.roster_status;
    const roster_status = convertStringToRosterStatusesEnum(
      unprocessed_roster_status
    );
    switch (roster_status) {
      case RosterStatus.Approved: {
        num_approved++;
        break;
      }
      case RosterStatus.Waitlist: {
        num_waitlist++;
        break;
      }
      case RosterStatus.Rejected: {
        num_rejected++;
        break;
      }
      case RosterStatus.Cancelled: {
        num_cancelled++;
        break;
      }
      case RosterStatus.InProgress: {
        num_in_progress++;
        break;
      }
      case RosterStatus.Transferred: {
        num_transferred++;
        break;
      }
      default: {
        throw new Error(
          `getRegistrableUnitSubmissionStatistics: Unrecognized roster status: ${roster_status}`
        );
      }
    }
  }
  // create the statistics object
  const submission_statistics: RegistrableUnitSubmissionStatistics = {
    num_approved,
    num_waitlist,
    num_rejected,
    num_cancelled,
    num_in_progress,
    num_transferred,
  };
  return submission_statistics;
}

export function getAllRosterTemporalEvents(
  registrable_unit_and_related: RegistrableUnitAndRelated
): TemporalEvent[] {
  let all_roster_temporal_events: TemporalEvent[] = [];
  const associated_rosters = registrable_unit_and_related?.associated_rosters;
  if (!associated_rosters?.length) {
    return [];
  }
  for (let roster of associated_rosters) {
    const roster_temporal_event =
      roster?.associated_program_roster_schedule?.associated_temporal_event;
    if (roster_temporal_event) {
      all_roster_temporal_events.push(roster_temporal_event);
    }
  }
  return all_roster_temporal_events;
}

export function checkIfAfterRegistrableUnitOccurs(
  datetime: Date,
  registrable_unit_and_related: RegistrableUnitAndRelated
): boolean {
  // grab the dates that the program itself is occuring
  const all_roster_temporal_events: TemporalEvent[] =
    getAllRosterTemporalEvents(registrable_unit_and_related);
  // error out if no temporal events
  if (!all_roster_temporal_events?.length) {
    throw new Error(
      "Error in checkIfBeforeRegistrableUnitOccurs: No temporal events found for registrable unit"
    );
  }
  // get min/max datetime
  const max_roster_datetime = getEndDatetimeFromTemporalEvents(
    all_roster_temporal_events
  );
  // check if datetime is in bounds
  return datetime > max_roster_datetime;
}

export function checkIfDuringRegistrableUnitOccurs(
  datetime: Date,
  registrable_unit_and_related: RegistrableUnitAndRelated
): boolean {
  // grab the dates that the program itself is occuring
  const all_roster_temporal_events: TemporalEvent[] =
    getAllRosterTemporalEvents(registrable_unit_and_related);
  // error out if no temporal events
  if (!all_roster_temporal_events?.length) {
    throw new Error(
      "Error in checkIfDuringRegistrableUnitOccurs: No temporal events found for registrable unit"
    );
  }
  // get min/max datetime
  const min_roster_datetime = getStartDatetimeFromTemporalEvents(
    all_roster_temporal_events
  );
  const max_roster_datetime = getEndDatetimeFromTemporalEvents(
    all_roster_temporal_events
  );
  // check if datetime is in bounds
  return min_roster_datetime <= datetime && datetime <= max_roster_datetime;
}

const getRegistrationPeriods = ({
  program_registration_schedules,
  override_registrable_unit_registration_schedules,
}: {
  program_registration_schedules: Schedule[] | null | undefined;
  override_registrable_unit_registration_schedules:
    | Schedule[]
    | null
    | undefined;
}): TemporalEvent[] => {
  const final_registration_schedules: Schedule[] =
    override_registrable_unit_registration_schedules?.length
      ? override_registrable_unit_registration_schedules
      : program_registration_schedules ?? [];

  let temporal_events: TemporalEvent[] = [];
  for (const schedule of final_registration_schedules) {
    const temporal_event = schedule?.associated_temporal_event;
    if (temporal_event) {
      temporal_events.push(temporal_event);
    } else {
      throw Error(
        "Error in checkIfDateInRegistrationPeriods: One of the temporal events for program registration schedule is undefined"
      );
    }
  }
  return temporal_events;
};

export function checkIfDateInRegistrationPeriods({
  datetime,
  program_registration_schedules,
  override_registrable_unit_registration_schedules,
  program_occurring,
  program_occurred,
}: CheckIfDateInRegistrationPeriodsInput): boolean {
  // if both schedules arrays are empty, return true
  if (
    !program_registration_schedules?.length &&
    !override_registrable_unit_registration_schedules?.length
  ) {
    // right now, the assumption is that if the program is occurring or has occurred
    // and if no registration schedules are provided, then the program is not open for registration
    if (program_occurring || program_occurred) {
      return false;
    }
    return true;
  }
  // now combine both arrays
  // TODO - This may not be desired behavior here...
  const temporal_events = getRegistrationPeriods({
    program_registration_schedules,
    override_registrable_unit_registration_schedules,
  });

  // TODO - handle resident status
  // check if the datetime is in any of the schedule temporal events
  const is_in_registration_period = checkIfDateInTemporalEvents(
    datetime,
    temporal_events
  );
  return is_in_registration_period;
}
export const checkIfFutureRegistrationPeriodExists = ({
  datetime,
  program_registration_schedules,
  override_registrable_unit_registration_schedules,
}: {
  datetime: Date;
  program_registration_schedules: Schedule[] | null | undefined;
  override_registrable_unit_registration_schedules:
    | Schedule[]
    | null
    | undefined;
}): boolean => {
  const temporalEvents = getRegistrationPeriods({
    program_registration_schedules,
    override_registrable_unit_registration_schedules,
  });
  if (temporalEvents.length === 0) {
    return false;
  }
  // check if there is at least one temporal event that occurs after the given datetime
  const lastTemporalEvent = getMaximumTemporalEvent(temporalEvents);
  const [startDatetime] = getTemporalEventDateBounds(lastTemporalEvent);
  return datetime < startDatetime;
};

export function getRegistrableUnitStatisticsInfo({
  program,
  registrable_unit_and_related,
  datetime,
  submission_statistics,
}: RegistrableUnitStatusInput): RegistrableUnitStatisticsInfo {
  // get all associated registration dates
  const program_registration_schedules =
    program?.program_registration_schedules?.filter(
      // missing fields will be undefined
      // proper fields will be null or value
      (schedule) => {
        return (
          (schedule.registrable_unit_id ||
            schedule.override_registrable_unit_id ||
            null) === null
        );
      }
    );
  const override_registration_schedules =
    registrable_unit_and_related?.associated_override_registration_schedules;
  // check if the program is in progress
  const program_occurring = checkIfDuringRegistrableUnitOccurs(
    datetime,
    registrable_unit_and_related
  );
  const program_occurred = checkIfAfterRegistrableUnitOccurs(
    datetime,
    registrable_unit_and_related
  );
  // check if registration is open
  const registration_open: boolean = checkIfDateInRegistrationPeriods({
    datetime,
    program_registration_schedules,
    override_registrable_unit_registration_schedules:
      override_registration_schedules,
    program_occurring,
    program_occurred,
  });
  // figure out stats of program (num approved, num waitlist, etc.)
  if (!submission_statistics) {
    const registrable_unit_submissions =
      registrable_unit_and_related?.associated_submissions;
    if (registrable_unit_submissions) {
      submission_statistics = getRegistrableUnitSubmissionStatistics(
        registrable_unit_submissions
      );
    } else {
      throw Error(
        "Error in getRegistrableUnitStatisticsInfo: No registrable unit submissions found and no submission statistics provided"
      );
    }
  }
  const has_future_registration_periods = checkIfFutureRegistrationPeriodExists(
    {
      datetime,
      program_registration_schedules,
      override_registrable_unit_registration_schedules:
        override_registration_schedules,
    }
  );
  // get class size stats
  const registrable_unit_size_statistics: RegistrableUnitSizeStatistics =
    getRegistrableUnitSizeStatistics(program, registrable_unit_and_related);
  // construct output
  const output: RegistrableUnitStatisticsInfo = {
    registration_open,
    program_occurring,
    program_occurred,
    submission_statistics,
    registrable_unit_size_statistics,
    no_future_registration_periods: !has_future_registration_periods,
  };
  return output;
}

export function getRegistrableUnitStatusFromStatistics({
  registration_open,
  program_occurring,
  program_occurred,
  submission_statistics,
  registrable_unit_size_statistics,
  no_future_registration_periods,
}: RegistrableUnitStatisticsInfo): RegistrableUnitStatus {
  // in progress
  if (!registration_open && program_occurring) {
    return RegistrableUnitStatus.InProgress;
  }
  if (program_occurred) {
    return RegistrableUnitStatus.Closed;
  }
  // booking closed, no more registration windows
  if (!registration_open && no_future_registration_periods) {
    return RegistrableUnitStatus.BookingClosed;
  }
  const max_class_size = registrable_unit_size_statistics.max_class_size;
  const waitlist_size = registrable_unit_size_statistics.waitlist_size;
  // now actually get the state...
  if (registration_open && !program_occurred) {
    // this series of conditionals is for WAITLIST and OPEN statuses
    if (
      max_class_size === undefined ||
      max_class_size === null ||
      submission_statistics.num_approved < max_class_size
    ) {
      // open
      return RegistrableUnitStatus.Open;
    } else {
      // waitlist
      if (
        waitlist_size === undefined ||
        waitlist_size === null ||
        waitlist_size > submission_statistics.num_waitlist
      ) {
        // waitlist
        return RegistrableUnitStatus.Waitlist;
      }
    }
  }
  // fully booked - no matter if the registration is open or not, the program can not accept any more registrations
  if (
    max_class_size != null &&
    waitlist_size != null &&
    submission_statistics.num_approved >= max_class_size &&
    submission_statistics.num_waitlist >= waitlist_size
  ) {
    return RegistrableUnitStatus.FullyBooked;
  }

  // not yet open
  return RegistrableUnitStatus.NotYetOpen;
}

export function getRegistrableUnitStatus(
  input: RegistrableUnitStatusInput
): RegistrableUnitStatusAndStatistics {
  // get all associated registration dates
  const registrable_unit_statistics_info: RegistrableUnitStatisticsInfo =
    getRegistrableUnitStatisticsInfo(input);
  // now actually get the state...
  const state = getRegistrableUnitStatusFromStatistics(
    registrable_unit_statistics_info
  );
  // return
  const output: RegistrableUnitStatusAndStatistics = {
    status: state,
    statistics: registrable_unit_statistics_info,
  };
  return output;
}

export function getRegistrableUnitStatusRanking(
  registrable_unit_status: RegistrableUnitStatus
): number {
  /*
    WHEN 'OPEN' THEN RETURN 1;
    WHEN 'WAITLIST' THEN RETURN 2;
    WHEN 'NOT_YET_OPEN' THEN RETURN 3;
    WHEN 'IN_PROGRESS' THEN RETURN 4;
    WHEN 'FULLY_BOOKED' THEN RETURN 5;
    WHEN 'BOOKING_CLOSED' THEN RETURN 6;
    WHEN 'CLOSED' THEN RETURN 7;
    WHEN 'CANCELLED' THEN RETURN 8;
    ELSE return 9;
   */
  switch (registrable_unit_status) {
    case RegistrableUnitStatus.Open: {
      return 1;
    }
    case RegistrableUnitStatus.Waitlist: {
      return 2;
    }
    case RegistrableUnitStatus.NotYetOpen: {
      return 3;
    }
    case RegistrableUnitStatus.InProgress: {
      return 4;
    }
    case RegistrableUnitStatus.FullyBooked: {
      return 5;
    }
    case RegistrableUnitStatus.BookingClosed: {
      return 6;
    }
    case RegistrableUnitStatus.Closed: {
      return 7;
    }
    case RegistrableUnitStatus.Cancelled: {
      return 8;
    }
    default: {
      return 9;
    }
  }
}

export function registrableUnitStatusComparator(
  a: RegistrableUnitStatus,
  b: RegistrableUnitStatus
): number {
  return (
    getRegistrableUnitStatusRanking(a) - getRegistrableUnitStatusRanking(b)
  );
}
