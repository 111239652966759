export interface KaizenAppConstants {
  slug: string;
  logoUrl: string;
  cityName: string;
  brandName: string;
  timeZone: string;
}

export type OrganizationSlug =
  | "westminster"
  | "weymouth"
  | "santarosa"
  | "castlepines"
  | "mountainhouse"
  | "110percent"
  | "sanantonio"
  | "parker"
  | "montereypark"
  | "evanston"
  | "ccmrd"
  | "pima"
  | "kencaryl"
  | "tulare"
  | "sarasota"
  | "redwoodcity"
  | "metroparks"
  | "crownmountain"
  | "northville";

const constantsBySlug: Record<OrganizationSlug, KaizenAppConstants> = {
  westminster: {
    slug: "westminster",
    logoUrl:
      "https://storage.googleapis.com/westminster_assets/westminster_logo.png",
    cityName: "Westminster",
    brandName: "Westminster Cares",
    timeZone: "Los_Angeles",
  },
  weymouth: {
    slug: "weymouth",
    logoUrl: "https://storage.googleapis.com/weymouth_assets/weymouth_logo.png",
    cityName: "Weymouth",
    brandName: "Weymouth Rec",
    timeZone: "New_York",
  },
  santarosa: {
    slug: "santarosa",
    logoUrl:
      "https://storage.googleapis.com/santarosa_assets/santa_rosa_logo.png",
    cityName: "Santa Rosa",
    brandName: "Clean Santa Rosa",
    timeZone: "Los_Angeles",
  },
  castlepines: {
    slug: "castlepines",
    logoUrl:
      "https://storage.googleapis.com/castle_pines_assets/castle_pines_logo.png",
    cityName: "Castle Pines",
    brandName: "Castle Pines Recreation",
    timeZone: "Denver",
  },
  mountainhouse: {
    slug: "mountainhouse",
    logoUrl:
      "https://storage.googleapis.com/mountain_house_assets/mountain_house.svg",
    cityName: "Mountain House",
    brandName: "Mountain House NSV",
    timeZone: "Los_Angeles",
  },
  "110percent": {
    slug: "110percent",
    logoUrl: "https://storage.googleapis.com/110_assets/110_logo.png",
    cityName: "110 Percent",
    brandName: "110 Percent",
    timeZone: "Denver",
  },
  sanantonio: {
    slug: "sanantonio",
    logoUrl:
      "https://storage.googleapis.com/san_antonio_assets/san_antonio_logo.png",
    cityName: "San Antonio",
    brandName: "Smarter Together SA",
    timeZone: "Chicago",
  },
  parker: {
    slug: "parker",
    logoUrl: "https://storage.googleapis.com/parker_assets/parker_logo.png",
    cityName: "Parker",
    brandName: "Parker Community Events",
    timeZone: "Denver",
  },
  montereypark: {
    slug: "montereypark",
    logoUrl:
      "https://storage.googleapis.com/monterey_park_assets/monterey_park_logo.png",
    cityName: "Monterey Park",
    brandName: "Monterey Park Recreation and Community Services",
    timeZone: "Los_Angeles",
  },
  evanston: {
    slug: "evanston",
    logoUrl: "https://storage.googleapis.com/evanston_assets/evanston_logo.png",
    cityName: "Evanston",
    brandName: "Evanston Parks and Recreation",
    timeZone: "Chicago",
  },
  ccmrd: {
    slug: "ccmrd",
    logoUrl: "https://storage.googleapis.com/ccmrd_assets/ccmrd_logo.png",
    cityName: "Clear Creek",
    brandName: "Clear Creek",
    timeZone: "Denver",
  },
  pima: {
    slug: "pima",
    logoUrl: "https://storage.googleapis.com/pima_assets/pima_logo.png",
    cityName: "Pima County",
    brandName: "Pima County Parks & Recreation",
    timeZone: "Denver",
  },
  kencaryl: {
    slug: "kencaryl",
    logoUrl: "https://storage.googleapis.com/kencaryl_assets/kencaryl_logo.png",
    cityName: "Ken-Caryl Metro District",
    brandName: "Ken-Caryl Parks & Recreation",
    timeZone: "Denver",
  },
  tulare: {
    slug: "tulare",
    logoUrl: "https://storage.googleapis.com/tulare_assets/tulare_logo.png",
    cityName: "Tulare County",
    brandName: "Tulare County Parks & Recreation",
    timeZone: "Los_Angeles",
  },
  sarasota: {
    slug: "sarasota",
    logoUrl: "https://storage.googleapis.com/sarasota_assets/sarasota_logo.png",
    cityName: "Sarasota",
    brandName: "Sarasota Parks & Recreation",
    timeZone: "New_York",
  },
  redwoodcity: {
    slug: "redwoodcity",
    logoUrl:
      "https://storage.googleapis.com/redwoodcity_assets/redwoodcity_logo.png",
    cityName: "Redwood City",
    brandName: "Redwood City Parks & Recreation",
    timeZone: "Los_Angeles",
  },
  metroparks: {
    slug: "metroparks",
    logoUrl:
      "https://storage.googleapis.com/metro_parks_assets/metro_parks_logo.png",
    cityName: "Metro Parks",
    brandName: "Metro Parks",
    timeZone: "New_York",
  },
  crownmountain: {
    slug: "crownmountain",
    logoUrl:
      "https://storage.googleapis.com/crown_mountain_assets/crown_mountain_logo.png",
    cityName: "Crown Mountain",
    brandName: "Crown Mountain",
    timeZone: "Denver",
  },
  northville: {
    slug: "northville",
    logoUrl:
      "https://storage.googleapis.com/northville_assets/northville_logo.png",
    cityName: "Northville",
    brandName: "Northville Parks and Recreation",
    timeZone: "New_York",
  },
};

export const getConstantsForOrganization = (
  organization: OrganizationSlug
): KaizenAppConstants => {
  const constants = constantsBySlug[organization];

  if (!constants) {
    throw new Error(`Constants for "${organization}" not defined.`);
  }

  return constants;
};

export const RWC_OKTOBERFEST_PATH =
  "/discover/program/2e5b7a18-bb8e-4471-b45d-49f2f19ceec8";

// There are just many ways to denote an ACH payment -
// Stripe uses us_bank_account (from the payment intent)
// and "Bank Account" from the payment element
// Our Orders table uses BANK
export const ACH_PAYMENTS = ["us_bank_account", "Bank Account", "BANK"];
