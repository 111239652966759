// How to use: https://posthog.com/docs/libraries/react
import { posthog } from "posthog-js";

import config from "../common/config";

const PostHogClient = () => {
  const isLocalOrDevelopment =
    config.NEXT_PUBLIC_ENV &&
    ["local", "development"].includes(config.NEXT_PUBLIC_ENV);
  if (config.NEXT_PUBLIC_POSTHOG_KEY && config.NEXT_PUBLIC_POSTHOG_HOST) {
    // Check that PostHog is client-side (used to handle Next.js SSR)
    if (typeof window !== "undefined") {
      posthog.init(config.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host: `${window.location.origin}/ingest`,
        ui_host: config.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
        // Enable debug mode in development
        loaded: (posthog) => {
          if (isLocalOrDevelopment) posthog.debug();
          // Remove this log eventually - for testing only
          console.log(`PostHog loaded in ${config.NEXT_PUBLIC_ENV}.`);
        },
      });
    } else {
      console.warn("Window is undefined; PostHog cannot load.");
    }
  } else {
    console.warn(
      `PostHog key and host not set on ${config.NEXT_PUBLIC_ENV}; PostHog not running...`
    );
  }
};

export default PostHogClient;
