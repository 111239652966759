import { defineAction, setWith, TypedReducer } from "redoodle";
import * as Reselect from "reselect";

import { OrganizationLanguage } from "../constants/types";
import { IAppState } from "./app";

export interface ILocaleState {
  language: OrganizationLanguage;
}

// actions
export const SetLocaleLanguage = defineAction(
  "APP/LOCALE/SET_LOCALE_LANGUAGE"
)<OrganizationLanguage>();

// reducer
export const localeReducer: any = TypedReducer.builder<ILocaleState>()
  .withHandler(SetLocaleLanguage.TYPE, (state, language) =>
    setWith(state, { language })
  )
  .withDefaultHandler((state) => state || initialLocaleState)
  .build();

// selectors
export const localeLanguageSelector = Reselect.createSelector(
  (state: IAppState) => state.locale.language,
  (language: OrganizationLanguage) => {
    return language;
  }
);

// init
export const initialLocaleState: ILocaleState = {
  language: "en",
};
