// Stripe's standard fee structure charges a flat fee of 30 cents per
// transaction, plus 2.9% of the total amount charged.
// See https://stripe.com/pricing
export const STRIPE_FEE_CARD_FIXED_CENTS = 30;
export const STRIPE_FEE_CARD_PERCENTAGE = 0.029;

// Stripe's ACH fee is a percentage of the total with no fixed portion, 1¢ at a
// minimum and capped at $5
// See https://stripe.com/pricing See
// https://support.stripe.com/questions/rounding-rules-for-stripe-fees
export const STRIPE_FEE_ACH_FIXED_CENTS = 0;
export const STRIPE_FEE_ACH_PERCENTAGE = 0.008;
export const STRIPE_FEE_ACH_MINIMUM_CENTS = 1;
export const STRIPE_FEE_ACH_MAXIMUM_CENTS = 500;
