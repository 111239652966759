import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useIsSm } from "../../hooks/useIsSm";
import { IAppState } from "../../store/app";
import { CloseAlert } from "../../store/notification";

const Alert = () => {
  const isSm = useIsSm();
  const dispatch = useDispatch();
  const alert = useSelector((state: IAppState) => state.notifications.alert);

  const handleCancel = useCallback(() => {
    if (alert?.allowCancel) {
      dispatch(CloseAlert());
      if (alert?.onCancel) {
        alert.onCancel();
      }
    }
  }, [dispatch, alert]);

  const handleApprove = useCallback(() => {
    alert?.onApprove();
    dispatch(CloseAlert());
  }, [alert, dispatch]);

  return alert ? (
    <Dialog
      fullScreen={isSm}
      open={!!alert}
      disableEscapeKeyDown={!alert.allowCancel}
      fullWidth={alert.fullWidth}
      maxWidth="sm"
      onClose={() => {
        if (alert.disableClose) {
          return;
        }

        handleCancel();
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <DialogTitle className="px-0 py-4">
          <Typography variant="h3" className="text-22 font-bold">
            {alert.title}
          </Typography>
        </DialogTitle>
        {!alert.disableClose && (
          <div
            onClick={handleCancel}
            style={{
              width: "min-content",
              height: "min-content",
              cursor: "pointer",
            }}
          >
            <CloseRoundedIcon fontSize="large" />
          </div>
        )}
      </Box>
      <DialogContent className="py-4">
        <DialogContentText id="alert-dialog-description">
          {alert.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {alert.allowCancel && (
          <Button
            className="h-[40px]"
            onClick={handleCancel}
            autoFocus
            variant="outlined"
          >
            {alert.cancelLabel ?? "Cancel"}
          </Button>
        )}
        <Button
          className="text-white font-bold h-[40px]"
          onClick={handleApprove}
          style={{
            backgroundColor: alert.approveBackgroundColor ?? "#008550",
          }}
          variant="contained"
        >
          {alert.approveLabel ?? "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default Alert;
