import {
  getRegistrableUnitStatus,
  RegistrableUnitStatus,
  RegistrableUnitSubmissionStatistics,
  RegistrableUnitVisibility,
} from "@kaizenlabs/registrable-unit-state-helpers";
import Brush from "@mui/icons-material/Brush";
import FitnessCenter from "@mui/icons-material/FitnessCenter";
import MusicNote from "@mui/icons-material/MusicNote";
import Park from "@mui/icons-material/Park";
import Pool from "@mui/icons-material/Pool";
import Restaurant from "@mui/icons-material/Restaurant";
import School from "@mui/icons-material/School";
import { SvgIcon } from "@mui/material";
import { flatten } from "lodash";
import groupBy from "lodash.groupby";
import { DateTime } from "luxon";
import moment from "moment";
import { match } from "ts-pattern";

import {
  CartItem,
  FetchProgramFormsDocument,
  FetchProgramFormsQuery,
  FetchProgramFormsQueryVariables,
  Form_Types_Enum,
  FormSubmissionInput,
  FormSubmissionInputType,
  HouseholdMemberPropertiesFragment,
  InsertRegistrableUnitSubmissionInput,
  Payment_Statuses_Enum,
  Program_Categories_Enum,
  Program_Forms_Bool_Exp,
  Program_Types_Enum,
  ProgramFormPropertiesFragment,
  ProgramGroupPropertiesFragment,
  ProgramPaymentOptionPropertiesFragment,
  ProgramPropertiesFragment,
  ProgramsPriceEvaluation,
  ProgramWithChildrenPropertiesFragment,
  Registrable_Unit_Submission_Form_Submissions_Insert_Input,
  RegistrableUnitAndRosterScheduleFragment,
  RegistrableUnitPropertiesFragment,
  RegistrableUnitSubmissionAndRelatedPropertiesFragment,
  RegistrableUnitSubmissionFormSubmissionRequirementsPropertiesFragment,
  Roster_Statuses_Enum,
  RosterItemPropertiesFragment,
  RosterItemRequirementsPropertiesFragment,
  TemporalEventPropertiesFragment,
} from "../api/generated";
import { StatusChipVariant } from "../components/shared/StatusChip";
import {
  CampCartItemData,
  ClassCartItemData,
  DocumentSubmission,
  FormSubmission,
  FormSubmissionsByHouseholdMember,
  isCampCartItem,
  isClassCartItem,
  isTicketedEventCartItem,
  Maybe,
  ProgramCartItemData,
  RegistrableUnitCartData,
  RegistrableUnitSubmissionCartAndRelated,
  RegistrableUnitSubmissionsInput,
  TicketedEventCartItemData,
  UserFile,
} from "../constants/types";
import ApolloService from "../services/ApolloService";
import { firstOrNull } from "./arrays";
import { convertUserFileToMediaInfoInput } from "./file_utils";
import { formatCents, getPaymentTypeFee } from "./price_utils";
import { registrableUnitDateTimeComparator } from "./sort_utils";
import { TemporalEventFormData } from "./zod";

export type PaymentOptionOutput = {
  option: any;
  isOverridden: boolean;
};

export type RegistrableUnitAndStatus = {
  registrableUnit: RegistrableUnitPropertiesFragment;
  status: RegistrableUnitStatus;
};

export function programCategoryToIcon(
  programType: Program_Categories_Enum
): typeof SvgIcon {
  return match(programType)
    .with(Program_Categories_Enum.Swim, () => Pool)
    .with(Program_Categories_Enum.Art, () => Brush)
    .with(Program_Categories_Enum.Fitness, () => FitnessCenter)
    .with(Program_Categories_Enum.Music, () => MusicNote)
    .with(Program_Categories_Enum.Dance, () => MusicNote)
    .with(Program_Categories_Enum.Outdoors, () => Park)
    .with(Program_Categories_Enum.Culinary, () => Restaurant)
    .with(Program_Categories_Enum.Education, () => School)
    .exhaustive();
}

export function getRegistrableUnitStatusFromInputs(
  program: ProgramPropertiesFragment,
  registrable_unit: RegistrableUnitPropertiesFragment
): RegistrableUnitStatus {
  // get num_approved and num_waitlist
  const num_approved = registrable_unit.registered_count;
  const num_waitlist = registrable_unit.waitlist_count;
  // create
  const submission_statistics: RegistrableUnitSubmissionStatistics = {
    num_approved: num_approved ?? 0,
    num_waitlist: num_waitlist ?? 0,
    num_cancelled: 0,
    num_in_progress: 0,
    num_rejected: 0,
    num_transferred: 0,
  };
  // make call
  const { status } = getRegistrableUnitStatus({
    program: program,
    registrable_unit_and_related: registrable_unit,
    datetime: new Date(),
    submission_statistics,
  });
  return status;
}

export const getRegistrableUnitStatusVariant = (
  registrableUnitStatus: RegistrableUnitStatus
): StatusChipVariant => {
  switch (registrableUnitStatus) {
    case RegistrableUnitStatus.Open:
      return StatusChipVariant.CONFIRM;
    case RegistrableUnitStatus.InProgress:
      return StatusChipVariant.ACTION;
    case RegistrableUnitStatus.Closed:
      return StatusChipVariant.INACTIVE;
    case RegistrableUnitStatus.FullyBooked:
    case RegistrableUnitStatus.BookingClosed:
      return StatusChipVariant.ERROR;
    default:
      return StatusChipVariant.WARNING;
  }
};

export const getRegistrableUnitVisibilityStatusVariant = (
  registrableUnitVisibilityStatus: RegistrableUnitVisibility
): StatusChipVariant => {
  switch (registrableUnitVisibilityStatus) {
    case RegistrableUnitVisibility.Published:
      return StatusChipVariant.PUBLISHED;
    case RegistrableUnitVisibility.Unpublished:
      return StatusChipVariant.INACTIVE;
  }
};

export const getRegistrableUnitStatusLabel = (
  registrableUnitStatus: RegistrableUnitStatus
): string => {
  switch (registrableUnitStatus) {
    case RegistrableUnitStatus.Open:
      return "Open";
    case RegistrableUnitStatus.Cancelled:
      return "Cancelled";
    case RegistrableUnitStatus.InProgress:
      return "In progress";
    case RegistrableUnitStatus.Closed:
      return "Closed";
    case RegistrableUnitStatus.Waitlist:
      return "Waitlist open";
    case RegistrableUnitStatus.NotYetOpen:
      return "Registration not open";
    case RegistrableUnitStatus.BookingClosed:
      return "Registration closed";
    case RegistrableUnitStatus.FullyBooked:
      return "Sold out";
    case RegistrableUnitStatus.Unknown:
      return "Unknown";
  }
};

export const getRegistrableUnitVisibilityStatusLabel = (
  registrableUnitVisibility: RegistrableUnitVisibility
): string => {
  switch (registrableUnitVisibility) {
    case RegistrableUnitVisibility.Published:
      return "Published";
    case RegistrableUnitVisibility.Unpublished:
      return "Unpublished";
  }
};

export const getRosterStatusChipVariant = (
  rosterStatus: Roster_Statuses_Enum
) =>
  match(rosterStatus)
    .with(Roster_Statuses_Enum.Approved, () => StatusChipVariant.CONFIRM)
    .with(Roster_Statuses_Enum.InProgress, () => StatusChipVariant.CONFIRM)
    .with(Roster_Statuses_Enum.Transferred, () => StatusChipVariant.INACTIVE)
    .with(Roster_Statuses_Enum.Waitlist, () => StatusChipVariant.INACTIVE)
    .with(Roster_Statuses_Enum.Cancelled, () => StatusChipVariant.ERROR)
    .with(Roster_Statuses_Enum.Rejected, () => StatusChipVariant.ERROR)
    .exhaustive();

export const parseRosterDialogInfo = (roster: RosterItemPropertiesFragment) => {
  const member = roster.associated_household_member;
  const program = roster.associated_program;
  const registrableUnitId = roster.registrable_unit_id;
  const paymentOptions = program?.program_payment_options;
  let programPrice: string = formatCents(0);
  if (paymentOptions) {
    const { option: selectedPaymentOption } = understandPaymentOptions(
      paymentOptions,
      registrableUnitId
    );
    programPrice = formatCents(selectedPaymentOption?.price_cents || 0);
  }
  const user = roster.associated_user;

  return {
    memberName: `${member?.first_name} ${member?.last_name}` || "This user",
    programName: program?.name || "this program",
    programPrice,
    userEmail: user?.email || "unknown",
  };
};

export const getPaymentOptionForProgram = (
  programPaymentOptions: ProgramPaymentOptionPropertiesFragment[],
  registrableUnitId?: string
): ProgramPaymentOptionPropertiesFragment => {
  let paymentOption;

  if (registrableUnitId) {
    paymentOption = programPaymentOptions.find((opt) => {
      return opt.override_registrable_unit_id === registrableUnitId;
    });
  }

  paymentOption = paymentOption ?? programPaymentOptions[0]; // TODO: fetch price correctly https://linear.app/kaizenlabs/issue/ENG-1180/select-the-price-correctly-in-the-programs-uis

  return paymentOption;
};

export const getPriceForProgram = (
  programPaymentOptions: ProgramPaymentOptionPropertiesFragment[],
  registrableUnitId?: string
) => {
  const { option } = understandPaymentOptions(
    programPaymentOptions,
    registrableUnitId
  );
  if (!option) {
    console.error({
      error: `Error in getPriceForProgram: No payment option found for program:`,
      programPaymentOptions,
      registrableUnitId,
    });
    throw Error(
      `Error in getPriceForProgram: No payment option found for program: ${programPaymentOptions}`
    );
  }
  return option.price_cents;
};

export function understandPaymentOptions(
  program_payment_options: ProgramPaymentOptionPropertiesFragment[] = [],
  registrableUnitId?: string
) {
  // there are no matching associated options, so move on to options list
  const options = program_payment_options;
  if (registrableUnitId) {
    const option = firstOrNull(
      options.filter(
        (option) => option.override_registrable_unit_id === registrableUnitId
      )
    );

    if (option) {
      return {
        option,
        isOverridden: true,
      };
    } else {
      return {
        option: firstOrNull(
          options.filter(
            (option) => option.override_registrable_unit_id == null
          )
        ),
        isOverridden: false,
      };
    }
  } else {
    return {
      option: firstOrNull(
        options.filter((option) => option.override_registrable_unit_id == null)
      ),
      isOverridden: false,
    };
  }
}

export function formatPriceForPaymentOption(
  option?: ProgramPaymentOptionPropertiesFragment | null
) {
  if (!option) {
    return "N/A";
  }
  return option.price_cents === 0 ? "Free" : formatCents(option.price_cents);
}

export function getFormattedPriceRangeForProgram(
  programPaymentOptions: ProgramPaymentOptionPropertiesFragment[],
  registrableUnitId?: string
): string {
  if (registrableUnitId) {
    //filter out options that don't match the registrable unit id
    const filteredOptions = programPaymentOptions.filter(
      (option) => option.override_registrable_unit_id === registrableUnitId
    );
    // make recursive call
    if (filteredOptions?.length) {
      return getFormattedPriceRangeForProgram(filteredOptions);
    }
  }
  // get lowest and highest price
  let min_price_cents = Number.MAX_SAFE_INTEGER;
  let max_price_cents = 0;
  for (let option of programPaymentOptions) {
    if (option.price_cents < min_price_cents) {
      min_price_cents = option.price_cents;
    }
    if (option.price_cents > max_price_cents) {
      max_price_cents = option.price_cents;
    }
  }
  // format the prices
  const min_price_string = formatCents(min_price_cents);
  if (min_price_cents == max_price_cents) {
    return min_price_string;
  }
  const max_price_string = formatCents(max_price_cents);
  // return the price range
  return `${min_price_string} - ${max_price_string}`;
}

export async function getProgramForm(
  form_id: string
): Promise<ProgramFormPropertiesFragment> {
  const where: Program_Forms_Bool_Exp = {
    id: {
      _eq: form_id,
    },
  };
  const result = await ApolloService.client.query<
    FetchProgramFormsQuery,
    FetchProgramFormsQueryVariables
  >({
    query: FetchProgramFormsDocument,
    variables: {
      where,
    },
  });
  const program_form = result?.data?.program_forms?.[0];
  if (!program_form) {
    throw Error(
      `Error in getProgramForm: No program form found for id ${form_id}`
    );
  }
  return program_form;
}

export async function getFormSubmissionInputs(
  householdMemberForms: FormSubmissionsByHouseholdMember["householdMemberId"],
  fakeProgramForms?: ProgramFormPropertiesFragment[]
): Promise<FormSubmissionInput[]> {
  const inputs: FormSubmissionInput[] = [];
  for (const key in householdMemberForms) {
    const file: FormSubmission = householdMemberForms[key];
    const existingDocumentId: string | undefined = file.existingDocumentId;
    const newDocument: UserFile | undefined = file.newDocument;
    const schemaFormData = file.schemaFormData;
    if (existingDocumentId) {
      const input: FormSubmissionInput = {
        form_definition_id: file.formDefinitionId,
        form_id: key,
        existing_media_info_id: existingDocumentId,
        input_type: FormSubmissionInputType.ExistingFamilyMemberDocument,
      };
      inputs.push(input);
    } else if (newDocument) {
      // default is new household document
      let input_type: FormSubmissionInputType =
        FormSubmissionInputType.NewFamilyMemberDocument;
      // get the program form (if exists)
      let program_form: ProgramFormPropertiesFragment | undefined = undefined;
      if (fakeProgramForms) {
        program_form = fakeProgramForms.find((form) => form.id == key);
      } else {
        program_form = await getProgramForm(key);
      }
      // change the form type based on the program form
      if (program_form) {
        // get the form type
        const form_type: Form_Types_Enum =
          program_form.associated_form_definition!.type;
        if (form_type == Form_Types_Enum.Disclaimer) {
          // if the type of form is Disclaimer, change the document type to generic insert
          input_type = FormSubmissionInputType.NewGenericDocument;
        }
      }
      const input: FormSubmissionInput = {
        form_definition_id: file.formDefinitionId,
        form_id: key,
        media_info: {
          content_type: newDocument.contentType,
          alt: newDocument.alt,
          storage_url: newDocument.tempSignedUrl,
          index: 0,
          name: newDocument.name,
          description:
            program_form?.associated_form_definition!.name ||
            newDocument.originalFileName,
        },
        input_type,
      };
      inputs.push(input);
    } else if (schemaFormData) {
      inputs.push({
        form_definition_id: file.formDefinitionId,
        schema_form_definition_id: file.schemaFormDefinitionId,
        schema_form_document: schemaFormData,
        form_id: key,
        existing_media_info_id: existingDocumentId,
        input_type: FormSubmissionInputType.ExistingFamilyMemberDocument,
      });
    } else {
      throw Error(
        `Error in getFormSubmissionInput: No newDocument or existingDocumentId found for program form id ${key}`
      );
    }
  }
  return inputs;
}

export async function convertToInsertRegistrableUnitSubmissionInput({
  registrableUnitId,
  householdMemberId,
  organizationId,
  stripeCustomerId,
  stripePaymentMethodId,
  user,
  userAddress,
  submittedForms,
  isWaitlist,
}: RegistrableUnitSubmissionsInput): Promise<InsertRegistrableUnitSubmissionInput> {
  let form_submission_inputs: FormSubmissionInput[] = [];
  if (submittedForms) {
    form_submission_inputs = await getFormSubmissionInputs(
      submittedForms[householdMemberId]
    );
  }
  const output: InsertRegistrableUnitSubmissionInput = {
    // TODO: Stop hardcoding allow_charge_stripe when we handle waitlist registration
    // https://linear.app/kaizenlabs/issue/ENG-1043/program-waitlist-registration
    allow_charge_stripe: true,
    check_registration_allowed: true,
    registrable_unit_id: registrableUnitId,
    household_member_id: householdMemberId,
    organization_id: organizationId,
    stripe_customer_id: stripeCustomerId,
    stripe_payment_method_id: stripePaymentMethodId,
    billing_user_id: user.id,
    // TODO: Revisit if we want the following user variables to be required here
    // or if we should just pull them from the user
    // https://linear.app/kaizenlabs/issue/ENG-1042/remove-user-properties-from-registrable-unit-submission-table
    billing_user_name: userAddress.name,
    billing_user_email: userAddress.email,
    billing_user_phone_number: userAddress.phone_number ?? "",
    billing_user_is_resident: user.is_resident,
    billing_user_address_line_1: userAddress.address_line_1,
    billing_user_address_line_2: userAddress.address_line_2,
    billing_user_city: userAddress.locality,
    billing_user_state: userAddress.administrative_area ?? "",
    billing_user_postal_code: userAddress.postal_code,
    billing_user_country: userAddress.country,
    add_to_waitlist: isWaitlist,
    form_submission_inputs,
  };
  return output;
}

export async function getInsertRegistrableUnitSubmissionInputs({
  cartItems,
  organizationId,
  stripeCustomerId,
  paymentMethodId,
  userData,
  address,
  formSubmissions,
}: RegistrableUnitSubmissionCartAndRelated): Promise<
  InsertRegistrableUnitSubmissionInput[]
> {
  // convert cart items to inputs
  const inputs: RegistrableUnitSubmissionsInput[] = flatten(
    cartItems.map((item) => {
      return item.selectedHouseholdMemberIds.map((memberId) => {
        return {
          isWaitlist: item.registeringForWaitlist,
          registrableUnitId: item.registrableUnitId,
          householdMemberId: memberId,
          organizationId,
          stripeCustomerId,
          stripePaymentMethodId: paymentMethodId,
          user: userData,
          userAddress: address,
          submittedForms: formSubmissions,
        };
      });
    })
  );
  // convert inputs to InsertRegistrableUnitSubmissionInput
  const input_objects: InsertRegistrableUnitSubmissionInput[] =
    await Promise.all(
      inputs.map(async (input) => {
        return await convertToInsertRegistrableUnitSubmissionInput(input);
      })
    );
  return input_objects;
}

export const getAvailableRegistrableUnits = (
  registrableUnits: RegistrableUnitPropertiesFragment[],
  program?: ProgramPropertiesFragment | null
) => {
  if (!program) {
    return [];
  }

  return registrableUnits.filter((unit) => {
    const status = getRegistrableUnitStatusFromInputs(program, unit);

    const isInvalidStatus = [
      RegistrableUnitStatus.Cancelled,
      RegistrableUnitStatus.Closed,
    ].includes(status);

    return !isInvalidStatus;
  });
};

export function getRegistrableUnitsAndStatuses(
  program: ProgramPropertiesFragment,
  registrableUnits:
    | RegistrableUnitPropertiesFragment[]
    | RegistrableUnitAndRosterScheduleFragment[]
) {
  // get registrable units
  const registrableUnitsAndStatuses: RegistrableUnitAndStatus[] = [];

  for (let registrableUnit of registrableUnits) {
    const registrableUnitStatus = getRegistrableUnitStatusFromInputs(
      program,
      registrableUnit
    );
    registrableUnitsAndStatuses.push({
      registrableUnit,
      status: registrableUnitStatus,
    });
  }

  return registrableUnitsAndStatuses;
}

export function getRegistrationWindowForProgramOrRegistrableUnit(
  program: ProgramPropertiesFragment,
  registrable_unit?: RegistrableUnitPropertiesFragment
) {
  const overrideSchedule =
    firstOrNull(registrable_unit?.associated_override_registration_schedules)
      ?.associated_temporal_event ?? null;

  if (overrideSchedule) {
    return {
      schedule: overrideSchedule,
      isOverride: true,
    };
  }

  const programDefaultSchedule =
    firstOrNull(
      program?.program_registration_schedules.filter(
        (schedule) => schedule.override_registrable_unit_id == null
      )
    )?.associated_temporal_event ?? null;

  return {
    schedule: programDefaultSchedule,
    isOverride: false,
  };
}

export const getStartDateTimeFromFormData = (
  value: TemporalEventFormData
): DateTime | null => {
  if (!value.startDate || !value.startTime) {
    return null;
  }

  const startTime = DateTime.fromISO(value.startTime);

  return DateTime.fromISO(value.startDate).set({
    hour: startTime.hour,
    minute: startTime.minute,
  });
};

export const getEndDateTimeFromFormData = (
  value: TemporalEventFormData
): DateTime | null => {
  if (!value.endDate || !value.endTime) {
    return null;
  }

  const endTime = DateTime.fromISO(value.endTime);

  return DateTime.fromISO(value.endDate).set({
    hour: endTime.hour,
    minute: endTime.minute,
  });
};

export const getStartDateTime = (
  value: TemporalEventPropertiesFragment
): DateTime | null => {
  if (!value.start_date || !value.start_time) {
    return null;
  }

  const startTime = DateTime.fromISO(value.start_time);

  return DateTime.fromISO(value.start_date).set({
    hour: startTime.hour,
    minute: startTime.minute,
  });
};

export const getEndDateTime = (
  value: TemporalEventPropertiesFragment
): DateTime | null => {
  if (!value.end_date || !value.end_time) {
    return null;
  }

  const endTime = DateTime.fromISO(value.end_time);

  return DateTime.fromISO(value.end_date).set({
    hour: endTime.hour,
    minute: endTime.minute,
  });
};

export function doesRosterItemHaveEveryRequiredForm(
  rosterItem: RosterItemRequirementsPropertiesFragment
) {
  const formSubmissionsById = groupBy(
    rosterItem.associated_form_submissions,
    (d) => d.associated_program_form?.id
  );

  return rosterItem.associated_program?.associated_program_forms.every(
    (form) => {
      return formSubmissionsById[form.id] != null;
    }
  );
}

export function isCamp(program: ProgramPropertiesFragment) {
  return program.type === Program_Types_Enum.Camp;
}

export function isTicketedEvent(program: ProgramPropertiesFragment) {
  return program.type === Program_Types_Enum.TicketedEvent;
}

function formatAgeRequirement(
  years: Maybe<number>,
  months?: Maybe<number>
): string | null {
  if (years != null && months != null) {
    return `${years}y ${months}mo`;
  }

  if (years != null) {
    return `${years}y`;
  }

  if (months != null) {
    return `${months}mo`;
  }

  return null;
}

export function getAgeRequirementsText({
  program,
  registrableUnit,
}: {
  program: ProgramPropertiesFragment;
  registrableUnit?: RegistrableUnitPropertiesFragment;
}) {
  const minAge = formatAgeRequirement(
    registrableUnit?.override_min_age_years ?? program.min_age_years,
    registrableUnit?.override_min_age_extra_months ??
      program.min_age_extra_months
  );

  const maxAge = formatAgeRequirement(
    registrableUnit?.override_max_age_years ?? program.max_age_years,
    registrableUnit?.override_max_age_extra_months ??
      program.max_age_extra_months
  );

  if (minAge != null && maxAge != null) {
    return `${minAge} - ${maxAge}`;
  }

  if (minAge != null) {
    return `Minimum age: ${minAge}`;
  }

  if (maxAge != null) {
    return `Maximum age: ${maxAge}`;
  }

  return "All ages";
}

export function programTimeFormat(time: string) {
  return moment.parseZone(time, "HH:mm:ssZ").format("h:mm A");
}

export function programDateFormat(date: string) {
  return moment(date).format("M/D/YYYY");
}

export function formatProgramDateRange(startDate?: string, endDate?: string) {
  if (startDate && endDate && startDate != endDate) {
    return `${programDateFormat(startDate)} - ${programDateFormat(endDate)}`;
  }

  if (startDate) {
    return programDateFormat(startDate);
  }
}

export function formatProgramTimeRange(startTime?: string, endTime?: string) {
  if (startTime && endTime) {
    return `${programTimeFormat(startTime)} - ${programTimeFormat(endTime)}`;
  }
}

interface CartItemPrice {
  price: number;
  fee: number;
}

export function computeCartItemPrice(
  cartItem: ProgramCartItemData,
  paymentOptions: ProgramPaymentOptionPropertiesFragment[],
  paymentType?: string,
  evaluations?: ProgramsPriceEvaluation[]
): CartItemPrice {
  if (
    cartItem.programType === Program_Types_Enum.Camp ||
    cartItem.programType === Program_Types_Enum.TicketedEvent
  ) {
    return computeCampCartItemPrice(
      cartItem.registrableUnits,
      paymentOptions,
      paymentType
    );
  } else {
    return computeClassCartItemPrice(
      cartItem,
      paymentOptions,
      paymentType,
      evaluations
    );
  }
}

export function computeCampCartItemPrice(
  registrableUnits: RegistrableUnitCartData[],
  paymentOptions: ProgramPaymentOptionPropertiesFragment[],
  paymentType?: string
): CartItemPrice {
  let totalPrice = 0;
  let totalFeeAmount = 0;

  registrableUnits.forEach((unit) => {
    if (unit.registeringForWaitlist) {
      return;
    }

    const price = getPriceForProgram(paymentOptions, unit.id);
    const stripeFee = getPaymentTypeFee(price, paymentType ?? "card");

    totalPrice += price;
    totalFeeAmount += stripeFee;
  });

  return {
    price: totalPrice,
    fee: totalFeeAmount,
  };
}

export function computeClassCartItemPrice(
  cartItem: ClassCartItemData,
  paymentOptions: ProgramPaymentOptionPropertiesFragment[],
  paymentType?: string,
  evaluations?: ProgramsPriceEvaluation[]
): CartItemPrice {
  if (cartItem.registeringForWaitlist) {
    return {
      price: 0,
      fee: 0,
    };
  }

  let totalPrice = 0;
  let totalFee = 0;

  cartItem.selectedHouseholdMemberIds.forEach((memberId) => {
    const evaluation = evaluations?.find((e) => {
      return (
        e.cart_item_id === cartItem.id &&
        e.registrable_unit_id === cartItem.registrableUnitId &&
        e.household_member_id === memberId
      );
    });

    const price = getPriceForProgram(
      paymentOptions,
      cartItem.registrableUnitId
    );

    const fee = getPaymentTypeFee(
      evaluation?.price ?? price,
      paymentType ?? "card"
    );

    totalPrice += price;
    totalFee += fee;
  });

  return {
    price: totalPrice,
    fee: totalFee,
  };
}

export function getTemporalEventForRegistrableUnit(
  registrableUnit: RegistrableUnitPropertiesFragment
) {
  const roster = registrableUnit.associated_rosters[0];
  const schedule = roster.associated_program_roster_schedule;

  return schedule?.associated_temporal_event;
}

export function cartHasWaitlistItems(cartItems: ProgramCartItemData[]) {
  return cartItems.some((item) => {
    if (
      item.programType === Program_Types_Enum.Camp ||
      item.programType === Program_Types_Enum.TicketedEvent
    ) {
      return item.registrableUnits.some((unit) => unit.registeringForWaitlist);
    } else {
      return item.registeringForWaitlist;
    }
  });
}

export function extractRegistrableUnitCartData({
  registrableUnit,
  status,
}: RegistrableUnitAndStatus): RegistrableUnitCartData {
  return {
    id: registrableUnit.id,
    programGroupId: registrableUnit.program_group_id,
    registeringForWaitlist: status === RegistrableUnitStatus.Waitlist,
  };
}

export function hasWaitlistRegistrations(
  cartItem: CampCartItemData | TicketedEventCartItemData
) {
  return cartItem.registrableUnits.some((u) => u.registeringForWaitlist);
}

export function createFormSubmissionInput(
  registration: RegistrableUnitSubmissionFormSubmissionRequirementsPropertiesFragment,
  householdMember: HouseholdMemberPropertiesFragment,
  programForm: ProgramFormPropertiesFragment,
  data: DocumentSubmission,
  userId: string
): Registrable_Unit_Submission_Form_Submissions_Insert_Input {
  const formDefinition = programForm.associated_form_definition!;

  const mediaInfo: Registrable_Unit_Submission_Form_Submissions_Insert_Input["associated_media_info"] =
    [Form_Types_Enum.Disclaimer, Form_Types_Enum.File].includes(
      formDefinition.type
    )
      ? {
          data: convertUserFileToMediaInfoInput(
            data.file!,
            registration.organization_id,
            "Uploaded document for form"
          ),
        }
      : undefined;

  const schemaFormSubmission: Registrable_Unit_Submission_Form_Submissions_Insert_Input["associated_schema_form_submission"] =
    formDefinition.type === Form_Types_Enum.SchemaForm
      ? {
          data: {
            form_definition_id: formDefinition.id,
            schema_form_definition_id:
              formDefinition.associated_schema_form_definition!.id,
            user_id: userId,
            organization_id: registration.organization_id,
            form_data: data.schemaFormData,
          },
        }
      : undefined;

  return {
    household_member_id: householdMember.id,
    organization_id: registration.organization_id,
    program_id: registration.program_id,
    registrable_unit_id: registration.registrable_unit_id,
    registrable_unit_submission_id: registration.id,
    program_form_id: programForm.id,
    billing_user_id: registration.billing_user_id,
    name: `${programForm.associated_form_definition?.name} - ${householdMember.first_name} ${householdMember.last_name}`,
    associated_media_info: mediaInfo,
    associated_schema_form_submission: schemaFormSubmission,
  };
}

export function sortProgramGroupsByName<
  Group extends ProgramGroupPropertiesFragment
>(groups: Group[]) {
  return groups.toSorted((a, b) => {
    return a.name.localeCompare(b.name);
  });
}

export function sortProgramGroupsChronologically<
  Group extends ProgramGroupPropertiesFragment
>(groups: Group[]) {
  return groups.toSorted((a, b) => {
    const a_sorted_registrable_units = a.registrable_units.toSorted((a, b) =>
      registrableUnitDateTimeComparator(a, b, "start_date")
    );
    const b_sorted_registrable_units = b.registrable_units.toSorted((a, b) =>
      registrableUnitDateTimeComparator(a, b, "start_date")
    );
    const a_earliest_registrable_unit = firstOrNull(a_sorted_registrable_units);
    const b_earliest_registrable_unit = firstOrNull(b_sorted_registrable_units);
    return a_earliest_registrable_unit && b_earliest_registrable_unit
      ? registrableUnitDateTimeComparator(
          a_earliest_registrable_unit,
          b_earliest_registrable_unit,
          "start_date"
        )
      : 0;
  });
}

export function getLatestUpdatedGroup<
  Group extends ProgramGroupPropertiesFragment
>(groups: Group[]) {
  return groups.toSorted((a, b) => {
    return a.updated_at.localeCompare(b.updated_at);
  })[groups.length - 1];
}

type SubmissionWithPaymentJunctions = {
  associated_payments: {
    payments_cents: number;
    payment_status: Payment_Statuses_Enum;
  }[];
};

export function computeRemainingBalanceForSubmissions(
  registrableUnitSubmissions: SubmissionWithPaymentJunctions[]
) {
  let remainingBalanceCents = 0;
  let totalCostCents = 0;

  registrableUnitSubmissions.forEach((submission) => {
    submission.associated_payments.forEach((payment) => {
      totalCostCents += payment.payments_cents;

      if (
        ![
          Payment_Statuses_Enum.Missing,
          Payment_Statuses_Enum.Scheduled,
        ].includes(payment.payment_status)
      ) {
        return;
      }

      remainingBalanceCents += payment.payments_cents ?? 0;
    });
  });

  return {
    remainingBalanceCents,
    totalCostCents,
  };
}

export type SubmissionPaymentStatus = ReturnType<typeof discernPaymentStatus>;

export function discernPaymentStatus(
  registrableUnitSubmissions: SubmissionWithPaymentJunctions[]
) {
  const { remainingBalanceCents, totalCostCents } =
    computeRemainingBalanceForSubmissions(registrableUnitSubmissions);

  return {
    needsPayment: remainingBalanceCents > 0,
    remainingBalanceCents,
    totalCostCents,
  };
}

export function discernFormSubmissionStatus(
  registrableUnitSubmissions: RegistrableUnitSubmissionAndRelatedPropertiesFragment[]
) {
  const needsFormSubmissions = registrableUnitSubmissions.some((submission) => {
    const formSubmissionsCount = submission.associated_form_submissions.length;
    const requiredFormsCount =
      submission.associated_program?.associated_program_forms.length ?? 0;

    return formSubmissionsCount !== requiredFormsCount;
  });

  return {
    needsFormSubmissions,
  };
}

export function isHouseholdMemberAlreadyRegistered(
  unitStatusMapping: RegistrableUnitAndStatus,
  householdMember: Maybe<HouseholdMemberPropertiesFragment>
) {
  const existingSubmissions = householdMember?.registrable_unit_submissions;
  const alreadyRegistered = !!existingSubmissions?.find((s) => {
    return s.registrable_unit_id === unitStatusMapping.registrableUnit.id;
  });

  return alreadyRegistered;
}

export function isRegistrableUnitSelectable(
  unitStatusMapping: RegistrableUnitAndStatus,
  householdMember?: Maybe<HouseholdMemberPropertiesFragment>
) {
  const alreadyRegistered = isHouseholdMemberAlreadyRegistered(
    unitStatusMapping,
    householdMember
  );

  if (alreadyRegistered) {
    return false;
  }

  const acceptableStatuses = [
    RegistrableUnitStatus.Open,
    RegistrableUnitStatus.Waitlist,
  ];

  return acceptableStatuses.includes(unitStatusMapping.status);
}

export function findSelectableUnits(
  unitStatusMappings: RegistrableUnitAndStatus[],
  householdMember?: Maybe<HouseholdMemberPropertiesFragment>
) {
  return unitStatusMappings.filter((mapping) =>
    isRegistrableUnitSelectable(mapping, householdMember)
  );
}

export function formatCartItemDataForServer(
  cartItem: ProgramCartItemData
): CartItem {
  if (isCampCartItem(cartItem) || isTicketedEventCartItem(cartItem)) {
    return {
      id: cartItem.id,
      household_member_ids: [cartItem.selectedHouseholdMemberId],
      program_id: cartItem.programId,
      registrable_units: cartItem.registrableUnits.map((unit) => ({
        id: unit.id,
        program_group_id: unit.programGroupId,
        registering_for_waitlist: unit.registeringForWaitlist,
      })),
    };
  } else {
    return {
      id: cartItem.id,
      household_member_ids: cartItem.selectedHouseholdMemberIds,
      program_id: cartItem.programId,
      registrable_units: [
        {
          id: cartItem.registrableUnitId,
          program_group_id: cartItem.programGroupId,
          registering_for_waitlist: cartItem.registeringForWaitlist,
        },
      ],
    };
  }
}

export const getProgramGroupName = (
  programGroupId?: string,
  programGroups?: {
    name: string;
    id: string;
  }[]
) => {
  if (!programGroupId || !programGroups?.length) {
    return "-";
  }

  return programGroups.find((pg) => pg.id === programGroupId)?.name ?? "-";
};

export interface PriceComponents {
  subtotal: number;
  fees: number;
  total: number;
  scheduled: number;
  discounted: number;
  paymentType: string;
}

export function computeTotalPriceForCartItems(
  cartItems: ProgramCartItemData[],
  paymentType: string,
  programs: ProgramWithChildrenPropertiesFragment[],
  evaluations?: ProgramsPriceEvaluation[]
): PriceComponents {
  let subtotal = 0;
  let fees = 0;
  let scheduled = 0;

  cartItems.forEach((cartItem) => {
    const program = programs.find(({ id }) => id === cartItem.programId);

    const registrableUnit = isClassCartItem(cartItem)
      ? program?.program_groups
          .flatMap((group) => group.registrable_units)
          .find((unit) => unit.id === cartItem.registrableUnitId)
      : null;

    if (!program?.program_payment_options?.length) {
      return;
    }

    const { price, fee } = computeCartItemPrice(
      cartItem,
      program.program_payment_options,
      paymentType,
      evaluations
    );

    if (registrableUnit?.scheduled_payment_on) {
      scheduled += price + fee;
    }

    subtotal += price;
    fees += fee;
  });

  const discounted =
    evaluations?.reduce((discounted, evaluation) => {
      let discountedAmount = 0;

      evaluation.applied_modifiers.forEach((mod) => {
        if (mod.is_discount) {
          discountedAmount += mod.amount_modified;
        }
      });

      return discounted + discountedAmount;
    }, 0) ?? 0;

  const total = subtotal + fees - scheduled - discounted;

  return {
    subtotal,
    fees,
    total,
    scheduled,
    discounted,
    paymentType,
  };
}
