import { useMemo } from "react";
import { loggingMiddleware, reduceCompoundActions } from "redoodle";
import { applyMiddleware, createStore, Reducer, Store } from "redux";
import { PersistConfig, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { match } from "ts-pattern";

import { Program_Types_Enum } from "../api/generated";
import { CommunityEventFormStep, CommunityEventTab } from "../constants/types";
import { appReducer, IAppState, initialAppState } from "./app";

let store: Store<IAppState>;

function migrateCommunityEventActiveStep(state: any) {
  // Check if active step already migrated to enum
  if (
    Object.values(CommunityEventFormStep).includes(
      state?.community_event?.activeStep
    )
  ) {
    return state?.community_event?.activeStep;
  }

  // remap activeStep from 9 to 8
  const result =
    state?.community_event?.activeStep === 9
      ? 8
      : //@ts-ignore
        state?.community_event?.activeStep;

  // remap from number to enum
  return (
    match(result)
      .with(1, () => CommunityEventFormStep.EventType)
      .with(2, () => CommunityEventFormStep.OrganizerContactInfo)
      .with(3, () => CommunityEventFormStep.EventDetails)
      .with(4, () => CommunityEventFormStep.LocationTimingAndAttendees)
      .with(5, () => CommunityEventFormStep.SiteMap)
      .with(6, () => CommunityEventFormStep.VendorOrExhibitorInformation)
      .with(7, () => CommunityEventFormStep.FormsWaiversRequiredDocuments)
      .with(8, () => CommunityEventFormStep.ApplicationSummary)
      // The following case is unexpected because of the remapping above, but including it just to be thorough
      .with(9, () => CommunityEventFormStep.PayFeesAndConfirm)
      .otherwise(() => CommunityEventFormStep.EventType)
  );
}

function migrateCartItem(item: any) {
  if (item.programType) {
    return item;
  }

  if (item.registrableUnitId) {
    return {
      programType: Program_Types_Enum.Class,
      ...item,
    };
  }

  if (item.registrableUnits) {
    return {
      programType: Program_Types_Enum.Camp,
      ...item,
    };
  }
}

export function configureStore(clientSide: boolean): Store<IAppState> {
  const reducer = clientSide
    ? reduceCompoundActions(
        persistReducer(
          {
            key: "cedar",
            storage: storage,
            whitelist: ["community_event", "program", "cart", "membership"], // add stores you want to persist here
            migrate: (state) => {
              return Promise.resolve({
                ...state,
                cart: {
                  cartItems:
                    //@ts-ignore
                    state?.cart?.cartItems
                      ?.map(migrateCartItem)
                      ?.filter((item: any) => !!item) ?? [],
                  formSubmissions:
                    //@ts-ignore
                    state?.cart?.formSubmissions,
                  showErrorForEmptyForms:
                    //@ts-ignore
                    false,
                },
                membership: {
                  membershipFormSubmissions:
                    //@ts-ignore
                    state?.membership?.membershipFormSubmissions,
                },
                community_event: {
                  //@ts-ignore
                  ...state?.community_event,
                  parksNoticeAcknowledged: false,
                  activeTab: CommunityEventTab.ApprovalsOverview,
                  activeStep:
                    //@ts-ignore
                    migrateCommunityEventActiveStep(state),
                  communityEventApplication: {
                    //@ts-ignore
                    ...(state?.community_event?.communityEventApplication ??
                      {}),
                    selectedType:
                      //@ts-ignore
                      state?.community_event?.communityEventApplication
                        ?.selectedType,
                    entirely_held_on_private_property:
                      //@ts-ignore
                      state?.community_event?.communityEventApplication
                        ?.entirely_held_on_private_property ?? false,
                  },
                  restoreFromDraftAcknowledged:
                    !state ||
                    //@ts-ignore
                    (migrateCommunityEventActiveStep(state) &&
                      //@ts-ignore
                      !state.community_event?.communityEventApplication
                        ?.selectedType),
                },
              });
            },
          } as PersistConfig<IAppState>,
          appReducer as any
        ) as any
      )
    : reduceCompoundActions(appReducer as any);

  const enhancer = applyMiddleware(
    loggingMiddleware({ enableInProduction: false, ignore: [] })
  );
  const createStoreWithMiddleware = enhancer(createStore);

  const store = createStoreWithMiddleware(
    reducer as Reducer<IAppState | undefined>,
    {
      ...initialAppState,
    }
  );

  if (clientSide) persistStore(store);

  return store;
}

export function usePersistedStore() {
  const clientSide = !(typeof window === "undefined");
  return useMemo(() => {
    let _store = store ?? configureStore(clientSide);
    // For SSG and SSR always create a new store
    if (clientSide) return _store;
    // Create the store once in the client
    if (!store) store = _store;
    return store;
  }, [clientSide]);
}
