import { Card, CardContent, Container } from "@mui/material";
import React from "react";

interface LoadableOrganization {
  slug: string;
  name: string;
  description: string;
}

const supportedOrganizations: LoadableOrganization[] = [
  {
    name: "Santa Rosa",
    slug: "santarosa",
    description: "Citizen Engagement",
  },
  {
    name: "Castle Pines",
    slug: "castlepines",
    description: "Recreation Management",
  },
  {
    name: "Weymouth",
    slug: "weymouth",
    description: "Recreation Management",
  },
  {
    name: "Mountain House",
    slug: "mountainhouse",
    description: "Permitting",
  },
  {
    name: "Westminster",
    slug: "westminster",
    description: "Citizen Engagement",
  },
  {
    name: "San Antonio",
    slug: "sanantonio",
    description: "Website Redesign",
  },
  {
    name: "Parker",
    slug: "parker",
    description: "Community Events",
  },
  {
    name: "Monterey Park",
    slug: "montereypark",
    description: "Recreation Management",
  },
  {
    name: "Evanston",
    slug: "evanston",
    description: "Recreation Management",
  },
  {
    name: "Metro Parks",
    slug: "metroparks",
    description: "Recreation Management",
  },
  {
    name: "Crown Mountain",
    slug: "crownmountain",
    description: "Recreation Management",
  },
  {
    name: "Clear Creek",
    slug: "ccmrd",
    description: "Recreation Management",
  },
  {
    name: "Pima County",
    slug: "pima",
    description: "Recreation Management",
  },
  {
    name: "Tulare County",
    slug: "tulare",
    description: "Recreation Management",
  },
  {
    name: "Ken Caryl Metro District",
    slug: "kencaryl",
    description: "Recreation Management",
  },
  {
    name: "Sarasota",
    slug: "sarasota",
    description: "Recreation Management",
  },
  {
    name: "Redwood City",
    slug: "redwoodcity",
    description: "Recreation Management",
  },
  {
    name: "Northville",
    slug: "northville",
    description: "Recreation Management",
  },
];

/**
 * This component handles the case where a developer forgets to put the name of an organization in their URL.
 * If this happens, the application doesn't know which interface to render, and renders a blank page.
 *
 * This simple loader helps guide him or her to the right place.
 */
const OrganizationLoader = () => {
  return (
    <Container
      maxWidth={"xl"}
      className="h-screen w-screen flex flex-col justify-center items-center"
    >
      <img
        src={
          "https://storage.googleapis.com/kaizen_labs_assets/logo_twitter.jpg"
        }
        alt="Company Logo"
        height={100}
        width={100}
        className="mb-4"
      />
      <p className="font-bold mb-2 text-20">Please pick your organization</p>
      <p className="font-bold mb-6 text-13 italic">
        Make sure to add the organization name to your url (e.g.
        localhost:3000/santarosa)
      </p>
      <div className="grid gap-4 grid-cols-3 grid-rows-3">
        {supportedOrganizations.map((organization) => {
          return (
            <Card
              key={organization.name}
              className="cursor-pointer relative"
              elevation={5}
              onClick={() => {
                const newUrl = window.location.origin + `/${organization.slug}`;
                window.open(newUrl, "_self"); // Do window.open to force _document.tsx to be re-called and fonts to load properly.
              }}
            >
              <CardContent>
                <p className="font-bold mb-1">{organization.name}</p>
                <p className="mb-1">{organization.description}</p>
              </CardContent>
            </Card>
          );
        })}
      </div>
    </Container>
  );
};

export default OrganizationLoader;
