import { CircularProgress, Typography } from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useState } from "react";

interface Props {
  className?: string;
  delay?: number;
  fullScreen?: boolean;
  organization?: string;
  label?: string;
}

/**
 * This component is used as fall back while the application is being loaded, or if components are being lazily loaded.
 *
 * It has an optional delay parameter that defaults to 0. If the delay is supplied, the spinner will not show until the delay is over.
 * This is to avoid small flashes when lazily loading components that end up loading very quickly.
 *
 * The other optional parameter is a full screen specifier. If true (enabled by default), the spinner will cover the whole screen. If false,
 * it will fill its parent container.
 */
const Loading = ({
  delay = 0,
  fullScreen = true,
  className,
  label,
  organization,
}: Props) => {
  const [showLoadingIndicator, setLoadingIndicatorVisibility] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setLoadingIndicatorVisibility(true), delay);
    return () => {
      clearTimeout(timer);
    };
  });
  let color = "#008550";
  if (organization === "westminster") {
    color = "#0c2752";
  } else if (organization === "santarosa") {
    color = "#712a3d";
  } else if (organization === "weymouth") {
    color = "#010150";
  }
  return showLoadingIndicator ? (
    <div
      className={classNames(
        { "h-screen w-screen": fullScreen },
        { "relative h-full w-full": !fullScreen },
        "flex flex-col items-center justify-center",
        className
      )}
    >
      <CircularProgress size={25} sx={{ color }} />
      {label && <Typography className="font-medium mt-3">{label}</Typography>}
    </div>
  ) : null;
};

export default Loading;
