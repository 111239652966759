import update from "immutability-helper";
import { defineAction, setWith, TypedReducer } from "redoodle";
import * as Reselect from "reselect";

import {
  CommunityEventApplicationData,
  CommunityEventFormStep,
  CommunityEventTab,
  UserFile,
} from "../constants/types";
import { IAppState } from "./app";

// model
export interface ICommunityEventState {
  activeStep: CommunityEventFormStep;
  communityEventApplication?: CommunityEventApplicationData;
  parksNoticeAcknowledged: boolean;
  restoreFromDraftAcknowledged: boolean;
  activeTab: CommunityEventTab;
}

// actions
export const ResetCommunityEventState = defineAction(
  "APP/COMMUNITY_EVENT/RESET_COMMUNITY_EVENT_STATE"
)();
export const SetCommunityEventApplication = defineAction(
  "APP/COMMUNITY_EVENT/SET_COMMUNITY_EVENT_APPLICATION"
)<CommunityEventApplicationData | undefined>();
// Sets the active step, but only if the given step is defined
export const MaybeSetActiveCommunityEventStep = defineAction(
  "APP/COMMUNITY_EVENT/MAYBE_SET_ACTIVE_STEP"
)<CommunityEventFormStep | null | undefined>();
export const SetActiveCommunityEventStep = defineAction(
  "APP/COMMUNITY_EVENT/SET_ACTIVE_STEP"
)<CommunityEventFormStep>();
export const SetParksNoticeAcknowledged = defineAction(
  "APP/COMMUNITY_EVENT/SET_PARKS_NOTICE_ACKNOWLEDGED"
)<boolean>();
export const SetResumeFromDraftAcknowledged = defineAction(
  "APP/COMMUNITY_EVENT/SET_RESUME_FROM_DRAFT_ACKNOWLEDGED"
)<boolean>();
export const SetActiveTab = defineAction(
  "APP/COMMUNITY_EVENT/SET_ACTIVE_TAB"
)<CommunityEventTab>();
export const AddCommunityEventRequiredFiles = defineAction(
  "APP/COMMUNITY_EVENT/ADD_REQUIRED_FILE"
)<{ id: string; files: UserFile[] }>();
export const RemoveCommunityEventRequiredFile = defineAction(
  "APP/COMMUNITY_EVENT/REMOVE_REQUIRED_FILE"
)<{ id: string; index: number }>();

// reducer
export const communityEventReducer: any =
  TypedReducer.builder<ICommunityEventState>()
    .withHandler(SetActiveCommunityEventStep.TYPE, (state, activeStep) =>
      setWith(state, { activeStep })
    )
    .withHandler(MaybeSetActiveCommunityEventStep.TYPE, (state, activeStep) =>
      activeStep ? setWith(state, { activeStep }) : state
    )
    .withHandler(
      SetCommunityEventApplication.TYPE,
      (state, communityEventApplication) =>
        setWith(state, { communityEventApplication })
    )
    .withHandler(
      SetParksNoticeAcknowledged.TYPE,
      (state, parksNoticeAcknowledged) =>
        setWith(state, { parksNoticeAcknowledged })
    )
    .withHandler(
      SetResumeFromDraftAcknowledged.TYPE,
      (state, restoreFromDraftAcknowledged) =>
        setWith(state, { restoreFromDraftAcknowledged })
    )
    .withHandler(ResetCommunityEventState.TYPE, (state) =>
      update(state, { $set: initialCommunityEventState })
    )
    .withHandler(SetActiveTab.TYPE, (state, activeTab) =>
      setWith(state, { activeTab })
    )
    .withHandler(
      AddCommunityEventRequiredFiles.TYPE,
      (state, { id, files }) => {
        const existingFilesForId =
          state.communityEventApplication?.required_documents?.[id] ?? [];
        const filesToAdd = files.map((file) => {
          return {
            ...file,
            description: id,
          };
        });
        const newFiles = [...existingFilesForId, ...filesToAdd];
        return setWith(state, {
          ...state,
          communityEventApplication: {
            ...state.communityEventApplication,
            required_documents: {
              ...state.communityEventApplication?.required_documents,
              [id]: newFiles,
            },
          } as CommunityEventApplicationData,
        });
      }
    )
    .withHandler(
      RemoveCommunityEventRequiredFile.TYPE,
      (state, { id, index }) => {
        const existingFilesForId =
          state.communityEventApplication?.required_documents?.[id] ?? [];
        const deletedFiles =
          index === 0 ? undefined : existingFilesForId.splice(index, 1);
        return setWith(state, {
          ...state,
          communityEventApplication: {
            ...state.communityEventApplication,
            required_documents: {
              ...state.communityEventApplication?.required_documents,
              [id]: deletedFiles,
            },
          } as CommunityEventApplicationData,
        });
      }
    )
    .withDefaultHandler((state) => (state ? state : initialCommunityEventState))
    .build();

// init
export const initialCommunityEventState: ICommunityEventState = {
  activeStep: CommunityEventFormStep.DetermineEventType,
  parksNoticeAcknowledged: false,
  restoreFromDraftAcknowledged: false,
  activeTab: CommunityEventTab.ApprovalsOverview,
};

// selectors

export const communityEventApplicationSelector = Reselect.createSelector(
  (state: IAppState) => state.community_event.communityEventApplication,
  (communityEventApplication: CommunityEventApplicationData | undefined) => {
    return communityEventApplication;
  }
);

export const parksNoticeAcknowledgedSelector = Reselect.createSelector(
  (state: IAppState) => state.community_event.parksNoticeAcknowledged,
  (parksNoticeAcknowledged: boolean) => {
    return parksNoticeAcknowledged;
  }
);

export const restoreFromDraftAcknowledgedSelector = Reselect.createSelector(
  (state: IAppState) => state.community_event.restoreFromDraftAcknowledged,
  (restoreFromDraftAcknowledged: boolean) => {
    return restoreFromDraftAcknowledged;
  }
);

export const communityEventActiveStepSelector = Reselect.createSelector(
  (state: IAppState) => state.community_event.activeStep,
  (activeStep: CommunityEventFormStep) => {
    return activeStep;
  }
);

export const communityEventActiveTabSelector = Reselect.createSelector(
  (state: IAppState) => state.community_event.activeTab,
  (activeTab: CommunityEventTab) => {
    return activeTab;
  }
);
