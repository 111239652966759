import config from "../common/config";

export const isLocalEnvironment = () => {
  return config.NEXT_PUBLIC_ENV === "local";
};

export const isDevEnvironment = () => {
  return config.NEXT_PUBLIC_ENV === "development";
};

export const isStagingEnvironment = () => {
  return config.NEXT_PUBLIC_ENV === "staging";
};

export const isProdEnvironment = () => {
  return config.NEXT_PUBLIC_ENV === "production";
};

export const isStagingOrProd = () => {
  return isStagingEnvironment() || isProdEnvironment();
};

export const isLocalOrDevEnvironment = () => {
  return isLocalEnvironment() || isDevEnvironment();
};

export const posthogFeatureFlags = {
  facilityCrudV2: "enable-facility-crud-v2",
  campgrounds: "enable-campgrounds",
  memberships: "enable-memberships",
  determineEventTypeStep: "enable-determine-event-type-step",
  printSpecialEventPDF: "enable-print-special-event-pdf",
  enableHideExtraQuestions: "enable-hide-extra-questions",
  enableDepartmentApprovalsTab: "enable-department-approvals-tab",
  enableMembershipsROBO: "enable-memberships-robo",
  enableProgramsCheckoutDetailedErrorHandling:
    "enable-programs-checkout-detailed-error-handling",
  enableProgramsCheckoutV2Action: "enable-programs-checkout-v2-action",
  minimalProgramsManagerInterface: "minimal-programs-manager-interface",
  enableFacResROBO: "enable-fac-res-robo",
  programsRosterPage: "programs-roster-page",
  formDefinitionUI: "form-definition-ui",
  useLegacyProgramRegistrationsTable: "use-legacy-program-registrations-table",
  enableHouseholdMemberMembershipsROBO:
    "enable-household-member-memberships-robo",
  enableProgramsQRValidation: "enable-programs-qr-validation",
  enableROBOPriceModifiers: "enable-robo-price-modifiers",
  enableMembershipsCheckins: "enable-memberships-checkins",
};
