import { useAuth0 } from "@auth0/auth0-react";
import jwtDecode from "jwt-decode";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CompoundAction } from "redoodle";
import { validate } from "uuid";

import { LoginDocument, LoginQuery } from "../api/generated";
import ApolloService from "../services/ApolloService";
import {
  SetAuthLoading,
  SetIsManager,
  SetUserId,
  useSetAuthLoading,
  useSetAuthToken,
} from "../store/auth";
import { organizationIDSelector } from "../store/organization";

export function useLoginReduxHandler() {
  const { isAuthenticated, getAccessTokenSilently, user, isLoading } =
    useAuth0();
  const organizationId = useSelector(organizationIDSelector);
  const dispatch = useDispatch();
  const setAuthLoading = useSetAuthLoading();
  const setAuthToken = useSetAuthToken();
  const posthog = usePostHog();

  useEffect(() => {
    const login = async (
      name: string | undefined,
      currentRoles: string[],
      orgId: string,
      phone_number?: string
    ) => {
      const { data } = await ApolloService.client.query<LoginQuery>({
        query: LoginDocument,
        variables: {
          name,
          phone_number: phone_number || null,
          current_roles: currentRoles,
          organization_id: orgId,
        },
        fetchPolicy: "network-only",
      });
      return data;
    };

    const fetchTokenAndUpdateState = async () => {
      // Fetch the token from Auth0 and update store so ApolloService can use a Hasura compatible token
      const token = await getAccessTokenSilently({
        audience: "hasura",
      });
      if (!token) {
        setAuthLoading(false);
        return;
      }
      const decodedToken = jwtDecode<any>(token);
      const currentRoles =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"];
      setAuthToken(token);

      try {
        const { login: userData } = await login(
          user?.name,
          currentRoles,
          organizationId,
          user?.phone_number
        );

        const isManager = userData?.is_manager ?? false;

        posthog.identify(userData?.user_id, {
          is_manager: isManager,
          organization_id: organizationId,
          email: user?.email,
        });

        dispatch(
          CompoundAction([
            SetUserId(userData?.user_id),
            SetIsManager(isManager),
            SetAuthLoading(false),
          ])
        );
      } catch (err: any) {
        console.error(`An error occurred logging in: ${err.message}.`);
        setAuthLoading(false);
      }
    };
    // Need to check for isLoading from Auth0 to properly set auth loading to false
    if (isLoading) {
      console.info("Auth0 is loading for current user.");
      return;
    } else if (!isAuthenticated) {
      console.info(
        "Auth0 has completed loading for this user. Set auth loading to false."
      );
      setAuthLoading(false);
    } else {
      console.info("User is authenticated. Fetch token and update authState.");
      if (organizationId && validate(organizationId)) {
        fetchTokenAndUpdateState();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, organizationId, isLoading]);
}
