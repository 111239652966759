import { RosterReportQueryParams } from "../components/shared/Programs/Manager/roster_report_query_params";

/**
 * Joins path segments.  Preserves initial "/"
 *
 * @example
 *   joinPaths("a", "b", "c") === "a/b/c"
 *
 * @example
 *   joinPaths("a/", "/b/", "/c") === "a/b/c"
 *
 * @example
 *   joinPaths("a", null, undefined, "b") === "a/b"
 *
 * @example
 *   joinPaths("/", "a", "b", "c") === "/a/b/c"
 */
export function joinPaths(...paths: (string | number | null | undefined)[]) {
  return (
    paths
      .filter((path) => path !== null && path !== undefined)
      .join("/")
      // Remove double slashes
      .replace(/\/+/g, "/")
      // Remove trailing slash, taking care not to remove the first slash if it is the only one
      .replace(/\/$/, (match, offset) => (offset === 0 ? "/" : ""))
  );
}

export function routeWithOrganization(
  organization: string | undefined,
  route: string
) {
  return joinPaths("/", organization, route);
}

// TODO: Consolidate this new function with routeWithOrganization (above)
// routeWithOrganization is used in too many places so risk is high when trying to consolidate
export function routeWithOrganizationQueryParams(
  organization: string | undefined,
  route: string,
  queryParams?: { query: Record<string, any> }
) {
  // We can pass in the current query params with {query: router.query}
  // If we do that, we need to extract the parsed query params
  return {
    pathname: joinPaths("/", organization, route),
    ...(queryParams && { query: queryParams.query }),
  };
}

/**
 * Extracts the route parameters from a string literal for a route descriptor
 * into an object
 */
type ExtractRouteParams<Path> = Path extends `${infer Segment}/${infer Rest}`
  ? Segment extends `[${infer Param}]`
    ? { [k in Param]: string } & ExtractRouteParams<Rest>
    : ExtractRouteParams<Rest>
  : Path extends `[${infer Param}]`
  ? { [k in Param]: string }
  : { [k in string]: unknown };

/**
 * Given a route descriptor and an object of parameters for the route, returns
 * the route with the parameters filled in. Enforces type safety between the
 * route and its parameters.
 *
 * @example
 *   makeLink("/foo/[bar]/[baz]", { bar: "a", baz: "b" }) === "/foo/a/b"
 *
 * @example
 *   makeLink("/foo/[bar]", {}) // Type error: missing parameter "bar"
 */
export const makeLink = <T extends string>(
  route: T,
  params: ExtractRouteParams<T>
) =>
  route.replace(
    /\[(?<param>\w+)]/g,
    (_, paramName) => params[paramName] as any
  );

export function makeRosterReportLink<Params extends RosterReportQueryParams>({
  organization,
  route,
  params,
}: {
  organization: string;
  route: string;
  params: Params;
}) {
  return routeWithOrganizationQueryParams(organization, route, {
    query: {
      // We stringify our query params here instead of just passing them regularly
      // because when Next parses them it will parse string arrays that contain a single string
      // as a string instead of an array. So using JSON.stringify helps to reduce the amount
      // of type coercing we need to do on the report page.
      params: JSON.stringify(params),
    },
  });
}
