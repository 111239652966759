import update from "immutability-helper";
import { useDispatch, useSelector } from "react-redux";
import { defineAction, setWith, TypedReducer } from "redoodle";
import * as Reselect from "reselect";

import {
  AdminDashboardMembershipRegistrationsColumns,
  membershipCols,
  membershipGroupCols,
  membershipTiersCols,
  SortOrder,
  UserDashboardMembershipRegistrationsColumns,
} from "../../constants/constants";
import { IAppState } from "../app";

export interface IMembershipSortState {
  membershipSortOrder?: SortOrder;
  membershipSortBy?: typeof membershipCols[number];
  membershipGroupSortOrder?: SortOrder;
  membershipGroupSortBy?: typeof membershipGroupCols[number];
  membershipTiersSortOrder?: SortOrder;
  membershipTiersSortBy?: typeof membershipTiersCols[number];
  membershipRegistrationsSortOrder?: SortOrder;
  userMembershipRegistrationsSortBy?: UserDashboardMembershipRegistrationsColumns;
  adminMembershipRegistrationsSortBy?: AdminDashboardMembershipRegistrationsColumns;
}

// actions
export const ResetSortState = defineAction("APP/SORT/RESET_SORT_STATE")();
export const SetMembershipSortOrder = defineAction(
  "APP/SORT/SET_MEMBERSHIP_SORT_ORDER"
)<SortOrder | undefined>();
export const SetMembershipSortBy = defineAction(
  "APP/SORT/SET_MEMBERSHIP_SORT_BY"
)<typeof membershipCols[number] | undefined>();
export const SetMembershipGroupSortOrder = defineAction(
  "APP/SORT/SET_MEMBERSHIP_GROUP_SORT_ORDER"
)<SortOrder | undefined>();
export const SetMembershipGroupSortBy = defineAction(
  "APP/SORT/SET_MEMBERSHIP_GROUP_SORT_BY"
)<typeof membershipCols[number] | undefined>();
export const SetMembershipTiersSortOrder = defineAction(
  "APP/SORT/SET_MEMBERSHIP_TIERS_SORT_ORDER"
)<SortOrder | undefined>();
export const SetMembershipTiersSortBy = defineAction(
  "APP/SORT/SET_MEMBERSHIP_TIERS_SORT_BY"
)<typeof membershipCols[number] | undefined>();
export const SetMembershipRegistrationsSortOrder = defineAction(
  "APP/SORT/SET_MEMBERSHIP_REGISTRATIONS_SORT_ORDER"
)<SortOrder | undefined>();
export const SetUserDashboardMembershipRegistrationsSortBy = defineAction(
  "APP/SORT/SET_USER_DASHBOARD_MEMBERSHIP_REGISTRATIONS_SORT_BY"
)<UserDashboardMembershipRegistrationsColumns | undefined>();
export const SetAdminDashboardMembershipRegistrationsSortBy = defineAction(
  "APP/SORT/SET_ADMIN_DASHBOARD_MEMBERSHIP_REGISTRATIONS_SORT_BY"
)<AdminDashboardMembershipRegistrationsColumns | undefined>();

// reducer
export const membershipSortReducer: any =
  TypedReducer.builder<IMembershipSortState>()
    .withHandler(ResetSortState.TYPE, (state) =>
      update(state, { $set: initialMembershipSortState })
    )
    .withHandler(SetMembershipSortOrder.TYPE, (state, membershipSortOrder) =>
      setWith(state, { membershipSortOrder: membershipSortOrder })
    )
    .withHandler(SetMembershipSortBy.TYPE, (state, membershipSortBy) =>
      setWith(state, { membershipSortBy })
    )
    .withHandler(
      SetMembershipGroupSortOrder.TYPE,
      (state, membershipGroupSortOrder) =>
        setWith(state, { membershipGroupSortOrder })
    )
    .withHandler(
      SetMembershipGroupSortBy.TYPE,
      (state, membershipGroupSortBy) =>
        setWith(state, { membershipGroupSortBy })
    )
    .withHandler(
      SetMembershipTiersSortOrder.TYPE,
      (state, membershipTiersSortOrder) =>
        setWith(state, { membershipTiersSortOrder })
    )
    .withHandler(
      SetMembershipTiersSortBy.TYPE,
      (state, membershipTiersSortBy) =>
        setWith(state, { membershipTiersSortBy })
    )
    .withHandler(
      SetMembershipRegistrationsSortOrder.TYPE,
      (state, membershipRegistrationsSortOrder) =>
        setWith(state, { membershipRegistrationsSortOrder })
    )
    .withHandler(
      SetAdminDashboardMembershipRegistrationsSortBy.TYPE,
      (state, adminMembershipRegistrationsSortBy) =>
        setWith(state, { adminMembershipRegistrationsSortBy })
    )
    .withHandler(
      SetUserDashboardMembershipRegistrationsSortBy.TYPE,
      (state, userMembershipRegistrationsSortBy) =>
        setWith(state, { userMembershipRegistrationsSortBy })
    )
    .withDefaultHandler((state) => (state ? state : initialMembershipSortState))
    .build();

// init
export const initialMembershipSortState: IMembershipSortState = {
  membershipSortOrder: "desc",
  membershipSortBy: "Membership name",
  membershipGroupSortOrder: "desc",
  membershipGroupSortBy: "Membership group",
  membershipTiersSortOrder: "desc",
  membershipTiersSortBy: "Membership tier",
  membershipRegistrationsSortOrder: "desc",
  adminMembershipRegistrationsSortBy: "Purchase Date",
  userMembershipRegistrationsSortBy: "Purchase Date",
};

// selectors
export const membershipSortOrderSelector = Reselect.createSelector(
  (state: IAppState) => state.membershipSort.membershipSortOrder,
  (sortOrder: SortOrder | undefined) => {
    return sortOrder;
  }
);

export const membershipSortBySelector = Reselect.createSelector(
  (state: IAppState) => state.membershipSort.membershipSortBy,
  (sortBy: typeof membershipCols[number] | undefined) => {
    return sortBy;
  }
);

export const membershipGroupSortOrderSelector = Reselect.createSelector(
  (state: IAppState) => state.membershipSort.membershipGroupSortOrder,
  (sortOrder: SortOrder | undefined) => {
    return sortOrder;
  }
);

export const membershipGroupSortBySelector = Reselect.createSelector(
  (state: IAppState) => state.membershipSort.membershipGroupSortBy,
  (sortBy: typeof membershipGroupCols[number] | undefined) => {
    return sortBy;
  }
);

export const membershipTiersSortOrderSelector = Reselect.createSelector(
  (state: IAppState) => state.membershipSort.membershipTiersSortOrder,
  (sortOrder: SortOrder | undefined) => {
    return sortOrder;
  }
);

export const membershipTiersSortBySelector = Reselect.createSelector(
  (state: IAppState) => state.membershipSort.membershipTiersSortBy,
  (sortBy: typeof membershipTiersCols[number] | undefined) => {
    return sortBy;
  }
);

export const membershipRegistrationsSortOrderSelector = Reselect.createSelector(
  (state: IAppState) => state.membershipSort.membershipRegistrationsSortOrder,
  (sortOrder: SortOrder | undefined) => {
    return sortOrder;
  }
);

export const adminMembershipRegistrationsSortBySelector =
  Reselect.createSelector(
    (state: IAppState) =>
      state.membershipSort.adminMembershipRegistrationsSortBy,
    (sortBy: AdminDashboardMembershipRegistrationsColumns | undefined) => {
      return sortBy;
    }
  );

export const userMembershipRegistrationsSortBySelector =
  Reselect.createSelector(
    (state: IAppState) =>
      state.membershipSort.userMembershipRegistrationsSortBy,
    (sortBy: UserDashboardMembershipRegistrationsColumns | undefined) => {
      return sortBy;
    }
  );

// selector hooks
export const useMembershipRegistrationsSortOrderSelector = () =>
  useSelector(membershipRegistrationsSortOrderSelector);
export const useAdminDashboardMembershipRegistrationsSortBySelector = () =>
  useSelector(adminMembershipRegistrationsSortBySelector);
export const useUserDashboardMembershipRegistrationsSortBySelector = () =>
  useSelector(userMembershipRegistrationsSortBySelector);

export const useSetMembershipRegistrationsSortOrder = () => {
  const dispatch = useDispatch();
  return (order: SortOrder | undefined) =>
    dispatch(SetMembershipRegistrationsSortOrder(order));
};
export const useSetAdminDashboardMembershipRegistrationsSortBy = () => {
  const dispatch = useDispatch();
  return (column: AdminDashboardMembershipRegistrationsColumns | undefined) =>
    dispatch(SetAdminDashboardMembershipRegistrationsSortBy(column));
};
export const useSetUserDashboardMembershipRegistrationsSortBy = () => {
  const dispatch = useDispatch();
  return (column: UserDashboardMembershipRegistrationsColumns | undefined) =>
    dispatch(SetUserDashboardMembershipRegistrationsSortBy(column));
};
