import config from "../common/config";
import { sentryCaptureException } from "./sentry_utils";

const NON_PRODUCTION_ENVS = ["local", "development", "staging"];

const shouldConsoleLog = () =>
  NON_PRODUCTION_ENVS.includes(config.NEXT_PUBLIC_ENV!);

export default {
  debug(message?: any, ...optionalParams: any[]) {
    if (shouldConsoleLog()) {
      console.log(message, ...optionalParams);
    }
  },
  error(e: unknown, ...optionalParams: any[]) {
    if (shouldConsoleLog()) {
      console.error(e, ...optionalParams);
    }
    sentryCaptureException(e, ...optionalParams);
  },
};
