import { produce } from "immer";
import update from "immutability-helper";
import { defineAction, setWith, TypedReducer } from "redoodle";
import * as Reselect from "reselect";

import { ProgramsCheckoutFailureReasons } from "../api/generated";
import {
  CartItemToValidationError,
  DocumentSubmission,
  FormSubmissionsByHouseholdMember,
  isCampCartItem,
  isClassCartItem,
  isTicketedEventCartItem,
  MembershipFormSubmissions,
  ProgramCartItemData,
  ProgramsCheckoutErrors,
  RegistrableUnitCartData,
  TicketedEventRegistrableUnitCartData,
} from "../constants/types";
import { hasWaitlistRegistrations } from "../utils/program_utils";
import { IAppState } from "./app";

// model
export interface ICartState {
  drawerIsOpen: boolean;
  cartItems: ProgramCartItemData[];
  formSubmissions: FormSubmissionsByHouseholdMember;
  membershipFormSubmissions: MembershipFormSubmissions;
  showErrorForEmptyForms: boolean;
  checkoutErrors: ProgramsCheckoutErrors;
}

export interface SubmitRequiredDocumentPayload extends DocumentSubmission {
  householdMemberId: string;
  formId: string;
  formDefinitionId: string;
  schemaFormDefinitionId?: string;
}

export interface RemoveRequiredDocumentPayload {
  householdMemberId: string;
  formId: string;
}

// actions
export const SetCartItems = defineAction("APP/CART/SET_CART_ITEMS")<
  ProgramCartItemData[]
>();
export const AddItemToCart = defineAction(
  "APP/CART/ADD_ITEM_TO_CART"
)<ProgramCartItemData>();
export const UpdateSelectedMemberIds = defineAction(
  "APP/CART/UPDATE_SELECTED_MEMBER_IDS"
)<{ cartItemId: string; selectedHouseholdMemberIds: string[] }>();
export const UpdateSelectedRegistrableUnits = defineAction(
  "APP/CART/UPDATE_SELECTED_REGISTRABLE_UNITS"
)<{
  cartItemId: string;
  registrableUnits: RegistrableUnitCartData[];
}>();
export const UpdateTicketedEventsSelectedMemberId = defineAction(
  "APP/CART/UPDATE_TICKETED_EVENTS_SELECTED_MEMBER_ID"
)<{
  selectedMemberId: string;
}>();
export const UpdateTicketedEventSelectedRegistrableUnits = defineAction(
  "APP/CART/UPDATE_TICKETED_EVENT_SELECTED_REGISTRABLE_UNITS"
)<{
  cartItemId: string;
  registrableUnits: TicketedEventRegistrableUnitCartData[];
}>();
export const RemoveItemFromCart = defineAction(
  "APP/CART/REMOVE_CART_ITEM"
)<string>();
export const RemoveHouseholdMemberFromCartItem = defineAction(
  "APP/CART/REMOVE_HOUSEHOLD_MEMBER_FROM_CART_ITEM"
)<{
  cartItemId: string;
  householdMemberId: string;
  removeFromEntireGroupId?: string | null;
}>();
export const RemoveRegistrableUnitFromCartItem = defineAction(
  "APP/CART/REMOVE_REGISTEABLE_UNIT_FROM_CART_ITEM"
)<{ cartItemId: string; registrableUnitId: string }>();
export const SubmitRequiredDocument = defineAction(
  "APP/CART/SUBMIT_REQUIRED_DOCUMENT"
)<SubmitRequiredDocumentPayload>();

export const RemoveRequiredDocument = defineAction(
  "APP/CART/REMOVE_REQUIRED_DOCUMENTS"
)<RemoveRequiredDocumentPayload>();

export const CloseWaitlistCard = defineAction(
  "APP/CART/CLOSE_WAITLIST_CARD"
)<string>();
export const SetCartDrawerOpenState = defineAction(
  "APP/CART/SET_CART_DRAWER_OPEN_STATE"
)<boolean>();
export const UpdateWaitlistStatus = defineAction(
  "APP/CART/UPDATE_WAITLIST_STATUS"
)<{
  cartItemId: string;
  registeringForWaitlist: boolean;
  showWaitlistCard?: boolean;
  registerableUnitId?: string;
  programGroupId?: string;
}>();
export const UpdateCartItemValidationErrors = defineAction(
  "APP/CART/UPDATE_CART_ITEM_VALIDATION_ERRORs"
)<CartItemToValidationError>();
export const UpdateShowErrorEmptyForForms = defineAction(
  "APP/CART/UPDATE_SHOW_ERROR_FOR_EMPTY_FORMS"
)<boolean>();
export const SetProgramsCheckoutErrors = defineAction(
  "APP/CART/SET_PROGRAMS_CHECKOUT_ERRORS"
)<ProgramsCheckoutErrors>();
export const RemoveCheckoutError = defineAction(
  "APP/CART/REMOVE_CHECKOUT_ERROR"
)<{ cartItemId: string; registrableUnitId: string }>();
export const ResetCart = defineAction("APP/CART/RESET_CART")();

// reducer
export const cartReducer: any = TypedReducer.builder<ICartState>()
  .withHandler(SetCartItems.TYPE, (state, cartItems) =>
    setWith(state, { cartItems })
  )
  .withHandler(AddItemToCart.TYPE, (state, cartItem) =>
    state.cartItems.some(
      (existingCartItem) => existingCartItem.id === cartItem.id
    )
      ? state
      : update(state, { cartItems: { $push: [cartItem] } })
  )
  .withHandler(
    UpdateSelectedMemberIds.TYPE,
    (state, { cartItemId, selectedHouseholdMemberIds }) => {
      const cartItem = state.cartItems.find(({ id }) => id === cartItemId);

      if (!cartItem) {
        return state;
      }

      if (!isClassCartItem(cartItem)) {
        return state;
      }

      // build a clone of the current state with the current cart item filtered out
      const newState = {
        ...state,
        cartItems: [...state.cartItems.filter(({ id }) => id !== cartItemId)],
      };

      // if we've set household member ids, push the cart item with the new ids into
      // the new state object. else leave it out, we don't want cart items without
      // selected household member ids
      if (selectedHouseholdMemberIds?.length) {
        newState.cartItems.push({
          ...cartItem,
          selectedHouseholdMemberIds,
        });
      }

      return setWith(state, newState);
    }
  )
  .withHandler(
    UpdateSelectedRegistrableUnits.TYPE,
    (state, { cartItemId, registrableUnits }) => {
      const cartItem = state.cartItems.find(({ id }) => id === cartItemId);

      if (!cartItem) {
        return state;
      }

      // Class cart items do not have a "registrableUnits" property
      // and are therefore not supported by this action.
      // For TicketedEvent cart items, use UpdateTicketedEventSelectedRegistrableUnits action instead.
      if (isClassCartItem(cartItem) || isTicketedEventCartItem(cartItem)) {
        return state;
      }
      let showWaitlistCard;

      const alreadyHadWaitlistRegistrations =
        hasWaitlistRegistrations(cartItem);

      if (alreadyHadWaitlistRegistrations) {
        // If we already had waitlist registrations in the cart do not change this value.
        // The user may have acknowleged and closed the card already.
        showWaitlistCard = cartItem.showWaitlistCard;
      } else {
        // If we did not have waitlist registrations and there are new waitlist registrations
        // force the waitlist card to show in the cart.
        showWaitlistCard = hasWaitlistRegistrations(cartItem);
      }

      return setWith(state, {
        ...state,
        cartItems: [
          ...state.cartItems.filter(({ id }) => id !== cartItem.id),
          {
            ...cartItem,
            registrableUnits,
            showWaitlistCard,
          },
        ],
      });
    }
  )
  .withHandler(
    UpdateTicketedEventsSelectedMemberId.TYPE,
    (state, { selectedMemberId }) => {
      return setWith(state, {
        ...state,
        cartItems: state.cartItems.map((cartItem) => {
          if (!isTicketedEventCartItem(cartItem)) {
            return cartItem;
          }
          return {
            ...cartItem,
            selectedHouseholdMemberId: selectedMemberId,
          };
        }),
      });
    }
  )
  .withHandler(
    UpdateTicketedEventSelectedRegistrableUnits.TYPE,
    (state, { cartItemId, registrableUnits }) => {
      const cartItem = state.cartItems.find(({ id }) => id === cartItemId);

      if (!cartItem || !isTicketedEventCartItem(cartItem)) {
        return state;
      }

      return setWith(state, {
        ...state,
        cartItems: [
          ...state.cartItems.filter(({ id }) => id !== cartItem.id),
          {
            ...cartItem,
            registrableUnits,
          },
        ],
      });
    }
  )
  .withHandler(RemoveItemFromCart.TYPE, (state, id) => {
    const existingCartItemIndex = state.cartItems.findIndex(
      (cartItem) => cartItem.id === id
    );

    if (existingCartItemIndex === -1) {
      return state;
    }

    return update(state, {
      cartItems: { $splice: [[existingCartItemIndex, 1]] },
      formSubmissions: {
        $set: state.cartItems.length === 1 ? {} : state.formSubmissions,
      },
    });
  })
  .withHandler(
    RemoveHouseholdMemberFromCartItem.TYPE,
    (state, { cartItemId, householdMemberId, removeFromEntireGroupId }) =>
      produce(state, (draft) => {
        const itemsToProcess = draft.cartItems
          .filter(isClassCartItem)
          .filter(
            (item) =>
              item.id === cartItemId ||
              (item.programGroupId === removeFromEntireGroupId &&
                item.selectedHouseholdMemberIds.includes(householdMemberId))
          );

        if (itemsToProcess.length === 0) {
          return draft;
        }

        itemsToProcess.forEach((item) => {
          const memberIndex = item.selectedHouseholdMemberIds.findIndex(
            (id) => id === householdMemberId
          );

          if (memberIndex === -1) {
            return;
          }

          item.selectedHouseholdMemberIds.splice(memberIndex, 1);

          const memberHasOtherCartItems = draft.cartItems.some((i) => {
            if (isClassCartItem(i)) {
              return i.selectedHouseholdMemberIds.includes(householdMemberId);
            } else {
              return i.selectedHouseholdMemberId === householdMemberId;
            }
          });

          if (!memberHasOtherCartItems && draft.formSubmissions) {
            delete draft.formSubmissions[householdMemberId];
          }

          // If this is the last household member in the cart item, remove the
          // entire cart item
          if (item.selectedHouseholdMemberIds.length === 0) {
            draft.cartItems = draft.cartItems.filter(
              ({ id }) => id !== item.id
            );
          }
        });

        return draft;
      })
  )
  .withHandler(
    RemoveRegistrableUnitFromCartItem.TYPE,
    (state, { cartItemId, registrableUnitId }) => {
      const cartItemIndex = state.cartItems.findIndex(
        ({ id }) => id === cartItemId
      );

      if (cartItemIndex === -1) {
        return state;
      }

      const item = state.cartItems[cartItemIndex];

      // Class cart items will only have one registrable unit
      // Remove the cart item instead
      if (isClassCartItem(item)) {
        return state;
      }

      const registrableUnitIndex = item.registrableUnits.findIndex(({ id }) => {
        return id === registrableUnitId;
      });

      if (registrableUnitIndex === -1) {
        return state;
      }

      return update(state, {
        cartItems: {
          [cartItemIndex]: {
            registrableUnits: {
              $splice: [[registrableUnitIndex, 1]],
            },
          },
        },
      });
    }
  )
  .withHandler(ResetCart.TYPE, (state) => setWith(state, initialCartState))
  .withHandler(
    SubmitRequiredDocument.TYPE,
    (
      state,
      {
        householdMemberId,
        formId,
        file,
        existingDocumentId,
        disclaimerDataUrl,
        schemaFormData,
        formDefinitionId,
        schemaFormDefinitionId,
      }
    ) => {
      const memberSubmissions =
        state.formSubmissions?.[householdMemberId] || {};

      return setWith(state, {
        ...state,
        formSubmissions: {
          ...state.formSubmissions,
          [householdMemberId]: {
            ...memberSubmissions,
            [formDefinitionId]: {
              formId,
              formDefinitionId,
              existingDocumentId,
              newDocument: file,
              disclaimerDataUrl,
              schemaFormData,
              schemaFormDefinitionId,
            },
          },
        },
      });
    }
  )
  .withHandler(
    RemoveRequiredDocument.TYPE,
    (state, { householdMemberId, formId }) => {
      const allSubmissions = state.formSubmissions;
      const memberFormSubmissions = allSubmissions[householdMemberId];

      if (memberFormSubmissions) {
        delete memberFormSubmissions[formId];
      }

      return setWith(state, {
        ...state,

        formSubmissions: {
          ...allSubmissions,
          [householdMemberId]: memberFormSubmissions,
        },
      });
    }
  )
  .withHandler(CloseWaitlistCard.TYPE, (state, cartItemId) => {
    const cartItem = state.cartItems?.find(({ id }) => id === cartItemId);

    return setWith(state, {
      ...state,
      cartItems: [
        ...state.cartItems.filter(({ id }) => id !== cartItemId), // Make sure we don't accidentally insert the item twice
        {
          ...cartItem,
          showWaitlistCard: false,
        } as ProgramCartItemData,
      ],
    });
  })
  .withHandler(
    UpdateWaitlistStatus.TYPE,
    (
      state,
      {
        cartItemId,
        registeringForWaitlist,
        showWaitlistCard,
        registerableUnitId,
        programGroupId,
      }
    ) => {
      const cartItem = state.cartItems.find(({ id }) => id === cartItemId);

      if (!cartItem) {
        return state;
      }

      if (isClassCartItem(cartItem)) {
        return setWith(state, {
          ...state,
          cartItems: [
            ...state.cartItems.filter(({ id }) => id !== cartItemId),

            {
              ...cartItem,
              registeringForWaitlist,
              showWaitlistCard: showWaitlistCard ?? registeringForWaitlist,
            },
          ],
        });
      } else if (isCampCartItem(cartItem)) {
        if (!registerableUnitId || !programGroupId) {
          return state;
        }

        return setWith(state, {
          ...state,
          cartItems: [
            ...state.cartItems.filter(({ id }) => id !== cartItemId),
            {
              ...cartItem,
              registrableUnits: [
                ...cartItem.registrableUnits.filter(
                  ({ id }) => id !== registerableUnitId
                ),
                {
                  id: registerableUnitId,
                  programGroupId,
                  registeringForWaitlist,
                },
              ],
              showWaitlistCard: showWaitlistCard ?? registeringForWaitlist,
            },
          ],
        });
      }

      return state;
    }
  )
  .withHandler(
    UpdateCartItemValidationErrors.TYPE,
    (state, cartValidationErrors) => {
      const cartItems = [
        ...state.cartItems.map((item) => {
          // Camps and Ticketed Events currently do not support the real time validation feature
          if (isCampCartItem(item) || isTicketedEventCartItem(item)) {
            return item;
          }

          const errorValExists = Object.prototype.hasOwnProperty.call(
            cartValidationErrors,
            item.id
          );

          return {
            ...item,
            validationError: errorValExists
              ? cartValidationErrors[item.id]
              : item.validationError,
          };
        }),
      ];

      return setWith(state, {
        ...state,
        cartItems,
      });
    }
  )
  .withHandler(UpdateShowErrorEmptyForForms.TYPE, (state, value) =>
    setWith(state, { ...state, showErrorForEmptyForms: value })
  )
  .withHandler(SetCartDrawerOpenState.TYPE, (state, value) =>
    setWith(state, { ...state, drawerIsOpen: value })
  )
  .withHandler(SetProgramsCheckoutErrors.TYPE, (state, value) =>
    setWith(state, { ...state, checkoutErrors: value })
  )
  .withHandler(
    RemoveCheckoutError.TYPE,
    (state, { cartItemId, registrableUnitId }) => {
      if (!state.checkoutErrors) {
        return state;
      }

      const index = state.checkoutErrors.findIndex((err) => {
        return (
          err.registrable_unit_id === registrableUnitId &&
          cartItemId === err.cart_item_id
        );
      });

      if (index === -1) {
        return state;
      }

      return update(state, {
        checkoutErrors: {
          $splice: [[index, 1]],
        },
      });
    }
  )
  .withDefaultHandler((state) => (state ? state : initialCartState))
  .build();

// init
export const initialCartState: ICartState = {
  drawerIsOpen: false,
  cartItems: [],
  formSubmissions: {},
  membershipFormSubmissions: {},
  showErrorForEmptyForms: false,
  checkoutErrors: [],
};

export const cartItemsSelector = Reselect.createSelector(
  (state: IAppState) => state.cart.cartItems,
  (cartItems: ProgramCartItemData[]) => cartItems
);

export const classCartItemsSelector = Reselect.createSelector(
  (state: IAppState) => state.cart.cartItems,
  (cartItems: ProgramCartItemData[]) => cartItems.filter(isClassCartItem)
);

export const campCartItemSelector = Reselect.createSelector(
  (state: IAppState) => state.cart.cartItems,
  (cartItems: ProgramCartItemData[]) => cartItems.filter(isCampCartItem)
);

export const ticketedEventCartItemSelector = Reselect.createSelector(
  (state: IAppState) => state.cart.cartItems,
  (cartItems: ProgramCartItemData[]) =>
    cartItems.filter(isTicketedEventCartItem)
);

export const drawerStateSelector = Reselect.createSelector(
  (state: IAppState) => state.cart.drawerIsOpen,
  (value) => value
);

export const formSubmissionsSelector = Reselect.createSelector(
  (state: IAppState) => state.cart.formSubmissions,
  (formSubmissions) => {
    return formSubmissions;
  }
);

export const formSubmissionsForFormSelector = Reselect.createSelector(
  [
    (state: IAppState) => state.cart.formSubmissions,
    (_state: IAppState, householdMemberId: string) => householdMemberId,
    (_state: IAppState, _householdMemberId: string, formId: string) => formId,
  ],
  (formSubmissions, householdMemberId, formId) =>
    formSubmissions?.[householdMemberId]?.[formId] ?? null
);

export const showErrorForEmptyFormsSelector = Reselect.createSelector(
  (state: IAppState) => state.cart.showErrorForEmptyForms,
  (showErrorForEmptyForms) => {
    return showErrorForEmptyForms;
  }
);

function findCapacityErrorsForCartItem(
  checkoutErrors: ProgramsCheckoutErrors,
  cartItemId: string
) {
  return checkoutErrors?.filter((e) => {
    return (
      e.cart_item_id === cartItemId &&
      e.reason === ProgramsCheckoutFailureReasons.ExceedsCapacity
    );
  });
}

export const capacityErrorSelector = Reselect.createSelector(
  [
    (state: IAppState) => state.cart.checkoutErrors,
    (_state: IAppState, cartItemId: string) => cartItemId,
  ],
  (checkoutErrors, cartItemId) =>
    findCapacityErrorsForCartItem(checkoutErrors, cartItemId)
);
