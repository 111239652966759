import { captureMessage } from "@sentry/react";
import { differenceInHours, differenceInMinutes } from "date-fns";
import { DateTime } from "luxon";
import moment, { Moment } from "moment-timezone";
import pluralize from "pluralize";
import { match } from "ts-pattern";

import {
  Days_Of_Week_Enum,
  FacilityScheduleAndRelatedPropertiesFragment,
  TemporalEventAndRelatedPropertiesFragment,
  TemporalEventPropertiesFragment,
  Time_Units_Enum,
  Time_Zones_Enum,
  UserBookingInfo,
  UserBookingPropertiesFragment,
  UserBookingSchedulePropertiesFragment,
  VenuePortionPrice,
  VenueScheduleAndRelatedPropertiesFragment,
} from "../api/generated";
import {
  Day_Of_Week_Index,
  DEFAULT_MAX_INTERVAL_MINUTES,
  DEFAULT_MIN_INTERVAL_MINUTES,
  DEFAULT_TIME_ZONE,
  NUM_DAYS_IN_SIX_WEEK_PERIOD,
} from "../constants/constants";
import { Scheduler_Views_Enum, SchedulerView } from "../constants/types";
import { compact } from "./arrays";
import logger from "./logger";

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function setDateToNoon(date: Date): Date {
  const newDate = new Date(date);
  newDate.setMilliseconds(0);
  newDate.setSeconds(0);
  newDate.setMinutes(0);
  newDate.setHours(12);
  return newDate;
}

// Returns a Date with the time changed to midnight, based on the user's LOCAL time
export function setDateToMidNight(date: Date): Date {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

export function setDateToOneMinuteBeforeMidnight(date: Date): Date {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 0);
  return newDate;
}

export const convertToDateThenSetToMidnight = (
  dateConvertable: Date | string | number
): Date => {
  const dateObject = new Date(dateConvertable);
  return setDateToMidNight(dateObject);
};

export function setDateToMorning(date: Date): Date {
  const newDate = new Date(date);
  newDate.setMilliseconds(0);
  newDate.setSeconds(0);
  newDate.setMinutes(0);
  newDate.setHours(8);
  return newDate;
}

export function setDateToTopOfTheHour(date: Date): Date {
  const newDate = new Date(date);
  newDate.setMilliseconds(0);
  newDate.setSeconds(0);
  newDate.setMinutes(0);
  newDate.setHours(date.getHours());
  return newDate;
}
export function secondsToHumanReadable(seconds: number): string {
  return moment.duration(seconds * 1000).humanize();
}

export function dateRangeToHumanReadable(
  start_date: Date,
  end_date: Date
): string {
  const start_date_string = start_date.toLocaleDateString();
  const end_date_string = end_date.toLocaleDateString();
  return `${start_date_string} - ${end_date_string}`;
}

export function dateToLocalTimeString(date: Date): string {
  const local_date_string = date.toLocaleString("en", {
    timeZoneName: "short",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  });
  const time_only_string = local_date_string.split(", ")[1];
  return time_only_string;
}

export function dateToTimeOnlyString(date: Date): string {
  // HH:MM:SS
  return date.toTimeString().split(" ")[0];
}

export function dayOfTheWeekToDaysOfTheWeekEnum(
  day_of_week: string
): Days_Of_Week_Enum {
  switch (day_of_week) {
    case "Mo":
      return Days_Of_Week_Enum.Monday;
    case "MONDAY":
      return Days_Of_Week_Enum.Monday;
    case "Tu":
      return Days_Of_Week_Enum.Tuesday;
    case "TUESDAY":
      return Days_Of_Week_Enum.Tuesday;
    case "We":
      return Days_Of_Week_Enum.Wednesday;
    case "WEDNESDAY":
      return Days_Of_Week_Enum.Wednesday;
    case "Th":
      return Days_Of_Week_Enum.Thursday;
    case "THURSDAY":
      return Days_Of_Week_Enum.Thursday;
    case "Fr":
      return Days_Of_Week_Enum.Friday;
    case "FRIDAY":
      return Days_Of_Week_Enum.Friday;
    case "Sa":
      return Days_Of_Week_Enum.Saturday;
    case "SATURDAY":
      return Days_Of_Week_Enum.Saturday;
    case "Su":
      return Days_Of_Week_Enum.Sunday;
    case "SUNDAY":
      return Days_Of_Week_Enum.Sunday;
    default:
      throw Error(
        `Error in dayOfTheWeekToDaysOfTheWeekEnum: ${day_of_week} is not currently supported...`
      );
  }
}

export const daysOfWeekComparator = (
  a: Days_Of_Week_Enum,
  b: Days_Of_Week_Enum
) => {
  // Assuming weeks start on Sunday
  const positions = [
    Days_Of_Week_Enum.Sunday,
    Days_Of_Week_Enum.Monday,
    Days_Of_Week_Enum.Tuesday,
    Days_Of_Week_Enum.Wednesday,
    Days_Of_Week_Enum.Thursday,
    Days_Of_Week_Enum.Friday,
    Days_Of_Week_Enum.Saturday,
  ];

  const posA = positions.indexOf(a);
  const posB = positions.indexOf(b);

  return posA - posB;
};

export function getApplicationTimeZone() {
  return new Date()
    .toLocaleString("en", { timeZoneName: "short" })
    .split(" ")
    .pop();
}

// Should come in as an ISO date string e.g. "2024-08-02"
export function selectorDateToISOStringDayBounds(
  selector_date_string: string
): [string, string] {
  const startDate = moment.utc(selector_date_string);
  const endDate = moment(startDate).add(24, "hours");

  const iso_day_start_datetime = startDate.toISOString();
  const iso_day_end_datetime = endDate.toISOString();

  return [iso_day_start_datetime, iso_day_end_datetime];
}

// Returns an ISO date string (YYYY-MM-DD) from a JS Date object.
// The date portion only is extracted, ignoring the time and timezone.
export function isoDateStringFromJSDate(jsDate: Date): string {
  const dateTime = DateTime.fromJSDate(jsDate);

  if (!dateTime.isValid) {
    logger.error("Error in isoDateStringFromJSDate: Invalid date", {
      jsDate,
    });
  }

  return dateTime.toISODate()!;
}

export function todayISODateString(): string {
  return DateTime.now().toISODate();
}

// Returns a JS date set to the provided date at midnight in user's local time.
// isoStringDate = "YYYY-MM-DD", representing a date independent of time or timezone
// e.g. the date the user selects on a date picker
export function isoDateStringToJSDate(isoDateString: string): Date {
  const dateTime = DateTime.fromISO(isoDateString, { zone: "local" });
  return dateTime.toJSDate();
}

export function selectedTimeIntervalToDayBounds(
  selectedTimeInterval: [Date, Date]
): [string, string] {
  const iso_day_start_datetime = selectedTimeInterval[0].toISOString();
  const iso_day_end_datetime = selectedTimeInterval[1].toISOString();
  return [iso_day_start_datetime, iso_day_end_datetime];
}

function getLocalTimeWithTimeZone(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    timeZoneName: "short", // 'short' will include the time zone abbreviation
    hour12: false, // Use 24-hour format (optional)
  };

  return date.toLocaleTimeString(undefined, options).split(" ")[0];
}

export function isDaylightSavings(date: Date): boolean {
  if (isNaN(date.getTime())) {
    throw new Error(
      `Error in isDaylightSavings: Invalid date provided to isDaylightSavings`
    );
  }
  const luxonDateTime = DateTime.fromJSDate(date);
  return luxonDateTime.isInDST;
}

export function combineSingleEndDateAndRecurrenceEndDateToISO(
  start_datetime: Date,
  single_event_end_datetime: Date,
  recurrence_end_datetime: string
): string {
  // get date only first
  const recurrence_end_date_only = recurrence_end_datetime.split("T")[0];
  const local_end_time = getLocalTimeWithTimeZone(single_event_end_datetime);
  let end_datetime = new Date(`${recurrence_end_date_only} ${local_end_time}`);
  // adjust for daylight savings
  // [1] if start date is in daylight savings but end date is not in daylight savings...
  if (isDaylightSavings(start_datetime) && !isDaylightSavings(end_datetime)) {
    // Then we need to subtract an hour to get it to match the "start" iso time, due to a storage idiosyncracy.
    // We store the time in this way, because the end time is stored in ISO time in the backend, and the assumption
    // is that the hours-only pulled from the ISO end time corresponds to the end time of the first event, not the
    // last event.
    end_datetime = moment(end_datetime).add(-1, "hours").toDate();
  }
  // [2] if the start date is not in daylight savings but the end date is in daylight savings...
  if (!isDaylightSavings(start_datetime) && isDaylightSavings(end_datetime)) {
    // Then we need to add an hour to get it to match the "start" iso time, due to a storage idiosyncracy.
    // We store the time in this way, because the end time is stored in ISO time in the backend, and the assumption
    // is that the hours-only pulled from the ISO end time corresponds to the end time of the first event, not the
    // last event.
    end_datetime = moment(end_datetime).add(1, "hours").toDate();
  }
  const iso_booking_end_datetime = end_datetime.toISOString();
  return iso_booking_end_datetime;
}

/**
 * Convert ISO date and time strings into a local Date object.
 * @param iso_date_string ISO Date string in format YYYY-MM-DD
 * @param iso_time_string ISO Time string in format HH:MM:SS+00
 * @returns Localized Date object
 */
export const combineISOStringsToDate = (
  iso_date_string: string,
  iso_time_string: string
): Date => new Date(`${iso_date_string} ${iso_time_string}`);

/**
 * Convert ISO date and time strings (such as from searchBookings query) into a combined ISO string in UTC time.
 * @param iso_date_string ISO Date string in format YYYY-MM-DD
 * @param iso_time_string ISO Time string in format HH:MM:SS.sss
 * @returns ISO Datetime string in format YYYY-MM-DDTHH:MM:SS.sssZ
 */
export const combineISOStringsToUTCString = (
  iso_date_string: string,
  iso_time_string: string
): string => {
  const processedTimeString = iso_time_string.split("+")[0];
  const localizedDate = new Date(`${iso_date_string}T${processedTimeString}Z`);
  if (isNaN(localizedDate.valueOf())) {
    // Invalid Date from constructor call above
    throw new Error(
      `Date strings ${iso_date_string} and/or ${iso_time_string} are causing "Invalid Date" generation.`
    );
  }
  return localizedDate.toISOString(); // "Z" suffix indicates UTC timezone
};

export function convertTimeAndUnitsToMinutes(
  time: number,
  units: Time_Units_Enum
): number {
  switch (units) {
    case Time_Units_Enum.Minute:
      return time;
    case Time_Units_Enum.Hour:
      return time * 60;
    case Time_Units_Enum.Day:
      return time * 60 * 24;
    default:
      throw Error(
        `Error in convertTimeAndUnitsToMinutes: the unit ${units} is not currently supported.`
      );
  }
}

export function getUserBookingInfoMinBookingMinutes(
  user_booking_info: UserBookingInfo | undefined | null,
  price?: VenuePortionPrice | undefined | null
): number {
  if (!user_booking_info) {
    return DEFAULT_MIN_INTERVAL_MINUTES;
  }
  if (
    !user_booking_info.min_booking_time ||
    !user_booking_info.min_booking_time_units
  ) {
    throw new Error(
      "Error in getUserBookingInfoMinBookingMinutes: min_booking_time or min_booking_time_units is undefined."
    );
  }

  let minBookingTime = user_booking_info.min_booking_time;
  let minBookingTimeUnits = user_booking_info.min_booking_time_units;
  if (user_booking_info.has_fixed_pricing && price) {
    if (price.fixed_booking_time && price.fixed_booking_time_units) {
      minBookingTime = price.fixed_booking_time;
      minBookingTimeUnits = price.fixed_booking_time_units;
    }
  }

  return convertTimeAndUnitsToMinutes(
    minBookingTime as number,
    minBookingTimeUnits as Time_Units_Enum
  );
}

export function getUserBookingInfoMaxBookingMinutes(
  user_booking_info: UserBookingInfo | undefined | null,
  price?: VenuePortionPrice | undefined | null
): number {
  if (!user_booking_info) {
    return DEFAULT_MAX_INTERVAL_MINUTES;
  }
  if (
    !user_booking_info.max_booking_time ||
    !user_booking_info.max_booking_time_units
  ) {
    throw new Error(
      "Error in getUserBookingInfoMaxBookingMinutes: max_booking_time or max_booking_time_units is undefined."
    );
  }

  let maxBookingTime = user_booking_info.max_booking_time;
  let maxBookingTimeUnits = user_booking_info.max_booking_time_units;

  if (user_booking_info.has_fixed_pricing && price) {
    if (price.fixed_booking_time && price.fixed_booking_time_units) {
      maxBookingTime = price.fixed_booking_time;
      maxBookingTimeUnits = price.fixed_booking_time_units;
    }
  }
  return convertTimeAndUnitsToMinutes(
    maxBookingTime as number,
    maxBookingTimeUnits as Time_Units_Enum
  );
}

/**
 * A utility function to convert a schedule booking event into start and end date moment objects
 * @param endDate A localized date string in the format MM/DD/YYYY
 * @param endTime A localized time string in the format 12:34 XM
 * @param startDate A localized date string in the format MM/DD/YYYY
 * @param startTime A localized time string in the format 12:34 XM
 * @returns an object containing the momentized start and end dates of a scheduler booking
 */
export const momentizeSchedulerEvent = ({
  end_date,
  end_time,
  start_date,
  start_time,
}: {
  end_date: string;
  end_time: string;
  start_date: string;
  start_time: string;
}): { startDateMoment: Moment; endDateMoment: Moment } => {
  const startDateMoment = moment(`${start_date} ${start_time}`);
  const endDateMoment = moment(`${end_date} ${end_time}`);
  return { startDateMoment, endDateMoment };
};

/**
 * A utility function to convert localized date and time strings to a reservation-specific format for use in the scheduler
 * @param endDate A localized date string in the format MM/DD/YYYY
 * @param endTime A localized time string in the format 12:34 XM
 * @param start_date A localized date string in the format MM/DD/YYYY
 * @param startTime A localized time string in the format 12:34 XM
 * @param timeZone time zone string in the format Region/City or UTC
 * @returns A string in the format: Weekday, Month DD from 12:34xm - 2:34xm
 */
export const formatLocalizedDateAndTimeForScheduler = ({
  end_date,
  end_time,
  start_date,
  start_time,
  timeZone,
}: {
  end_date: string;
  end_time: string;
  start_date: string;
  start_time: string;
  timeZone: string;
}): string => {
  const { startDateMoment, endDateMoment } = momentizeSchedulerEvent({
    start_date,
    start_time,
    end_date,
    end_time,
  });
  const timeZoneAbbreviation = getTimeZoneAbbreviation(
    startDateMoment,
    timeZone
  );
  return `${startDateMoment.format(
    "dddd, MMMM D"
  )} from ${startDateMoment.format("h:mma")} - ${endDateMoment.format(
    "h:mma"
  )} ${timeZoneAbbreviation}`;
};

/**
 * Gets the date object that represents the day immediately following the input date.
 * @param date Date object to be incremented by 1 day
 * @returns Date object for the day after the input date object
 */
export const getNextDayDate = (date: Date): Date => {
  const dateCopy = new Date(date);
  const nextWeekDate = new Date(dateCopy.setDate(dateCopy.getDate() + 1));
  return nextWeekDate;
};

/**
 * Gets the date object that represents the day immediately preceding the input date.
 * @param date Date object to be decremented by 1 day
 * @returns Date object for the day before the input date object
 */
export const getPreviousDayDate = (date: Date): Date => {
  const dateCopy = new Date(date);
  const previousWeekDate = new Date(dateCopy.setDate(dateCopy.getDate() - 1));
  return previousWeekDate;
};

/**
 * Gets the Sunday-to-Sunday week range that includes the provided origin date.
 * @param originDate Date to use as origin for finding its corresponding week range.
 * @returns Tuple array of originDate's encapsulating Sunday Dates, respectively.
 */
export const getWeekDateRangeForDate = (originDate: Date): [Date, Date] => {
  const originDateForWeek = new Date(originDate);
  // Date.getDay() number is 0-indexed starting with Sunday
  const dayOffset: number = originDateForWeek.getDay();
  const dateForFirstSunday = new Date(
    originDateForWeek.setDate(originDateForWeek.getDate() - dayOffset)
  );
  // Need to get the Sunday after the Saturday so we make sure to get all of Saturday's
  // events but the midnight timestamp will exclude any events from that Sunday.
  const dateForSecondSundayAsNumber: number = new Date(
    dateForFirstSunday
  ).setDate(dateForFirstSunday.getDate() + 7);
  return [
    convertToDateThenSetToMidnight(dateForFirstSunday),
    convertToDateThenSetToMidnight(dateForSecondSundayAsNumber),
  ];
};

/**
 * Gets the date object to be used as the origin for the week immediately following
 * the input date's corresponding week.
 * @param date Date object to be incremented by 1 week (7 days)
 * @returns Date object for a day in the week after the input date object
 */
export const getNextWeekDate = (date: Date): Date => {
  const dateCopy = new Date(date);
  const nextWeekDate = new Date(dateCopy.setDate(dateCopy.getDate() + 7));
  return nextWeekDate;
};

/**
 * Gets the date object to be used as the origin for the week immediately preceding
 * the input date's corresponding week.
 * @param date Date object to be decremented by 1 week (7 days)
 * @returns Date object for a day in the week before the input date object
 */
export const getPreviousWeekDate = (date: Date): Date => {
  const dateCopy = new Date(date);
  const previousWeekDate = new Date(dateCopy.setDate(dateCopy.getDate() - 7));
  return previousWeekDate;
};

/**
 * Gets the 6-week range that includes the provided origin date's corresponding month.
 * @param originDate Date to use as origin for finding its corresponding week range.
 * @returns Tuple array of first Sunday date before first of the month and the Sunday
 * 6 weeks after with Midnight timestamps.
 */
export const getMonthDateRangeForDate = (originDate: Date): [Date, Date] => {
  const originDateForMonth = new Date(originDate);
  // Date.getMonth() number is 0-indexed starting with January
  const dateForFirstOfMonth = new Date(originDateForMonth.setDate(1));
  const dayIndexForFirstOfMonth: number = dateForFirstOfMonth.getDay();
  const firstSundayBeforeFirstOfMonthTimestamp = new Date(
    dateForFirstOfMonth
  ).setDate(dayIndexForFirstOfMonth > 0 ? 1 - dayIndexForFirstOfMonth : -6);
  const firstSundayMidnightDate = convertToDateThenSetToMidnight(
    firstSundayBeforeFirstOfMonthTimestamp
  );
  // Need to get the Sunday after the Saturday so we make sure to get all of Saturday's
  // events but the midnight timestamp will exclude any events from that Sunday.
  const sixWeeksFromFirstSundayTimestamp = new Date(
    firstSundayBeforeFirstOfMonthTimestamp
  ).setDate(firstSundayMidnightDate.getDate() + NUM_DAYS_IN_SIX_WEEK_PERIOD);
  return [
    firstSundayMidnightDate,
    convertToDateThenSetToMidnight(sixWeeksFromFirstSundayTimestamp),
  ];
};

/**
 * Gets the date object to be used as the origin for the month immediately following
 * the input date's corresponding month.
 * @param date Date object to be incremented by 1 month
 * @returns Date object for a day in the month after the input date object
 */
export const getNextMonthDate = (date: Date): Date => {
  const dateCopy = new Date(date);
  // Need to set date to first of the month to ensure we don't skip a month when
  // we increment since not all months have the same number of days
  dateCopy.setDate(1);
  const nextMonthDate = new Date(dateCopy.setMonth(dateCopy.getMonth() + 1));
  return nextMonthDate;
};

/**
 * Gets the date object to be used as the origin for the month immediately preceding
 * the input date's corresponding month.
 * @param date Date object to be decremented by 1 month
 * @returns Date object for a day in the month before the input date object
 */
export const getPreviousMonthDate = (date: Date): Date => {
  const dateCopy = new Date(date);
  dateCopy.setDate(1);
  const previousMonthDate = new Date(
    dateCopy.setMonth(dateCopy.getMonth() - 1)
  );
  return previousMonthDate;
};

export function getTemporalEventDateBounds(
  temporal_event: TemporalEventPropertiesFragment
): [Date, Date] {
  const start_datetime = new Date(
    `${temporal_event.start_date} ${temporal_event.start_time}`
  );
  // create end_datetime
  const end_datetime = new Date(
    `${temporal_event.end_date} ${temporal_event.end_time}`
  );
  return [start_datetime, end_datetime];
}

/**
 * Gets the ISO date bounds for the temporal events. Note that if this is a recurrent
 * event, this is the start and end date and time for the entire series. The exceptions
 * may fall outside of these bounds.
 */
export function getTemporalEventDateBoundsStrings(
  temporal_event: TemporalEventPropertiesFragment
): [string, string] {
  // create start_datetime
  const [start_datetime, end_datetime] =
    getTemporalEventDateBounds(temporal_event);
  // convert both to iso timestamps
  const iso_start_datetime = start_datetime.toISOString();
  const iso_end_datetime = end_datetime.toISOString();
  return [iso_start_datetime, iso_end_datetime];
}

/**
 * Creates human-readable date and time.
 */
export const dateToHumanReadableDateAndTime = (date: Date, showTime = true) => {
  const timeOptions: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  };
  if (!showTime) {
    return date.toLocaleDateString();
  } else {
    return date.toLocaleDateString("en-us", timeOptions);
  }
};

/**
 * Creates human-readable dates that displays currently selected item in calendar and other places.
 */
export function getHumanReadableBookingRange(
  origin_date: Date,
  current_view: SchedulerView
): string {
  const dateToHumanReadable = (date: Date) => {
    return date.toLocaleDateString("en", {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };
  const formatWeekViewString = (first_sunday: Date, last_sunday: Date) => {
    const last_saturday = new Date(last_sunday);
    last_saturday.setDate(last_saturday.getDate() - 1);
    return `${dateToHumanReadable(first_sunday)} - ${dateToHumanReadable(
      last_saturday
    )}`;
  };
  switch (current_view) {
    case Scheduler_Views_Enum.Day: {
      // if original_date is today, return "Today"
      // return human-readable date, i.e. "Aug 3rd, 2023"
      return dateToHumanReadable(origin_date);
    }
    case Scheduler_Views_Enum.Week: {
      // get the weekly range
      const [firstSunday, lastSunday] = getWeekDateRangeForDate(origin_date);
      const formatted_string = formatWeekViewString(firstSunday, lastSunday);
      return formatted_string;
    }
    case Scheduler_Views_Enum.Month: {
      // return human-readable month and year, i.e. "Aug 2023"
      return origin_date.toLocaleDateString("en", {
        month: "long",
        year: "numeric",
      });
    }
    default: {
      throw Error(
        `Error in getHumanReadableBookingRange: ${current_view} is not currently supported.`
      );
    }
  }
}

export function splitIsoTimestamp(
  iso_timestamp: string | undefined
): [string, string] {
  if (!iso_timestamp) {
    throw Error(`Error in splitIsoTimestamp: iso_timestamp is undefined.`);
  }
  const split_timestamp = iso_timestamp.split("T");
  return [split_timestamp[0], split_timestamp[1]];
}

export function getMinimumScheduleDatetime(
  schedules:
    | VenueScheduleAndRelatedPropertiesFragment[]
    | FacilityScheduleAndRelatedPropertiesFragment[],
  replacement_date_string?: string
): Date {
  let minimum_datetime: Date = new Date("2100-01-01");
  for (const schedule of schedules) {
    const temporal_event = schedule.temporal_event_reference;
    if (!temporal_event) {
      continue;
    }
    const current_start_datetime = new Date(
      `${replacement_date_string} ${temporal_event.start_time}`
    );
    if (current_start_datetime < minimum_datetime) {
      minimum_datetime = current_start_datetime;
    }
  }
  return minimum_datetime;
}

export function getMaximumScheduleDatetime(
  schedules:
    | VenueScheduleAndRelatedPropertiesFragment[]
    | FacilityScheduleAndRelatedPropertiesFragment[],
  date_string: string
): Date {
  let maximum_datetime: Date = new Date("1970-01-01");
  for (const schedule of schedules) {
    const temporal_event = schedule.temporal_event_reference;
    if (!temporal_event) {
      continue;
    }
    const current_end_datetime = new Date(
      `${date_string} ${temporal_event.end_time}`
    );
    if (current_end_datetime > maximum_datetime) {
      maximum_datetime = current_end_datetime;
    }
  }
  return maximum_datetime;
}

export function getDateWithHoursMinutes(
  date_only: Date | string,
  hour: number,
  minutes: number
): Date {
  const date: Date = new Date(new Date(date_only).setHours(hour, minutes, 0));
  return date;
}

export function getDateTomorrowWithHoursMinutes(
  date_only: Date | string,
  hour: number,
  minutes: number
): Date {
  const date: Date = new Date(
    getNextDayDate(new Date(date_only)).setHours(hour, minutes, 0, 0)
  );
  return date;
}

export function getStartDatetimeOfLastEvent(
  temporal_event: TemporalEventPropertiesFragment
): Date {
  /*
    DESCRIPTION
    -----------
    This function returns the start datetime of the last event in a series.
    (1) If the event is non-recurrent, then the returned datetime is just drawn from
        the start date and start time.
    (2) If the event is recurrent, then the returned datetime is drawn from the end date.
        * The first case is if the start time and end time are on the same day in UTC time.
          In this case, we just return the start datetime.
        * The second case is if the end time bleeds into the next day in UTC. For example, let's
          say that tne end date is 2023-10-31. The start time is 20:00:00Z and the end time is
          01:00:00Z. This means that the event actually started on 2023-10-30, since the way that
          temporal events are structured for recurrent events is that the end date corresponds to the
          date that the end time of the last event falls on, hence why the "start datetime" of
          the last event is actually the end datetime minus one day.
  */
  if (!temporal_event.is_recurring) {
    // there is only one event, so this date is start date
    const start_datetime = new Date(
      `${temporal_event.start_date} ${temporal_event.start_time}`
    );
    return start_datetime;
  } else {
    const end_date = temporal_event.end_date;
    const start_time = temporal_event.start_time;
    const end_time = temporal_event.end_time;
    // format into two dates
    const end_start_datetime = new Date(`${end_date} ${start_time}`);
    const end_end_datetime = new Date(`${end_date} ${end_time}`);
    if (end_end_datetime > end_start_datetime) {
      return end_start_datetime;
    } else {
      // subtract one day from end_date using moment - see description for details
      const end_datetime_minus_one_day = moment(end_start_datetime).subtract(
        1,
        "day"
      );
      return end_datetime_minus_one_day.toDate();
    }
  }
}

export function getUserBookingTemporalEvents(
  user_booking: UserBookingPropertiesFragment
): TemporalEventAndRelatedPropertiesFragment[] {
  /*
    This function gets the minimum TemporalEvent based on the start date and time of the
    minimum temporal event in the series. Note that this function doesn't take into account
    temporal event exceptions, so if there is a replacement before the minimum temporal event,
    this value will be wrong. This can be mitigated later if there are complaints.
  */
  const user_schedules: UserBookingSchedulePropertiesFragment[] =
    user_booking?.user_booking_schedule;
  if (!user_schedules) {
    throw Error(
      `Error in getUserBookingTemporalEvents: User booking with id ${user_booking.id} does not have any schedules`
    );
  }
  let temporal_events: TemporalEventAndRelatedPropertiesFragment[] = [];
  for (let user_schedule of user_schedules) {
    if (user_schedule.temporal_event_reference) {
      temporal_events.push(user_schedule.temporal_event_reference);
    }
  }
  return temporal_events;
}

export function getMinimumTemporalEvent(
  temporal_events: TemporalEventAndRelatedPropertiesFragment[]
): TemporalEventAndRelatedPropertiesFragment {
  return temporal_events.reduce((min_event, curr_event) => {
    const curr_event_start = new Date(
      `${curr_event.start_date} ${curr_event.start_time}`
    ).getTime();
    const min_event_start = new Date(
      `${min_event.start_date} ${min_event.start_time}`
    ).getTime();
    if (curr_event_start < min_event_start) return curr_event;
    return min_event;
  });
}

export function getMaximumTemporalEvent(
  temporal_events: TemporalEventAndRelatedPropertiesFragment[]
): TemporalEventAndRelatedPropertiesFragment {
  return temporal_events.reduce((max_event, curr_event) => {
    const curr_event_end = new Date(
      `${curr_event.end_date} ${curr_event.end_time}`
    ).getTime();
    const max_event_end = new Date(
      `${max_event.end_date} ${max_event.end_time}`
    ).getTime();
    if (curr_event_end > max_event_end) return curr_event;
    return max_event;
  });
}

export function getStartDatetimeFromTemporalEvents(
  temporal_events: TemporalEventAndRelatedPropertiesFragment[]
): Date {
  const start_event = getMinimumTemporalEvent(temporal_events);
  const start_datetime = new Date(
    `${start_event.start_date} ${start_event.start_time}`
  );
  return start_datetime;
}

export function getEndDatetimeFromTemporalEvents(
  temporal_events: TemporalEventAndRelatedPropertiesFragment[]
): Date {
  const end_event = getMaximumTemporalEvent(temporal_events);
  const end_datetime = new Date(`${end_event.end_date} ${end_event.end_time}`);
  return end_datetime;
}

export const getNumBusinessDaysBeforeDate = (
  numDays: number,
  date: Date
): Date => {
  if (numDays === 0) {
    return date;
  }
  let currentDayIndex = date.getDay(),
    numBusinessDays = 0,
    numActualDays = 0;
  while (numBusinessDays < numDays) {
    if (currentDayIndex > Day_Of_Week_Index.Monday) {
      currentDayIndex -= 1;
      numActualDays += 1;
    } else {
      if (currentDayIndex === Day_Of_Week_Index.Monday) {
        numActualDays += 3;
      }
      if (currentDayIndex === Day_Of_Week_Index.Sunday) {
        numActualDays += 2;
      }
      currentDayIndex = 5;
    }
    numBusinessDays += 1;
  }
  return new Date(new Date(date).setDate(date.getDate() - numActualDays));
};

export const getTwoBusinessDaysBeforeDate = (date: Date): Date =>
  getNumBusinessDaysBeforeDate(2, date);

export const addNumYearsToDate = (date: Date, numYears: number): Date => {
  return moment(date).add(numYears, "years").toDate();
};

export function calculateAge(
  birthday: Date | string,
  includeMonthsWithYears: boolean = false
): string {
  const duration = moment.duration(moment().diff(birthday));

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();

  const pluralYears = pluralize("year", years);
  const pluralMonths = pluralize("month", months);
  const pluralDays = pluralize("day", days);

  if (years && months && includeMonthsWithYears) {
    return `${years} ${pluralYears}, ${months} ${pluralMonths}`;
  }

  if (years) {
    return `${years} ${pluralYears} old`;
  }

  if (months) {
    return `${months} ${pluralMonths}`;
  }

  if (days) {
    return `${days} ${pluralDays}`;
  }

  return "0 days";
}

export function calculateAgeNumeral(birthday: Date): number {
  let age = moment().diff(birthday, "years");
  return age;
}

export const formatTimeUnitToShortened = (unit: Time_Units_Enum) => {
  switch (unit) {
    case Time_Units_Enum.Hour:
      return "hr";
    case Time_Units_Enum.Minute:
      return "min";
    case Time_Units_Enum.Month:
      return "mo";
    case Time_Units_Enum.Second:
      return "s";
    case Time_Units_Enum.Week:
      return "wk";
    case Time_Units_Enum.Year:
      return "yr";
    default:
      console.error(`Unhandled case for Time Unit ${unit}`);
      return "";
  }
};

export const formatAmountPerTimeUnit = (
  amount: number | string,
  unit: Time_Units_Enum
) => {
  return `${amount}/${formatTimeUnitToShortened(unit)}`;
};

export const convertDateToUnixTimestampSeconds = (date: Date): number => {
  return parseInt((date.getTime() / 1000).toFixed(0));
};

export const convertUnixTimestampSecondsToDate = (timestamp: number): Date => {
  return new Date(timestamp * 1000);
};

export const timeAsLocalTime = (utcTime: string) =>
  DateTime.fromISO(utcTime).toLocal().toFormat("HH:mm");

export const serializeLocalTime = (localTime: string) =>
  DateTime.fromISO(localTime).toFormat("HH:mm:ssZ");

export const getTotalHours = (startDate: Date, endDate: Date) => {
  const diffInHours = differenceInHours(startDate, endDate);
  return Math.abs(diffInHours);
};

export const getTotalMinutes = (startDate: Date, endDate: Date) => {
  const diffInMinutes = differenceInMinutes(startDate, endDate);
  return Math.abs(diffInMinutes);
};

export function getFormattedLocalTimeRangeString(
  startIsoTime: string,
  endIsoTime: string,
  time_zone: Time_Zones_Enum
): string {
  const [currentDateOnlyString] = new Date().toISOString().split("T");
  const momentTimeZone = getMomentTimeZone(time_zone);
  const moddedStartDate = new Date(`${currentDateOnlyString} ${startIsoTime}`);
  const moddedEndDate = new Date(`${currentDateOnlyString} ${endIsoTime}`);
  const moddedStartDateString = moment(moddedStartDate)
    .tz(momentTimeZone)
    .format("LT");
  const moddedEndDateString = moment(moddedEndDate)
    .tz(momentTimeZone)
    .format("LT z");
  const formattedTimeRangeString = `${moddedStartDateString} - ${moddedEndDateString}`;
  return formattedTimeRangeString;
}

export function convertIsoToLocal(
  isoTimestamp: string,
  time_zone: Time_Zones_Enum
): {
  localDateString: string;
  localTimeString: string;
} {
  // Parse the ISO timestamp
  const momentObj = moment(isoTimestamp);

  // Convert to the specified timezone
  const momentTimeZone = getMomentTimeZone(time_zone);
  const localMomentObj = momentObj.tz(momentTimeZone);

  // Format the date and time strings
  const localDateString = localMomentObj.format("YYYY-MM-DD");
  const localTimeString = localMomentObj.format("HH:mm:ss");

  return {
    localDateString,
    localTimeString,
  };
}

export function getLocalDateAndTimeComponents(
  startIsoTime: string,
  endIsoTime: string,
  time_zone: Time_Zones_Enum
): {
  localStartDate: string;
  localEndDate: string;
  localStartTime: string;
  localEndTime: string;
} {
  // convert time zone to moment tz
  const { localDateString: localStartDate, localTimeString: localStartTime } =
    convertIsoToLocal(startIsoTime, time_zone);
  const { localDateString: localEndDate, localTimeString: localEndTime } =
    convertIsoToLocal(endIsoTime, time_zone);
  return {
    localStartDate,
    localEndDate,
    localStartTime,
    localEndTime,
  };
}

/**
 * Gets the time zone string for an organization.
 * @param timeZone Time_Zones_Enum to be converted to moment time zone string. If missing, returns the default time zone string.
 * @returns string in the format Region/City or simply UTC.
 */
export function getMomentTimeZone(
  timeZone: Time_Zones_Enum | null | undefined
): string {
  switch (timeZone) {
    case Time_Zones_Enum.Anchorage:
      return "America/Anchorage";
    case Time_Zones_Enum.Chicago:
      return "America/Chicago";
    case Time_Zones_Enum.Denver:
      return "America/Denver";
    case Time_Zones_Enum.Honolulu:
      return "Pacific/Honolulu";
    case Time_Zones_Enum.LosAngeles:
      return "America/Los_Angeles";
    case Time_Zones_Enum.NewYork:
      return "America/New_York";
    case Time_Zones_Enum.Utc:
      return "UTC";
    default: {
      captureMessage(
        `Time zone ${timeZone} not supported. Defaulting to ${DEFAULT_TIME_ZONE}.`,
        {
          level: "warning",
        }
      );
      return `America/${DEFAULT_TIME_ZONE}`;
    }
  }
}

/**
 * Gets the time zone abbreviation for a date-ish input using the current organization's time zone.
 * @param dateLike moment.MomentInput: could be a string, number, Date, moment or other.
 * @param timeZone time zone string in the format Region/City or UTC.
 * @returns 3-character time zone abbreviation.
 */
export const getTimeZoneAbbreviation = (
  dateLike: moment.MomentInput,
  timeZone: string
): string => moment(dateLike).tz(timeZone).format("z");

export const getDaysOfWeek = (
  associatedTemporalEvent: TemporalEventAndRelatedPropertiesFragment
) =>
  compact(
    associatedTemporalEvent?.temporal_event_to_recurrences.map(
      (d) => d.day_of_week
    ) ?? []
  );

export function formatTimeSpanForTemporalEvent(
  value: TemporalEventPropertiesFragment,
  kind: "date" | "time" | "both",
  { joiner = "-" }: { joiner?: string } = {}
) {
  const [start, end] = match(kind)
    .with("date", () => [
      DateTime.fromISO(value.start_date),
      DateTime.fromISO(value.end_date),
    ])
    .with("time", () => [
      DateTime.fromISO(value.start_time),
      DateTime.fromISO(value.end_time),
    ])
    .with("both", () => [
      combineDateAndTime(value.start_date, value.start_time),
      combineDateAndTime(value.end_date, value.end_time),
    ])
    .exhaustive();

  const format = match(kind)
    .with("date", () => DateTime.DATE_SHORT)
    .with("time", () => DateTime.TIME_SIMPLE)
    .with("both", () => DateTime.DATETIME_SHORT)
    .exhaustive();

  return [
    start.toLocaleString(format),
    joiner,
    end.toLocaleString(format),
  ].join(" ");
}

export function combineDateAndTime(date: string, time: string) {
  const parsedTime = DateTime.fromISO(time);

  return DateTime.fromISO(date).set({
    hour: parsedTime.hour,
    minute: parsedTime.minute,
    second: parsedTime.second,
    millisecond: parsedTime.millisecond,
  });
}

export function getDateRangeForTemporalEvents(
  temporalEvents: TemporalEventPropertiesFragment[]
) {
  function isEarlierThan(
    e1: TemporalEventPropertiesFragment,
    e2: TemporalEventPropertiesFragment
  ) {
    return DateTime.fromISO(e1.start_date) < DateTime.fromISO(e2.start_date);
  }

  function isLaterThan(
    e1: TemporalEventPropertiesFragment,
    e2: TemporalEventPropertiesFragment
  ) {
    return DateTime.fromISO(e1.start_date) > DateTime.fromISO(e2.start_date);
  }

  function doEventTimesMatch(
    e1: TemporalEventPropertiesFragment,
    e2: TemporalEventPropertiesFragment
  ) {
    return (
      (e1.start_time === e2.start_time && e1.end_time === e2.end_time) ||
      (e1.is_full_day_event && e2.is_full_day_event) ||
      (e1.is_closed_all_day && e2.is_closed_all_day)
    );
  }

  let earliestEvent = null;
  let latestEvent = null;
  let eventTimesMatch = true;

  for (const event of temporalEvents) {
    if (!earliestEvent || !latestEvent) {
      earliestEvent = event;
      latestEvent = event;
      continue;
    }

    if (isEarlierThan(event, earliestEvent)) {
      earliestEvent = event;
    }

    if (isLaterThan(event, latestEvent)) {
      latestEvent = event;
    }

    if (!doEventTimesMatch(earliestEvent, latestEvent)) {
      eventTimesMatch = false;
    }
  }

  return {
    earliestEvent,
    latestEvent,
    eventTimesMatch,
  };
}

// Converts date to noon local based on the provided timezone
// Note that the date is determined by the UTC date of the provided date object
// (so it may not match the date logged by Javascript based on the browser timezone)
export function convertToNoonLocal(
  date: Date,
  timeZone: Time_Zones_Enum
): Date {
  return moment
    .utc(date)
    .tz(getMomentTimeZone(timeZone), true)
    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    .toDate();
}

/**
 * Format the event date and time
 * @param {string} eventStart - The start date and time of the event.
 * @param {string} eventEnd - The end date and time of the event.
 * @returns {object} - An object containing the formatted event date and time.
 * */
export function formatEventDateTime(
  eventStart: string,
  eventEnd: string
): { eventDate: string; eventTime: string } {
  const startDate = new Date(eventStart);
  const endDate = new Date(eventEnd);

  const dateFormatter = new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const timeFormatter = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const startTime = timeFormatter.format(startDate);
  const endTime = timeFormatter.format(endDate);

  return {
    eventDate: dateFormatter.format(startDate),
    eventTime: `${startTime} - ${endTime}`,
  };
}

export const convertDateToISODateString = (date: Date): string => {
  const yearStr = date.getFullYear();

  // Date.getMonth is 0-based, so January is 0, February is 1, etc.
  const monthStr = (date.getMonth() + 1).toString().padStart(2, "0");
  const dayStr = date.getDate().toString().padStart(2, "0");

  return `${yearStr}-${monthStr}-${dayStr}`;
};

export const convertDateToLocalDate = (date: Date) => {
  return moment(date, "YYYY-MM-DD").toDate();
};
