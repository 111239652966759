import {
  getMarkedUpStripeACHFee,
  getMarkedUpStripeCCFee,
} from "@kaizenlabs/price-helpers";
import numeral from "numeral";

import { Time_Units_Enum } from "../api/generated";
import { ACH_PAYMENTS } from "../constants";

// This format will drop the cents portion for whole dollar amounts
// e.g. 12.50 => $12.50, 12.00 => $12
export const removeCentsWholeDollarFormat = "$0,0[.]00";

export function roundToNearestCent(price_cents: number): number {
  return Math.round((price_cents + Number.EPSILON) * 100) / 100;
}
export function formatCents(
  n: number,
  format = "$0,0.00",
  suffix?: string
): string {
  if (n == null) return "";
  return formatDollars(n / 100, format, suffix);
}

export function formatDollars(
  n: number,
  format = "$0,0.00",
  suffix?: string
): string {
  if (n == null) return "";
  return `${numeral(n).format(format)}${suffix ? ` ${suffix}` : ""}`;
}

export function formatPriceCentsAndUnits(
  price_cents: number,
  price_unit: string
): string {
  const formattedPrice = formatCents(price_cents, removeCentsWholeDollarFormat);
  const suffix =
    price_unit === Time_Units_Enum.NA ? "" : `/${price_unit.toLowerCase()}`;

  return `${formattedPrice}${suffix}`;
}

export function getPaymentTypeFee(price: number, paymentType: string) {
  if (ACH_PAYMENTS.includes(paymentType)) {
    return getMarkedUpStripeACHFee(price);
  }
  return getMarkedUpStripeCCFee(price);
}
