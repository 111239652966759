import { Typography } from "@mui/material";
import cn from "classnames";
import React from "react";

export enum StatusChipVariant {
  "CONFIRM" = "Confirm",
  "ACTION" = "Action",
  "WARNING" = "Warning",
  "ERROR" = "Error",
  "INACTIVE" = "Inactive",
  "PUBLISHED" = "Published",
}

export enum StatusChipSize {
  "XSMALL" = "xsmall",
  "XXSMALL" = "xxsmall",
}

export interface StatusChipProps {
  className?: string;
  onClick?(): void;
  variant?: StatusChipVariant;
  size?: StatusChipSize;
  label: string;
  indicatorReplacement?: React.ReactNode;
  hideIndicator?: boolean;
}

const StatusChip = ({
  className,
  onClick,
  variant = StatusChipVariant.INACTIVE,
  size = StatusChipSize.XSMALL,
  label,
  hideIndicator = false,
  indicatorReplacement,
}: StatusChipProps) => {
  return (
    <div
      className={cn(
        "flex items-center justify-center gap-2 px-3 rounded-full w-fit",
        {
          "h-[32px]": size === StatusChipSize.XSMALL,
          "h-[24px]": size === StatusChipSize.XXSMALL,
          "bg-[#E1F3EE]": variant === StatusChipVariant.CONFIRM,
          "bg-[#DFE8FF]": variant === StatusChipVariant.ACTION,
          "bg-[#FFEEE7]": variant === StatusChipVariant.WARNING,
          "bg-[#FCE8E8]": variant === StatusChipVariant.ERROR,
          "bg-[#F4F4F4]": variant === StatusChipVariant.INACTIVE,
          "bg-[#E9EBFE]": variant === StatusChipVariant.PUBLISHED,
          "hover:brightness-95 transition duration-150": onClick,
        },
        className
      )}
      onClick={onClick}
    >
      {indicatorReplacement ? (
        indicatorReplacement
      ) : !hideIndicator ? (
        <div
          className={cn("w-2 h-2 rounded-full", {
            "bg-[#006647]": variant === StatusChipVariant.CONFIRM,
            "bg-[#1F35F8]":
              variant === StatusChipVariant.ACTION ||
              variant === StatusChipVariant.PUBLISHED,
            "bg-[#FF5811]": variant === StatusChipVariant.WARNING,
            "bg-[#B30000]": variant === StatusChipVariant.ERROR,
            "bg-[#959393]": variant === StatusChipVariant.INACTIVE,
          })}
        />
      ) : null}
      <Typography
        className={cn("font-medium whitespace-nowrap", {
          "text-14": size === StatusChipSize.XSMALL,
          "text-12": size === StatusChipSize.XXSMALL,
        })}
      >
        {label}
      </Typography>
    </div>
  );
};

export default StatusChip;
