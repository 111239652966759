import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import * as Sentry from "@sentry/nextjs";
import { Store } from "redux";

import config from "../common/config";
import { IAppState } from "../store/app";
import { EnqueueSnackbar } from "../store/notification";
import { isLocalOrDevEnvironment } from "../utils/feature_flag_utils";

export const CUSTOM_ERROR_CONTEXT = {
  context: {
    customErrorHandling: true,
  },
};

class ApolloService {
  public static instance: ApolloService;
  public static store: Store<IAppState>;
  public static client: ApolloClient<NormalizedCacheObject>;

  public static init(store: Store<IAppState>) {
    this.instance = new ApolloService(store);
    ApolloService.client = ApolloService.createApolloClient();
  }

  public static createApolloClient() {
    const authLink = setContext(() => {
      const { token, isManager } = ApolloService.store.getState().auth;
      const headers: Record<string, string | undefined> = {
        "X-Hasura-Role":
          token && isManager ? "manager" : token ? "user" : "anonymous",
        "Access-Control-Allow-Origin": config.NEXT_PUBLIC_DOMAIN,
        origin: config.NEXT_PUBLIC_DOMAIN,
      };
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      return { headers };
    });

    const errorLink = onError(
      ({ graphQLErrors, networkError, response, operation }) => {
        if (networkError) {
          const errorMessage = `[Network error]: ${networkError}`;
          console.error(errorMessage);
          Sentry.captureException(new Error(errorMessage));
          ApolloService.store.dispatch(
            EnqueueSnackbar({
              message:
                "There was a problem connecting to the server. Please try again.",
              options: { variant: "error" },
            })
          );
          if (response) {
            response.errors = undefined;
          }
          return;
        }

        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
            if (isLocalOrDevEnvironment()) {
              console.error(errorMessage);
            }
            Sentry.captureException(new Error(errorMessage));
          });
        }

        const context = operation.getContext();

        // If we didn't specify that we have custom error handling setup
        // then swallow the error here. This is a best-effort practice
        // to keep unhandled errors from crashing the page the error occurred on.
        if (!context.customErrorHandling && response) {
          response.errors = undefined;
        }
      }
    );

    const httpLink = new HttpLink({
      uri: config.NEXT_PUBLIC_HASURA_SCHEMA_LOCATION,
    });

    const wsLink = new WebSocketLink({
      uri: config.NEXT_PUBLIC_HASURA_SUBSCRIPTION_URL as string,
      options: {
        reconnect: true,
      },
    });

    // The split function takes three parameters:
    //
    // * A function that's called for each operation to execute
    // * The Link to use for an operation if the function returns a "truthy" value
    // * The Link to use for an operation if the function returns a "falsy" value
    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink
    );

    return new ApolloClient({
      link: ApolloLink.from([authLink, errorLink, splitLink]),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: "network-only",
        },
      },
    });
  }

  public static async clearCache() {
    await ApolloService.client.resetStore();
  }

  private constructor(store: Store<IAppState>) {
    ApolloService.store = store;
    ApolloService.instance = this;
  }
}

export default ApolloService;
