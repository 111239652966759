import React from "react";

import { useLoginReduxHandler } from "../../hooks/useLoginReduxHandler";

interface AuthWrapperProps {
  children: React.ReactNode;
}

const AuthWrapper = ({ children }: AuthWrapperProps) => {
  useLoginReduxHandler();
  return <>{children}</>;
};

export default AuthWrapper;
