import { useSelector } from "react-redux";
import { defineAction, setWith, TypedReducer } from "redoodle";
import * as Reselect from "reselect";

import { Time_Zones_Enum } from "../api/generated";
import { DEFAULT_TIME_ZONE } from "../constants/constants";
import { IAppState } from "./app";

// model
type OrganizationStorageBuckets = {
  base: string;
  facilities: string;
  venues: string;
};
export interface IOrganizationState {
  organization: string;
  organizationID: string;
  organizationTimeZone: Time_Zones_Enum;
  organizationStorageBuckets: OrganizationStorageBuckets;
  organizationResidentZipCodes?: string[];
}

// actions
export const SetOrganization = defineAction("ORGANIZATION/SET_ORG")<string>();
export const SetOrganizationID = defineAction(
  "ORGANIZATION/SET_ORG_ID"
)<string>();
export const SetOrganizationTimeZone = defineAction(
  "ORGANIZATION/SET_ORG_TIMEZONE"
)<Time_Zones_Enum>();
export const SetOrganizationStorageBuckets = defineAction(
  "ORGANIZATION/SET_STORAGE_BUCKETS"
)<OrganizationStorageBuckets>();
export const SetOrganizationResidentZipCodes = defineAction(
  "ORGANIZATION/SET_RESIDENT_ZIP_CODES"
)<string[]>();
// reducer
export const organizationReducer = TypedReducer.builder<IOrganizationState>()
  .withHandler(SetOrganization.TYPE, (state, organization) =>
    setWith(state, { organization })
  )
  .withHandler(SetOrganizationID.TYPE, (state, organizationID) =>
    setWith(state, { organizationID })
  )
  .withHandler(SetOrganizationTimeZone.TYPE, (state, organizationTimeZone) =>
    setWith(state, { organizationTimeZone })
  )
  .withHandler(
    SetOrganizationStorageBuckets.TYPE,
    (state, organizationStorageBuckets) =>
      setWith(state, { organizationStorageBuckets })
  )
  .withHandler(
    SetOrganizationResidentZipCodes.TYPE,
    (state, organizationResidentZipCodes) =>
      setWith(state, { organizationResidentZipCodes })
  )
  .withDefaultHandler((state) => (state ? state : initialOrgState))
  .build();

/**
 * initialOrgState should have organization and organizationID set to empty strings at first but these values should be set to the correct strings in the root app component at startup.
 */
export const initialOrgState: IOrganizationState = {
  organization: "",
  organizationID: "",
  organizationTimeZone: DEFAULT_TIME_ZONE,
  organizationStorageBuckets: {
    facilities: "",
    venues: "",
    base: "",
  },
};

// selectors
export const organizationSelector = Reselect.createSelector(
  (state: IAppState) => state.organization.organization,
  (organization: string) => organization
);

export const organizationIDSelector = Reselect.createSelector(
  (state: IAppState) => state.organization.organizationID,
  (organizationID: string) => organizationID
);

export const organizationTimeZoneSelector = Reselect.createSelector(
  (state: IAppState) => state.organization.organizationTimeZone,
  (organizationTimeZone: Time_Zones_Enum) => organizationTimeZone
);

export const organizationStorageBucketsSelector = Reselect.createSelector(
  (state: IAppState) => state.organization.organizationStorageBuckets,
  (organizationStorageBuckets: OrganizationStorageBuckets) =>
    organizationStorageBuckets
);

export const organizationResidentZipCodesSelector = Reselect.createSelector(
  (state: IAppState) => state.organization.organizationResidentZipCodes,
  (organizationResidentZipCodes: string[] | undefined) =>
    organizationResidentZipCodes
);

// Selector Hooks
export const useOrganizationIDSelector = () =>
  useSelector(organizationIDSelector);
export const useOrganizationSlugSelector = () =>
  useSelector(organizationSelector);
export const useOrganizationTimeZoneSelector = () =>
  useSelector(organizationTimeZoneSelector);
export const useOrganizationStorageBuckets = () =>
  useSelector(organizationStorageBucketsSelector);
export const useOrganizationResidentZipCodesSelector = () =>
  useSelector(organizationResidentZipCodesSelector);
