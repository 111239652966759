import { useDispatch, useSelector } from "react-redux";
import { defineAction, setWith, TypedReducer } from "redoodle";
import * as Reselect from "reselect";

import {
  DocumentSubmission,
  MembershipCard,
  MembershipFormSubmissions,
} from "../constants/types";
import { IAppState } from "./app";

export interface IMembershipState {
  membershipFormSubmissions: MembershipFormSubmissions;
  membershipCard?: MembershipCard;
  householdMemberIds?: string[];
}

export interface SubmitRequiredMembershipDocumentPayload
  extends DocumentSubmission {
  formSubmissionId?: string;
  formDefinitionId: string;
  membershipFormId: string;
  schemaFormDefinitionId?: string;
}

export interface RemoveRequiredMembershipDocumentPayload {
  membershipFormId: string;
}

export const SubmitRequiredMembershipDocument = defineAction(
  "APP/MEMBERSHIP/SUBMIT_REQUIRED_MEMBERSHIP_DOCUMENT"
)<SubmitRequiredMembershipDocumentPayload>();

export const RemoveRequiredMembershipDocument = defineAction(
  "APP/MEMBERSHIP/REMOVE_REQUIRED_MEMBERSHIP_DOCUMENTS"
)<RemoveRequiredMembershipDocumentPayload>();

export const SubmitMembershipCard = defineAction(
  "APP/MEMBERSHIP/SUBMIT_MEMBERSHIP_CARD"
)<MembershipCard>();

export const ClearMembershipStore = defineAction(
  "APP/MEMBERSHIP/CLEAR_MEMBERSHIP_STORE"
)<void>();

export const SetHouseholdMemberIds = defineAction(
  "APP/MEMBERSHIP/SET_HOUSEHOLD_MEMBER_IDS"
)<string[] | undefined>();

export const RemoveHouseholdMemberId = defineAction(
  "APP/MEMBERSHIP/REMOVE_HOUSEHOLD_MEMBER_ID"
)<string>();

// reducer
export const membershipReducer: any = TypedReducer.builder<IMembershipState>()
  .withHandler(
    SubmitRequiredMembershipDocument.TYPE,
    (
      state,
      {
        formSubmissionId,
        membershipFormId,
        formDefinitionId,
        file,
        existingDocumentId,
        schemaFormDefinitionId,
        schemaFormData,
      }
    ) => {
      return setWith(state, {
        ...state,
        membershipFormSubmissions: {
          ...state.membershipFormSubmissions,
          [membershipFormId]: {
            formSubmissionId: formSubmissionId,
            formId: membershipFormId,
            existingDocumentId,
            newDocument: file,
            formDefinitionId,
            membershipFormId,
            schemaFormDefinitionId,
            schemaFormData,
          },
        },
      });
    }
  )
  .withHandler(
    RemoveRequiredMembershipDocument.TYPE,
    (state, { membershipFormId }) => {
      const membershipFormSubmissions = state.membershipFormSubmissions;
      delete membershipFormSubmissions[membershipFormId];

      return setWith(state, {
        ...state,
        membershipFormSubmissions,
      });
    }
  )
  .withHandler(SubmitMembershipCard.TYPE, (state, membershipCard) => {
    return setWith(state, {
      ...state,
      membershipCard,
    });
  })
  .withHandler(ClearMembershipStore.TYPE, (state) =>
    setWith(state, initialMembershipState)
  )
  .withHandler(SetHouseholdMemberIds.TYPE, (state, householdMemberIds) =>
    setWith(state, {
      ...state,
      householdMemberIds,
    })
  )
  .withHandler(RemoveHouseholdMemberId.TYPE, (state, idToRemove) =>
    setWith(state, {
      ...state,
      householdMemberIds: state.householdMemberIds?.filter(
        (memberId) => memberId !== idToRemove
      ),
    })
  )
  .withDefaultHandler((state) => (state ? state : initialMembershipState))
  .build();

// init
export const initialMembershipState: IMembershipState = {
  membershipFormSubmissions: {},
  membershipCard: undefined,
};

export const membershipFormSubmissionsSelector = Reselect.createSelector(
  (state: IAppState) => state.membership.membershipFormSubmissions,
  (membershipFormSubmissions) => {
    return membershipFormSubmissions;
  }
);

export const membershipFormSubmissionsForFormSelector = Reselect.createSelector(
  [
    (state: IAppState) => state.membership.membershipFormSubmissions,
    (state: IAppState, formId: string) => formId,
  ],
  (membershipFormSubmissions, formId) =>
    membershipFormSubmissions?.[formId] ?? null
);

export const membershipCardSelector = Reselect.createSelector(
  (state: IAppState) => state.membership.membershipCard,
  (membershipCard) => {
    return membershipCard;
  }
);

export const householdMemberIdsSelector = Reselect.createSelector(
  (state: IAppState) => state.membership.householdMemberIds,
  (memberIds) => {
    return memberIds;
  }
);

export const useHouseholdMemberIdsForMembershipSelector = () =>
  useSelector(householdMemberIdsSelector);
export const useSetHouseholdMemberIdsForMembership = () => {
  const dispatch = useDispatch();
  return (memberIds: string[] | undefined) =>
    dispatch(SetHouseholdMemberIds(memberIds));
};
export const useRemoveHouseholdMemberIdForMembership = () => {
  const dispatch = useDispatch();
  return (memberId: string) => dispatch(RemoveHouseholdMemberId(memberId));
};
