import { registrableUnitStatusComparator } from "@kaizenlabs/registrable-unit-state-helpers";
import { DateTime } from "luxon";

import {
  Household_Privilege_Types_Enum,
  HouseholdMemberPropertiesFragment,
  RegistrableUnitPropertiesFragment,
} from "../api/generated";
import {
  getTemporalEventForRegistrableUnit,
  RegistrableUnitAndStatus,
} from "./program_utils";

export type MultiSortComparator<T> = (a: T, b: T) => number;

/**
 * Applies a set of sorting functions (comparators) to a given list in the order that they are passed.
 * It will use the first result returned by one of the functions that does not equal 0 as the sort value
 * when comparing two list items. If all comparators return 0 then 0 will be used as the sort value to
 * signify that these list items are equal in terms of sort order and should be placed at the same point
 * in the final list.
 *
 * @param list - the list we want to sort
 * @param comparators - the sort comparsion functions we want to apply to the list in the order we want to apply them
 * @returns the sorted list
 */
export function multisort<T>(list: T[], comparators: MultiSortComparator<T>[]) {
  return list.toSorted((a, b) => {
    for (const comparator of comparators) {
      const result = comparator(a, b);

      if (result !== 0) {
        return result;
      }
    }

    return 0;
  });
}

export function sortHouseholdMembersByPrivelegeType(
  members: HouseholdMemberPropertiesFragment[]
) {
  return [...members].sort((a, b) => {
    if (a.privilege_type === b.privilege_type) {
      return 0;
    }

    if (a.privilege_type === Household_Privilege_Types_Enum.HeadOfHousehold) {
      return -1;
    }

    return 1;
  });
}

export function paymentStatusComparator<T extends { needsPayment: boolean }>(
  a: T,
  b: T
) {
  if (a.needsPayment === b.needsPayment) {
    return 0;
  }

  return a.needsPayment ? -1 : 1;
}

export function documentStatusComparator<
  T extends { needsFormSubmissions: boolean }
>(a: T, b: T) {
  if (a.needsFormSubmissions === b.needsFormSubmissions) {
    return 0;
  }

  return a.needsFormSubmissions ? -1 : 1;
}

export function registrableUnitAndStatusStatusComparator(
  a: RegistrableUnitAndStatus,
  b: RegistrableUnitAndStatus
) {
  return registrableUnitStatusComparator(a.status, b.status);
}

/**
 * Utility to help compare two registrable units by one of their start or end date/time properties
 *
 * @param a - the first registrable unit
 * @param b - the second registrable unit
 * @param dateTimePropertyName - the date/time property you want to compare
 */
export function registrableUnitDateTimeComparator(
  a: RegistrableUnitPropertiesFragment,
  b: RegistrableUnitPropertiesFragment,
  dateTimePropertyName: "start_date" | "start_time" | "end_date" | "end_time"
) {
  const aTemporalEvent = getTemporalEventForRegistrableUnit(a);
  const bTemporalEvent = getTemporalEventForRegistrableUnit(b);

  const aProperty = aTemporalEvent?.[dateTimePropertyName];
  const bProperty = bTemporalEvent?.[dateTimePropertyName];

  if (aProperty === bProperty) {
    return 0;
  }

  // Should never realistically be null but handling just in case.
  // Moving nulls to the back of the list.
  if (!aProperty) {
    return 1;
  }

  if (!bProperty) {
    return -1;
  }

  const aDateTime = DateTime.fromISO(aProperty);
  const bDateTime = DateTime.fromISO(bProperty);

  if (aDateTime > bDateTime) {
    return 1;
  } else {
    return -1;
  }
}

export function registrableUnitStartDateComparator(
  a: RegistrableUnitPropertiesFragment,
  b: RegistrableUnitPropertiesFragment
) {
  return registrableUnitDateTimeComparator(a, b, "start_date");
}

export function registrableUnitAndStatusStartDateComparator(
  a: RegistrableUnitAndStatus,
  b: RegistrableUnitAndStatus
) {
  return registrableUnitStartDateComparator(
    a.registrableUnit,
    b.registrableUnit
  );
}

export function registrableUnitStartTimeComparator(
  a: RegistrableUnitPropertiesFragment,
  b: RegistrableUnitPropertiesFragment
) {
  return registrableUnitDateTimeComparator(a, b, "start_time");
}

export function registrableUnitAndStatusStartTimeComparator(
  a: RegistrableUnitAndStatus,
  b: RegistrableUnitAndStatus
) {
  return registrableUnitStartTimeComparator(
    a.registrableUnit,
    b.registrableUnit
  );
}

export function sortCampRegistrableUnitsAndStatuses(
  registrableUnitsAndStatuses: RegistrableUnitAndStatus[]
) {
  // sort camp registrable units by start date first, start time second and status last
  return multisort(registrableUnitsAndStatuses, [
    registrableUnitAndStatusStartDateComparator,
    registrableUnitAndStatusStartTimeComparator,
    registrableUnitAndStatusStatusComparator,
  ]);
}

export function sortClassRegistrableUnitsAndStatuses(
  registrableUnitsAndStatuses: RegistrableUnitAndStatus[]
) {
  // sort class registrable unit by status first, start date second and start time last
  return multisort(registrableUnitsAndStatuses, [
    registrableUnitAndStatusStatusComparator,
    registrableUnitAndStatusStartDateComparator,
    registrableUnitAndStatusStartTimeComparator,
  ]);
}
