import update from "immutability-helper";
import { useDispatch, useSelector } from "react-redux";
import { defineAction, setWith, TypedReducer } from "redoodle";
import * as Reselect from "reselect";

import { MediaInfoInput, VenueInput } from "../api/generated";
import { SportOptionType } from "../components/shared/FacilitiesManagement/FacilityCRUDWorkflow";
import { ReservableArea, UserFile } from "../constants/types";
import { IAppState } from "./app";

// model
export interface IVenueManagementState extends VenueInput {
  venue_portions: ReservableArea[];
  hours: string;
  amenities: string[];
  sportsOptionTypes: SportOptionType[];
  images: MediaInfoInput[];
  imagesToDelete: UserFile[];
  is_hidden_from_public: boolean;
  settings: VenueInput["settings"];
}

type VenueDetails = Pick<
  IVenueManagementState,
  | "id"
  | "name"
  | "description"
  | "type"
  | "capacity"
  | "amenities"
  | "sportsOptionTypes"
  | "location_notes"
  | "facility_id"
  | "is_hidden_from_public"
  | "settings"
>;

// actions
const ResetVenueState = defineAction(
  "APP/VENUE_MANAGEMENT/RESET_VENUE_MANAGEMENT_STATE"
)();
const SetVenueDetails = defineAction(
  "APP/VENUE_MANAGEMENT/SET_DETAILS"
)<VenueDetails>();
const SetVenueImages = defineAction("APP/VENUE_MANAGEMENT/SET_IMAGES")<
  MediaInfoInput[]
>();
const SetVenueDeleteImages = defineAction(
  "APP/VENUE_MANAGEMENT/SET_DELETE_IMAGE_FROM_PUBLIC_BUCKET"
)<UserFile[]>();
const SetVenueHours = defineAction("APP/VENUE_MANAGEMENT/SET_HOURS")<string>();
const SetVenuePortions = defineAction(
  "APP/VENUE_MANAGEMENT/SET_RESERVABLE_AREAS"
)<ReservableArea[]>();
const SetVenueIsHiddenFromPublic = defineAction(
  "APP/VENUE_MANAGEMENT/SET_IS_HIDDEN"
)<boolean>();
const SetVenueStateToCurrentVenue = defineAction(
  "APP/VENUE_MANAGEMENT/SET_VENUE_STATE_TO_CURRENT_VENUE"
)<IVenueManagementState>();

// reducer
export const venueManagementReducer: any =
  TypedReducer.builder<IVenueManagementState>()
    .withHandler(
      SetVenueDetails.TYPE,
      (
        state,
        {
          name,
          type,
          location_notes,
          description,
          amenities,
          sportsOptionTypes,
          capacity,
          facility_id,
          is_hidden_from_public,
          settings,
        }
      ) =>
        setWith(state, {
          name,
          type,
          location_notes,
          description,
          amenities,
          sportsOptionTypes,
          capacity,
          facility_id,
          is_hidden_from_public,
          settings,
        })
    )
    .withHandler(SetVenueImages.TYPE, (state, venueImages) =>
      setWith(state, { images: venueImages })
    )
    .withHandler(SetVenueDeleteImages.TYPE, (state, venueImages) =>
      setWith(state, { imagesToDelete: venueImages })
    )
    .withHandler(SetVenueHours.TYPE, (state, venueHours) =>
      setWith(state, { hours: venueHours })
    )
    .withHandler(SetVenuePortions.TYPE, (state, venuePortions) =>
      setWith(state, { venue_portions: venuePortions })
    )
    .withHandler(SetVenueIsHiddenFromPublic.TYPE, (state, venueIsHidden) =>
      setWith(state, { is_hidden_from_public: venueIsHidden })
    )
    .withHandler(ResetVenueState.TYPE, (state) =>
      update(state, { $set: initialVenueManagementState })
    )
    .withHandler(SetVenueStateToCurrentVenue.TYPE, (state, newVenueState) =>
      update(state, { $set: newVenueState })
    )
    .withDefaultHandler((state) =>
      state ? state : initialVenueManagementState
    )
    .build();

// init
export const initialVenueManagementState: IVenueManagementState = {
  type: "",
  name: "",
  description: "",
  location_notes: "",
  facility_id: "",
  amenities: [],
  sportsOptionTypes: [],
  capacity: 1,
  hours: "",
  is_hidden_from_public: false,
  venue_portions: [],
  images: [],
  imagesToDelete: [],
  settings: {},
};

// selectors
const venueManagementStateSelector = Reselect.createSelector(
  (state: IAppState) => state,
  (state) => state.venueManagement
);
const venueDetailsSelector = Reselect.createSelector(
  (state: IAppState) => state.venueManagement,
  ({
    id,
    type,
    name,
    description,
    location_notes,
    amenities,
    sportsOptionTypes,
    capacity,
    facility_id,
    is_hidden_from_public,
    settings,
  }: VenueDetails) => {
    return {
      id,
      name,
      type,
      description,
      location_notes,
      amenities,
      sportsOptionTypes,
      capacity,
      facility_id,
      is_hidden_from_public,
      settings,
    };
  }
);
const venueImagesSelector = Reselect.createSelector(
  (state: IAppState) => state.venueManagement.images,
  (venueImages: MediaInfoInput[]) => {
    return venueImages;
  }
);
const venueDeleteImagesSelector = Reselect.createSelector(
  (state: IAppState) => state.venueManagement.imagesToDelete,
  (venueImages: UserFile[]) => {
    return venueImages;
  }
);
const venueHoursSelector = Reselect.createSelector(
  (state: IAppState) => state.venueManagement.hours,
  (venueHours: string) => {
    return venueHours ?? "";
  }
);
const venuePortionsSelector = Reselect.createSelector(
  (state: IAppState) => state.venueManagement.venue_portions,
  (venuePortions: ReservableArea[]) => {
    return venuePortions;
  }
);
const venueIsHiddenSelector = Reselect.createSelector(
  (state: IAppState) => !!state.venueManagement.is_hidden_from_public,
  (venueIsHidden: boolean) => venueIsHidden
);

// Selector Hooks
export const useVenueManagementStateSelector = (): IVenueManagementState =>
  useSelector(venueManagementStateSelector);

export const useVenueDetailsSelector = (): VenueDetails => {
  const venueDetails = useSelector(venueDetailsSelector);
  return venueDetails;
};
export const useVenueImagesSelector = () => {
  const venueImages = useSelector(venueImagesSelector);
  return venueImages;
};
export const useVenueDeleteImagesSelector = () => {
  const venueImages = useSelector(venueDeleteImagesSelector);
  return venueImages;
};
export const useVenueHoursSelector = () => {
  const venueHours = useSelector(venueHoursSelector);
  return venueHours;
};
export const useVenuePortionsSelector = () => {
  const venuePortions = useSelector(venuePortionsSelector);
  return venuePortions;
};
export const useVenueIsHiddenSelector = () => {
  const venueIsHidden = useSelector(venueIsHiddenSelector);
  return venueIsHidden;
};

export const useSetVenueDetails = () => {
  const dispatch = useDispatch();

  return (venueDetails: VenueDetails) =>
    dispatch(SetVenueDetails(venueDetails));
};

export const useSetVenueImages = () => {
  const dispatch = useDispatch();

  return (venueImages: MediaInfoInput[]) =>
    dispatch(SetVenueImages(venueImages));
};

export const useSetVenueDeleteImages = () => {
  const dispatch = useDispatch();

  return (imagesToDelete: UserFile[]) =>
    dispatch(SetVenueDeleteImages(imagesToDelete));
};
export const useSetVenueHours = () => {
  const dispatch = useDispatch();

  return (venueHours: string) => dispatch(SetVenueHours(venueHours));
};

export const useSetVenuePortions = () => {
  const dispatch = useDispatch();

  return (venuePortions: ReservableArea[]) =>
    dispatch(SetVenuePortions(venuePortions));
};

export const useSetVenueIsHidden = () => {
  const dispatch = useDispatch();

  return (venueIsHidden: boolean) =>
    dispatch(SetVenueIsHiddenFromPublic(venueIsHidden));
};

export const useSetVenueStateToCurrentVenue = () => {
  const dispatch = useDispatch();
  return (newVenueState: IVenueManagementState) =>
    dispatch(SetVenueStateToCurrentVenue(newVenueState));
};

export const useResetVenueManagementState = () => {
  const dispatch = useDispatch();
  return () => dispatch(ResetVenueState());
};
