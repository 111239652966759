import * as Sentry from "@sentry/nextjs";

export const sentryCaptureInvalidResponse = (
  error: string | Error,
  responseData: any
) => {
  Sentry.withScope((scope) => {
    scope.setExtras({ responseData });
    scope.captureException(error);
  });
};

export const sentryCaptureMessage = (message: string, extras?: any) => {
  Sentry.withScope((scope) => {
    if (extras !== undefined) {
      scope.setExtras(extras);
    }
    scope.captureMessage(message);
  });
};

export const sentryCaptureException = (e: unknown, extras?: any) => {
  Sentry.withScope((scope) => {
    if (extras !== undefined) {
      scope.setExtras(extras);
    }
    scope.captureException(e);
  });
};
