import { SnackbarKey } from "notistack";
import { useDispatch } from "react-redux";
import { defineAction, setWith, TypedReducer } from "redoodle";

import { IAlert, INotification } from "../constants/types";

// model
export interface INotificationsState {
  notifications: INotification[];
  alert?: IAlert;
}

// actions
export const EnqueueSnackbar = defineAction(
  "NOTIFICATIONS/ENQUEUE_SNACKBAR"
)<INotification>();
export const CloseSnackbar = defineAction(
  "NOTIFICATIONS/CLOSE_SNACKBAR"
)<SnackbarKey>();
export const RemoveSnackbar = defineAction(
  "NOTIFICATIONS/REMOVE_SNACKBAR"
)<number>();
export const ShowAlert = defineAction("NOTIFICATIONS/SHOW_ALERT")<IAlert>();
export const CloseAlert = defineAction("NOTIFICATIONS/CLOSE_ALERT")();

// reducer
export const notificationsReducer = TypedReducer.builder<INotificationsState>()
  .withHandler(EnqueueSnackbar.TYPE, (state, notification) =>
    setWith(state, {
      notifications: [
        ...state.notifications,
        {
          ...notification,
          key:
            notification.options?.key || new Date().getTime() + Math.random(),
        },
      ],
    })
  )
  .withHandler(ShowAlert.TYPE, (state, alert) => setWith(state, { alert }))
  .withHandler(CloseAlert.TYPE, (state) => setWith(state, { alert: undefined }))
  .withHandler(CloseSnackbar.TYPE, (state, key) =>
    setWith(state, {
      notifications: state.notifications.map((notification) => {
        return !key || notification.key === key
          ? { ...notification, dismissed: true }
          : { ...notification };
      }),
    })
  )
  .withHandler(RemoveSnackbar.TYPE, (state, key) =>
    setWith(state, {
      notifications: state.notifications.filter(
        (notification) => notification.key !== key
      ),
    })
  )
  .withDefaultHandler((state) => (state ? state : initialNotificationsState))
  .build();

// init
export const initialNotificationsState: INotificationsState = {
  notifications: [],
  alert: undefined,
};

// Selector hooks
export const useShowAlert = () => {
  const dispatch = useDispatch();
  return (alertPayload: IAlert) => dispatch(ShowAlert(alertPayload));
};
