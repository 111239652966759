import { ApolloQueryResult } from "@apollo/client";
import { LinkProps } from "next/link";
import { OptionsObject, SnackbarKey } from "notistack";
import { MessageFormatElement } from "react-intl";

import {
  CardReceiveType,
  Citizen_Inquiry_Types_Enum,
  Community_Event_Types_Enum,
  Exact,
  FacilityPropertiesFragment,
  FetchUserBookingsByIdQueryHookResult,
  FetchUserBookingsQuery,
  FetchUserBookingsQueryVariables,
  FormDefinitionAndRelatedPropertiesFragment,
  GetProcessedUserBookingPropertiesForIdQuery,
  GetProcessedUserBookingPropertiesInput,
  MediaInfoPropertiesFragment,
  MembershipBookingAndRelatedPropertiesFragment,
  Program_Types_Enum,
  ProgramsCheckoutMutation,
  Recurrence_Types_Enum,
  RegistrableUnitSubmissionValidationResultsFragment,
  RosterItemPropertiesFragment,
  SearchBookingsInput,
  SearchBookingsQuery,
  SearchBookingsQueryVariables,
  SiteMapFeature,
  TemporalEvent,
  UserPropertiesFragment,
  Venue_Portion_Split_Types_Enum,
  Venue_Portion_Types_Enum,
  VenuePortionInput,
  VenuePortionPrice,
} from "../api/generated";
import { UserAddressFormData } from "../utils/address_utils";
import { Day_Of_Week_Index } from "./constants";

export type OrganizationMessagesFormat =
  | Record<string, string>
  | Record<string, MessageFormatElement[]>;
export type OrganizationLanguage = "en" | "es";

export type HeightRatio =
  | "70%"
  | "80%"
  | "90%"
  | "100%"
  | "115%"
  | "120%"
  | "125%"
  | "130%"
  | "140%"
  | "150%";
export const FileExtensionLists = {
  video: ["m4v", "avi", "mpg", "mp4", "webm", "mov"],
  image: ["jpg", "gif", "bmp", "png", "jpeg"],
  pdf: ["pdf"],
};
export const ITEM_HEIGHT = 36;
export const MOBILE_ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const MENU_ITEMS = 6;
export interface AccountRoute {
  name: string;
  route: string;
}
export const protectedManagerRoutes: AccountRoute[] = [
  { name: "Manager Interface", route: "/manager" },
  { name: "Manager Reservations", route: "/manager/reservations" },
  { name: "Manager Settings", route: "/manager/settings" },
  { name: "Manager Users", route: "/manager/users" },
  { name: "Manager Overview", route: "/manager/overview" },
  { name: "Manager Settings", route: "/manager/calendar" },
  { name: "Manager Facilities", route: "/manager/facilities" },
  { name: "Manager Permits", route: "/manager/permits/manage" },
  { name: "Manager Permit Tasks", route: "/manager/my-tasks" },
  { name: "Manager Facilities", route: "/manager/facilities" },
];

export const protectedAccountRoutes: AccountRoute[] = [
  { name: "Account Permits", route: "/my-account/permits/" },
  { name: "Account Address", route: "/my-account/address" },
  { name: "Account Details", route: "/my-account/general" },
  { name: "Account Files", route: "/my-account/files" },
  { name: "Manage Account Permits", route: "/my-account/permits/manage" },
  { name: "Manage Account Tasks", route: "/my-account/permits/my-tasks" },
  { name: "Registration Checkout", route: "/registration/checkout" },
];

export type UserFile = {
  id: string;
  src: string;
  name: string;
  contentType: string;
  tempSignedUrl: string;
  originalFileName?: string;
  description?: string;
  alt: string;
};

export type SuccessiveQuestionFormData = Record<
  string,
  boolean | string | UserFile
>;

export interface IAlert {
  title: string;
  message: React.ReactNode;
  approveLabel?: string;
  onApprove: () => void;
  allowCancel?: boolean;
  onCancel?(): void;
  approveBackgroundColor?: string;
  fullWidth: boolean;
  cancelLabel?: string;
  disableClose?: boolean;
}
export interface PermitFormData {
  name: string;
  plateNumber: string;
  description: string;
  email: string;
  address_line_1: string;
  address_line_2?: string;
  locality: string;
  administrative_area: string;
  selectedDate: Date;
  postal_code: string;
  country: string;
  startDate?: string;
  endDate?: string;
}

export interface ReservationRecurrenceState {
  reservationRecurrenceType: Recurrence_Types_Enum;
  quantity: number; // Every 2 weeks, every 3 months, etc.
  weeklyRecurrenceDays: string[];
  ends: string;
}

export interface ReservationFormData {
  name: string;
  email: string;
  phone_number: string;
  address_line_1: string;
  description: string;
  special_requests?: string;
  quantity: number;
  address_line_2?: string;
  agency?: string;
  title?: string;
  locality: string;
  administrative_area: string;
  postal_code: string;
  country: string;
  metadata?: Record<string, string>;
  booking_options?: { [key: string]: boolean };
}
export interface CitizenInquiryType {
  title: string;
  description: string;
  image_url: string;
  timeToComplete: number;
  route: string;
  external?: boolean;
  type: Citizen_Inquiry_Types_Enum;
}
export interface CustomerServiceSurveyType {
  overallService: number;
  timelienessofService: number;
  staffKnowledge: number;
  staffCourtesy: number;
}
export interface CitizenInquiryFormData {
  name: string;
  email: string;
  inquiry: string;
  agency?: string;
  professional_title?: string;
  type: Citizen_Inquiry_Types_Enum;
  additionalComments?: string;
  administratorName?: string;
  departmentsInvolved: string[];
  facilitiesVisited: string[];
  address_line_1: string;
  address_line_2?: string;
  locality: string;
  administrative_area: string;
  selectedEntityType?: FacilityPropertiesFragment;
  selectedDate: string;
  postal_code: string;
  country: string;
  survey?: CustomerServiceSurveyType;
}
export interface INotification {
  key?: SnackbarKey;
  message: string;
  options?: OptionsObject;
  dismissed?: boolean;
}

export interface SubNavOption {
  title: string;
  route: string;
  isChildRouteActive?(currentPathname: string): boolean;
}

export interface NavOptionType {
  title: JSX.Element | string;
  route: string;
  external?: boolean;
  icon?: () => JSX.Element;
  featureFlag?: () => boolean;
  subNavHeader?: React.ReactNode;
  subNavOptions?: Array<SubNavOption>;
}

// Scheduler Types
export enum Scheduler_Views_Enum {
  Day = "Day",
  Week = "Week",
  Month = "Month",
}
export type SchedulerView = keyof typeof Scheduler_Views_Enum;
type SearchBookingsResult = NonNullable<SearchBookingsQuery["searchBookings"]>;
export type SearchBookingsItem = NonNullable<SearchBookingsResult[number]>;
export type SearchBookingsItemArray = SearchBookingsItem[];
export type SchedulerBooking = Pick<
  SearchBookingsItem,
  "info" | "locations"
> & {
  eventLocalized: SearchBookingsItem["events"][number];
};

// Reservation Edit Types
type ReservationFetchData = FetchUserBookingsByIdQueryHookResult["data"];
type ReservationFetchDataByPK =
  NonNullable<ReservationFetchData>["user_bookings_by_pk"];
export type Reservation = NonNullable<ReservationFetchDataByPK>;
export type SearchBookingsQueryRefetch = (
  variables?:
    | Partial<
        Exact<{
          input: SearchBookingsInput;
        }>
      >
    | undefined
) => Promise<ApolloQueryResult<SearchBookingsQuery>>;
export type SearchBookingsFilterOptions = Pick<
  SearchBookingsQueryVariables["input"],
  "facility_ids" | "venue_ids" | "venue_portion_ids" | "booking_statuses"
>;
export type BookingPropertiesForReservationRefetch = (
  variables?:
    | Partial<
        Exact<{
          input: GetProcessedUserBookingPropertiesInput;
        }>
      >
    | undefined
) => Promise<ApolloQueryResult<GetProcessedUserBookingPropertiesForIdQuery>>;

// ReservationHistoryForUser types
export type ReservationHiostoryForUserRefetch = (
  variables?: Partial<Exact<FetchUserBookingsQueryVariables>> | undefined
) => Promise<ApolloQueryResult<FetchUserBookingsQuery> | void>;

export enum FacilityCRUDWorkflowStep {
  AddFacilityDetails = "Add Facility Details",
  UploadMedia = "Upload Media",
  AddHoursOfOperation = "Add Hours of Operation",
  AddRulesAndRegulations = "Add Rules & Regulations",
  ReviewAndConfirm = "Review & Confirm",
}

export enum VenueCRUDWorkflowStep {
  AddVenueDetails = "Add Venue Details",
  UploadMedia = "Upload Media",
  SetReservableHours = "Set Reservable Hours",
  ConfigureReservableAreas = "Configure Reservable Areas",
  ReviewAndConfirm = "Review & Confirm",
}

export type DayOfWeekLowerCase = Lowercase<keyof typeof Day_Of_Week_Index>;

export type FacilityHoursOfOperation = {
  general: {
    start_date: string;
    end_date: string;
  };
  days_of_week_times: {
    [DayOfWeek in DayOfWeekLowerCase]: {
      start_time: string;
      end_time: string;
      is_closed?: boolean;
    };
  };
};

export type FacilityDayOfWeekHoursOfOperation =
  FacilityHoursOfOperation["days_of_week_times"][DayOfWeekLowerCase];

// Community Event Redux Types
export interface CommunityEventMapFeature {
  latitude: number;
  longitude: number;
  name: string;
}
export enum CommunityEventClassifications {
  PERFORMING_ARTS = "Performing Arts, Visual Arts, & Music",
  ENTREPRENEURIAL = "Entrepreneurial",
  FOOD_AND_DRINK = "Food & Drink",
  SPORTS_AND_FITNESS = "Sports & Fitness",
  EDUCATIONAL = "Educational",
  POLITICAL = "Political",
  CHARITY = "Charity",
  RELIGIOUS = "Religious",
  FAMILY_ORIENTED = "Family Oriented",
  OTHER = "Other (please describe)",
}

export enum CommunityEventAgeGroups {
  CHILDREN = "Children (under age 10)",
  YOUNG_ADULTS = "Young adults (ages 10-17)",
  ADULTS = "Adults (ages 18-64)",
  SENIORS = "Seniors (ages 65+)",
}
export interface SiteMap {
  file?: UserFile;
  features: SiteMapFeature[];
  name: string;
  description: string;
}

export interface AccountImageReturn {
  id: string;
  media_info: MediaInfoPropertiesFragment;
}

export interface CommunityEventVendorFormData {
  name: string;
  phone_number: string;
  type: string;
}

export enum RequiredDocumentIds {
  ADDITIONAL_ATTACHMENTS = "additional_attachments",
  PRODUCTION_SCHEDULE = "production_schedule",
  NON_PROFIT = "501c3-certificate",
  LIABILITY_WAIVER = "liability-waiver",
  RENTAL_AGREEMENT = "rental-agreement",
  BUILDING_PERMIT = "building-permit",
  MARKETING_PLAN = "marketing-plan",
  NEIGHBOR_ACKNOWLEDGEMENT = "neighbor-acknowledgement",
  ALLERGY_WAIVER = "allergy-waiver",
  PARENT_PERMISSION = "parent-permission",
  SITE_MAP = "site-map",
  CROWD_CONTROL_PLAN = "crowd-control-plan",
  TRAFFIC_PLAN = "traffic-plan",
  VENDOR_LIST = "vendor-list",
  PARKS_EQUIPMENT_FORM = "parks-equipment-form",
  LICENSE_AGREEMENT = "license-agreement",
  INFLATABLES_STAGE_DOCUMENTS = "inflatables-stage-documents",
  GENERATOR_DOCUMENTS = "generator-documents",
  AMUSEMENT_RIDE_DOCUMENTS = "amusement-ride-documents",
}

export enum ReportTypes {
  COMMUNITY_EVENT_APPLICATION = "community_event_application",
  COMMUNITY_EVENT_FINANCIAL = "community_event_financial",
  FACILITY_RESERVATION_EXPORT = "facility_reservation_export",
  FACILITY_RESERVATION_FINANCIAL = "facility_reservation_financial",
  PROGRAM_ACTIVITY_REPORT = "program_activity_report",
  NEWSLETTER_EXPORT = "newsletter_export",
  USER_ACCOUNT_EXPORT = "user_account_export",
  CAMPGROUND_AVAILABILITY_EXPORT = "campground_availability_export",
  INSTRUCTOR_INVOICE_REPORT = "instructor_invoice_report",
}

export enum SettingTypes {
  SUPPORT_EMAIL_CONFIGURATION = "support_email_configuration",
  GLOBAL_APPLICATION_NOTIFICATION = "global_application_notification",
  SPORTS_FIELD_ENABLE_AFTER = "sports_field_enable_after",
  MAXIMUM_DAYS_IN_ADVANCE_FOR_RESERVATION = "maximum_days_in_advance_for_reservation",
  GET_AVAILABLE_FACILITIES_BOOKING_STATUSES = "get_available_facilities_booking_statuses",
}

export interface PlatformRequiredDocument {
  name: string;
  id: RequiredDocumentIds;
  description: string;
  example?: string;
  example_text?: string;
  required: boolean;
  required_for_approval: boolean;
  required_for_block_party_approval?: boolean;
  admin_only: boolean;
  is_waiver?: boolean;
  signature_data_url?: string;
}

export interface CommunityEventLocation {
  facilities: string[];
  private_location?: string;
  start_date: string;
  start_time: string;
  end_date: string;
  end_time: string;
  setup_start_date: string;
  setup_start_time: string;
  setup_end_date: string;
  setup_end_time: string;
  teardown_start_date: string;
  teardown_start_time: string;
  teardown_end_date: string;
  teardown_end_time: string;
  num_attendees: number;
}

export interface SelectedCommunityEventType {
  type: Community_Event_Types_Enum;
  description?: string;
  bullets?: string[];
  image_url?: string;
  estimated_time_to_approve: number;
}

export interface CommunityEventApplicationEventDetailsFormData {
  event_name: string;
  event_description: string;
  other_tag_description?: string;
  age_group_description: string;
}

export interface CommunityEventApplicationOrganizerFormData {
  name: string;
  email: string;
  phone_number: string;
  agency: string;
  professional_title: string;
  address_line_1: string;
  address_line_2?: string;
  locality: string;
  administrative_area: string;
  postal_code: string;
  country: string;
  organization_ein?: string;
}

export interface CommunityEventApplicationData {
  selectedType: Community_Event_Types_Enum;
  organizerFormData: CommunityEventApplicationOrganizerFormData;
  registering_as_non_profit: boolean;
  registering_as_local_non_profit: boolean;
  eventDetailsFormData?: CommunityEventApplicationEventDetailsFormData;
  tags: CommunityEventClassifications[];
  age_groups: CommunityEventAgeGroups[];
  intent_to_distribute_food: boolean;
  intent_to_distribute_alcohol: boolean;
  internal_town_staff: boolean;
  generators_required: boolean;
  inflatables_required: boolean;
  stages_required: boolean;
  non_food_tangible_goods: boolean;
  amusement_rides_required: boolean;
  closing_mainstreet: boolean;
  additional_closures_other_than_mainstreet: boolean;
  municipally_supplied_water_required: boolean;
  municipally_supplied_electricity_required: boolean;
  trash_removal_required: boolean;
  temporary_restrooms_required: boolean;
  event_location: CommunityEventLocation;
  site_map?: SiteMap;
  vendors: CommunityEventVendorFormData[];
  required_documents: Record<string, UserFile[] | undefined>;
  entirely_held_on_private_property: boolean;
}

/* Venue CRUD Types */
export interface ReservationType {
  id: string;
  name: string;
  minBookingTime: string;
  maxBookingTime?: string;
  description?: string;
  type: Venue_Portion_Types_Enum | "N/A";
  split_type: Venue_Portion_Split_Types_Enum | "N/A";
  rates: string[];
}

export type ReservableArea = Pick<
  VenuePortionInput,
  "capacity" | "description" | "id" | "name" | "venue_id"
> & {
  reservationType?: ReservationType;
  type: Venue_Portion_Types_Enum | "N/A";
  split_type: Venue_Portion_Split_Types_Enum | "N/A";
  user_booking_info_id?: string;
};

export interface VenuePortionPriceInfo extends VenuePortionPrice {
  // set to true if the venue has only one price (for both residents and non-residents)
  isSinglePrice?: boolean;
}

export type RegistrableUnitSubmissionsInput = {
  isWaitlist: boolean;
  registrableUnitId: string;
  householdMemberId: string;
  organizationId: string;
  stripeCustomerId: string;
  stripePaymentMethodId: string;
  user: UserPropertiesFragment;
  userAddress: UserAddressFormData;
  submittedForms: FormSubmissionsByHouseholdMember;
};

export type RegistrableUnitSubmissionCartAndRelated = {
  cartItems: ClassCartItemData[];
  organizationId: string;
  stripeCustomerId: string;
  paymentMethodId: string;
  userData: UserPropertiesFragment;
  address: UserAddressFormData;
  formSubmissions: FormSubmissionsByHouseholdMember;
};

export interface MembershipFormSubmissions {
  [membershipFormId: string]: FormSubmission;
}

export interface MembershipCard {
  legacyIdentifier?: string;
  receiveType?: CardReceiveType;
  cardReceiveDetails?: string;
  isReplacement?: boolean;
  membershipPaymentAddOnIds?: string[];
}

export enum CardCreationOption {
  Mail = "Mail me a new Flash Parking",
  Pickup = "I will pick up a new Flash Parking card at 87 Wharf St",
}

export enum CardExistsType {
  Existing = "I have an existing Flash Parking card that I will use this season",
  Lost = "I lost my Flash Parking card",
  New = "I am a new pass user and need a Flash Parking card",
}

export interface MembershipCardFormState {
  cardExistsType?: CardExistsType;
  cardCreationOption?: CardCreationOption;
  cardIdentifier?: string;
  mailingAddress?: string;
}

export interface WeymouthMembershipCustomData {
  boatTrailerNumber?: string | null;
  licensePlateNumber?: string | null;
  commercialAddress?: UserAddressFormData | null;
}

export interface FormSubmissionsByHouseholdMember {
  [householdMemberId: string]: {
    [formId: string]: FormSubmission;
  };
}

export interface FormSubmission {
  newDocument?: UserFile;
  existingDocumentId?: string;
  disclaimerDataUrl?: string;
  membershipFormId?: string;
  venueFormId?: string;
  formId: string; // program form id
  formSubmissionId?: string;
  formDefinitionId: string;
  schemaFormDefinitionId?: string;
  schemaFormSubmissionId?: string;
  schemaFormData?: Record<any, any>;
}

export interface DocumentSubmission {
  file?: UserFile;
  existingDocumentId?: string;
  disclaimerDataUrl?: string;
  schemaFormData?: Record<any, any>;
}

export interface FormSubmissionDialogProps {
  open: boolean;
  form: FormDefinitionAndRelatedPropertiesFragment;
  extraHeaderContent?: React.ReactNode;
  existingFormSubmission: FormSubmission | null | undefined;
  existingDocuments?: MediaInfoPropertiesFragment[];
  onClose(): void;
  onSubmit(payload: DocumentSubmission): void;
  onRemove?(): void;
  loading?: boolean;
}

export type FieldVariant = "outlined" | "filled" | "standard";

export type AnchorLinkProps = LinkProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

export interface RosterMenuItemProps {
  rosterItem: RosterItemPropertiesFragment;
  refetchRosterItems(): void;
  closeMenu(): void;
}

export enum MembershipManagementPage {
  Registrations = "Membership registrations",
  Details = "Membership details",
  Disclaimers = "Disclaimers",
  Documents = "Documents",
}

export type UUID = string;

export interface CartItemToValidationError {
  [cartItemId: string]:
    | RegistrableUnitSubmissionValidationResultsFragment
    | undefined;
}

export type MembershipCardCheckboxesGroupState = {
  hide_memberships_with_activated_cards: boolean;
  hide_memberships_with_listed_card_identifiers: boolean;
};
export type SetMembershipCardCheckboxesGroupState = React.Dispatch<
  React.SetStateAction<MembershipCardCheckboxesGroupState>
>;
export interface MembershipFiltersButtonProps {
  disabled: boolean;
  membershipCardCheckboxesGroupState: MembershipCardCheckboxesGroupState;
  setMembershipCardCheckboxesGroupState: SetMembershipCardCheckboxesGroupState;
}

export enum CommunityEventFormStep {
  DetermineEventType = "determine-event-type",
  EventType = "event-type",
  OrganizerContactInfo = "organizer-contact-info",
  EventDetails = "event-details",
  LocationTimingAndAttendees = "location-timing-and-attendees",
  SiteMap = "site-map",
  VendorOrExhibitorInformation = "vendor-or-exhibitor-information",
  FormsWaiversRequiredDocuments = "forms-waivers-required-documents",
  ApplicationSummary = "application-summary",
  PayFeesAndConfirm = "pay-fees-and-confirm",
}

export enum CommunityEventTab {
  ApprovalsOverview = "approvals-overview",
  DepartmentApprovals = "department-approvals",
  ApplicationComments = "application-comments",
  AdministratorApplicationComments = "administrator-application-comments",
  OrganizerInformation = "organizer-information",
  EventDetails = "event-details",
  LocationsTimingAndAttendees = "locations-timing-and-attendees",
  VendorsAndSponsors = "vendors-and-sponsors",
  FormsAndDocuments = "forms-and-documents",
  FeesAndStatements = "fees-and-statements",
}

export interface CommunityEventStepDefinition {
  id: CommunityEventFormStep;
  title: string;
  checklistLabel: string;
}

export interface MembershipBookingMenuItemProps {
  membershipBooking: MembershipBookingAndRelatedPropertiesFragment;
  refetchMembershipBookings(): void;
  closeMenu(): void;
}

export enum MembershipBookingSelectionDialogType {
  ChoosePricingForMembershipTier = "ChoosePricingForMembershipTier",
  ChooseCardExistsType = "ChooseCardExistsType",
  ChooseCardCreationOption = "ChooseCardCreationOption",
  ChooseHouseholdMember = "ChooseHouseholdMember",
  MailingAddressForm = "MailingAddressForm",
  CardIdentifierForm = "CardIdentifierForm",
  Checkout = "Checkout",
}

export type MunicipalityFlagsForLinks = {
  isFacilityCrudFlagEnabled?: boolean;
  isMembershipsFlagEnabled?: boolean;
};

export interface RegistrableUnitCartData {
  id: string;
  programGroupId: string;
  registeringForWaitlist: boolean;
}

export interface BaseProgramCartItem {
  id: string;
  programId: string;
  showWaitlistCard: boolean;
}

export interface CampCartItemData extends BaseProgramCartItem {
  programType: Program_Types_Enum.Camp;
  selectedHouseholdMemberId: string;
  registrableUnits: Array<RegistrableUnitCartData>;
}

export interface ClassCartItemData extends BaseProgramCartItem {
  programType: Program_Types_Enum.Class;
  selectedHouseholdMemberIds: string[];
  registrableUnitId: string;
  programGroupId: string;
  registeringForWaitlist: boolean;
  validationError?: RegistrableUnitSubmissionValidationResultsFragment;
}
export interface TicketedEventRegistrableUnitCartData
  extends RegistrableUnitCartData {
  attendees_count: number;
}
export interface TicketedEventCartItemData extends BaseProgramCartItem {
  programType: Program_Types_Enum.TicketedEvent;
  selectedHouseholdMemberId: string;
  registrableUnits: Array<TicketedEventRegistrableUnitCartData>;
}

export type ProgramCartItemData =
  | ClassCartItemData
  | CampCartItemData
  | TicketedEventCartItemData;

// Type guards
export const isCampCartItem = (
  item: ProgramCartItemData
): item is CampCartItemData => item.programType === Program_Types_Enum.Camp;

export const isClassCartItem = (
  item: ProgramCartItemData
): item is ClassCartItemData => item.programType === Program_Types_Enum.Class;

export const isTicketedEventCartItem = (
  item: ProgramCartItemData
): item is TicketedEventCartItemData =>
  item.programType === Program_Types_Enum.TicketedEvent;

export type Maybe<T> = T | null | undefined;

export type ProgramsCheckoutErrors =
  ProgramsCheckoutMutation["programsCheckout"]["errors"];

export type BookingTimeRange = Pick<
  TemporalEvent,
  | "start_date"
  | "start_time"
  | "end_date"
  | "end_time"
  | "is_recurring"
  | "is_closed_all_day"
  | "is_full_day_event"
>;

export type ParseQRCodePayloadReturn = Record<string, string>;
