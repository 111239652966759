/**
 * This function is used to determine what civic organization a user is accessing from the URL.
 */
import { Programs_Bool_Exp } from "../api/generated";

export function getOrganizationFromUrl(
  host: string | undefined,
  pathname: string | undefined
): string | undefined {
  if (!host && !pathname) {
    return undefined;
  }
  let convertedHostName = host?.toLowerCase();
  let convertedPathName = pathname?.toLowerCase();
  if (
    convertedHostName?.includes("westminster") ||
    convertedPathName?.includes("westminster")
  ) {
    return "westminster";
  } else if (
    convertedHostName?.includes("mountainhouse") ||
    convertedPathName?.includes("mountainhouse")
  ) {
    return "mountainhouse";
  } else if (
    convertedHostName?.includes("santarosa") ||
    convertedPathName?.includes("santarosa")
  ) {
    return "santarosa";
  } else if (
    convertedHostName?.includes("weymouth") ||
    convertedPathName?.includes("weymouth") ||
    convertedHostName?.includes("weyrec") ||
    convertedPathName?.includes("weyrec")
  ) {
    return "weymouth";
  } else if (
    convertedHostName?.includes("castlepines") ||
    convertedPathName?.includes("castlepines")
  ) {
    return "castlepines";
  } else if (
    convertedHostName?.includes("sanantonio") ||
    convertedPathName?.includes("sanantonio") ||
    convertedHostName?.includes("smarter") ||
    convertedPathName?.includes("smarter")
  ) {
    return "sanantonio";
  } else if (
    convertedHostName?.includes("parker") ||
    convertedPathName?.includes("parker")
  ) {
    return "parker";
  } else if (
    convertedHostName?.includes("montereypark") ||
    convertedPathName?.includes("montereypark")
  ) {
    return "montereypark";
  } else if (
    convertedHostName?.includes("evanston") ||
    convertedPathName?.includes("evanston")
  ) {
    return "evanston";
  } else if (
    convertedHostName?.includes("metroparks") ||
    convertedPathName?.includes("metroparks")
  ) {
    return "metroparks";
  } else if (
    convertedHostName?.includes("crownmountain") ||
    convertedPathName?.includes("crown") ||
    convertedHostName?.includes("crown")
  ) {
    return "crownmountain";
  } else if (
    convertedHostName?.includes("ccmrd") ||
    convertedHostName?.includes("clearcreek") ||
    convertedPathName?.includes("ccmrd") ||
    convertedPathName?.includes("clearcreek")
  ) {
    return "ccmrd";
  } else if (
    convertedHostName?.includes("pima") ||
    convertedPathName?.includes("pima")
  ) {
    return "pima";
  } else if (
    convertedHostName?.includes("tulare") ||
    convertedPathName?.includes("tulare")
  ) {
    return "tulare";
  } else if (
    convertedHostName?.includes("sarasota") ||
    convertedPathName?.includes("sarasota") ||
    convertedHostName?.includes("letsplaysarasota") ||
    convertedPathName?.includes("letsplaysarasota")
  ) {
    return "sarasota";
  } else if (
    convertedHostName?.includes("caryl") ||
    convertedPathName?.includes("caryl")
  ) {
    return "kencaryl";
  } else if (
    convertedHostName?.includes("redwood") ||
    convertedPathName?.includes("redwood") ||
    convertedPathName?.includes("rwcpaf") ||
    convertedHostName?.includes("rwcpaf")
  ) {
    return "redwoodcity";
  } else if (
    convertedHostName?.includes("northville") ||
    convertedPathName?.includes("northville") ||
    convertedPathName?.includes("northvilleparksandrec") ||
    convertedHostName?.includes("northvilleparksandrec")
  ) {
    return "northville";
  }
  return undefined;
}

export async function fetchOrganizationIdForSlug(
  slug: string
): Promise<string> {
  const operationsDoc = `
  query GetOrganizationID {
    organizations(where: {slug: {_eq: ${slug}}}) {
      id
    }
  }
`;
  const result = await fetch(
    process.env.NEXT_PUBLIC_HASURA_SCHEMA_LOCATION as string,
    {
      method: "POST",
      body: JSON.stringify({
        query: operationsDoc,
        variables: {},
        operationName: "GetOrganizationID",
      }),
    }
  );
  const data = await result.json();
  return data?.data?.organizations?.[0]?.id as string;
}

export async function fetchFacilities(
  organizationId: string,
  facilityId?: string
): Promise<any[]> {
  const operationsDoc = `
  query GetFacilities($where: facilities_bool_exp) {
    facilities(where: $where) {
      amenities
      images
      address
      description
      history
      hours
      outside_food_and_alcohol
      parking
      reservation_information
      title
      type
      created_at
      updated_at
      id
      scorecard_link
      organization_id
      location_info_id
      is_hidden_from_public
      email_attachments
      booking_receipt_text
      cancellation_policy_document
      rules_and_regulations
      override_external_link
    }
  }
`;
  const where: any = {};
  if (facilityId) {
    where.id = { _eq: facilityId };
  }
  where.organization_id = { _eq: organizationId };
  const result = await fetch(
    process.env.NEXT_PUBLIC_HASURA_SCHEMA_LOCATION as string,
    {
      method: "POST",
      body: JSON.stringify({
        query: operationsDoc,
        variables: {
          where,
        },
        fetchPolicy: "network-only",
        operationName: "GetFacilities",
      }),
    }
  );
  const data = await result.json();
  return data?.data?.facilities ?? [];
}

export async function fetchPrograms(
  organizationId: string,
  programId?: string
): Promise<any[]> {
  const getProgramsQuery = `
    query GetPrograms($where: programs_bool_exp) {
      programs(where: $where) {
        is_public
        id
        type
        category
        images
        name
        description
        location_notes
        season_notes
        details_notes
        other_notes
        important_information
        min_age_years
        min_age_extra_months
        max_age_years
        max_age_extra_months
        min_grade
        max_grade
        is_high_volume
        capacity
        max_nonresident_capacity
        waitlist_max_size
        external_links
        prerequisites_notes
        admin_approval_required
        organization_id
        coordinator_first_name
        coordinator_last_name
        coordinator_email
        created_at
        updated_at
        max_attendees_per_checkout

        program_registration_schedules {
          id
          name
          description
          for_resident
          program_id
          override_registrable_unit_id
          temporal_event_id
          organization_id
          created_at
          updated_at

          associated_temporal_event {
            id
            start_date
            end_date
            start_time
            type
            end_time
            is_full_day_event
            is_recurring
            is_closed_all_day
          }
        }

        program_roster_schedules {
          associated_temporal_event {
            id
            start_date
            end_date
            start_time
            type
            end_time
            is_full_day_event
            is_recurring
            is_closed_all_day
          }
        }
      }
    }
  `;

  const where: Programs_Bool_Exp = {
    organization_id: {
      _eq: organizationId,
    },
  };

  if (programId) {
    where.id = { _eq: programId };
  }

  const result = await fetch(
    process.env.NEXT_PUBLIC_HASURA_SCHEMA_LOCATION as string,
    {
      method: "POST",
      body: JSON.stringify({
        query: getProgramsQuery,
        variables: {
          where,
        },
        fetchPolicy: "network-only",
        operationName: "GetPrograms",
      }),
    }
  );
  const data = await result.json();
  return data?.data?.programs ?? [];
}

export async function fetchMemberships(
  organizationId: string,
  membershipId?: string
): Promise<any[]> {
  const getMembershipsQuery = `
    query GetMemberships($where: memberships_bool_exp) {
      memberships(where: $where) {
        id
        organization_id
        max_registrations
        name
        description
        created_at
        updated_at
        is_public
        is_active
      }
    }
  `;

  const where: any = {
    organization_id: {
      _eq: organizationId,
    },
  };

  if (membershipId) {
    where.id = { _eq: membershipId };
  }

  const result = await fetch(
    process.env.NEXT_PUBLIC_HASURA_SCHEMA_LOCATION as string,
    {
      method: "POST",
      body: JSON.stringify({
        query: getMembershipsQuery,
        variables: {
          where,
        },
        fetchPolicy: "network-only",
        operationName: "GetMemberships",
      }),
    }
  );
  const data = await result.json();
  return data?.data?.memberships ?? [];
}

export const REVALIDATE_TIME_SECONDS = 10;
