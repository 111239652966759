import React from "react";

export function createProvider<T extends object>(name: string) {
  const context = React.createContext<T>(null as unknown as T);

  const Provider: React.FC<{ value: T }> = ({ value, children }) => {
    return <context.Provider value={value}>{children}</context.Provider>;
  };

  const useContext = () => {
    const value = React.useContext(context);
    if (!value) {
      throw new Error(
        `${name} constants must be used within a ${name} provider`
      );
    }
    return value;
  };

  const useValue = <K extends keyof T>(key: K): T[K] => {
    const obj = useContext();
    return obj[key];
  };

  return { Provider, useContext, useValue };
}
