import update from "immutability-helper";
import { defineAction, setWith, TypedReducer } from "redoodle";
import * as Reselect from "reselect";

import {
  reservationCols,
  SortOrder,
  userCols,
} from "../../constants/constants";
import { IAppState } from "../app";

export interface ISortState {
  sortOrder?: SortOrder;
  sortBy?: string;
  userSortOrder?: SortOrder;
  userSortBy?: typeof userCols[number];
}

// actions
export const ResetSortState = defineAction("APP/SORT/RESET_SORT_STATE")();
export const SetSortOrder = defineAction("APP/SORT/SET_SORT_ORDER")<
  SortOrder | undefined
>();
export const SetUserSortOrder = defineAction("APP/SORT/SET_USER_SORT_ORDER")<
  SortOrder | undefined
>();
export const SetSortBy = defineAction("APP/SORT/SET_SORT_BY")<
  typeof reservationCols[number] | undefined
>();
export const SetUserSortBy = defineAction("APP/SORT/SET_USER_SORT_BY")<
  typeof userCols[number] | undefined
>();

// reducer
export const sortReducer: any = TypedReducer.builder<ISortState>()
  .withHandler(SetSortOrder.TYPE, (state, reservationSortOrder) =>
    setWith(state, { sortOrder: reservationSortOrder })
  )
  .withHandler(SetUserSortOrder.TYPE, (state, userSortOrder) =>
    setWith(state, { userSortOrder })
  )
  .withHandler(SetSortBy.TYPE, (state, reservationSortBy) =>
    setWith(state, { sortBy: reservationSortBy })
  )
  .withHandler(SetUserSortBy.TYPE, (state, userSortBy) =>
    setWith(state, { userSortBy })
  )
  .withHandler(ResetSortState.TYPE, (state) =>
    update(state, { $set: initialSortState })
  )
  .withDefaultHandler((state) => (state ? state : initialSortState))
  .build();

// init
export const initialSortState: ISortState = {
  sortOrder: "desc",
  sortBy: "Date Submitted",
  userSortOrder: "desc",
  userSortBy: "First Name",
};

// selectors
export const SortOrderSelector = Reselect.createSelector(
  (state: IAppState) => state.sort.sortOrder,
  (sortOrder: SortOrder | undefined) => {
    return sortOrder;
  }
);

export const userSortOrderSelector = Reselect.createSelector(
  (state: IAppState) => state.sort.userSortOrder,
  (sortOrder: SortOrder | undefined) => {
    return sortOrder;
  }
);

export const SortBySelector = Reselect.createSelector(
  (state: IAppState) => state.sort.sortBy,
  (sortBy: string | undefined) => {
    return sortBy;
  }
);

export const userSortBySelector = Reselect.createSelector(
  (state: IAppState) => state.sort.userSortBy,
  (sortBy: typeof userCols[number] | undefined) => {
    return sortBy;
  }
);
