import { mapOrganizationSlugToBucketName } from "@kaizenlabs/organization-helpers";
import * as Sentry from "@sentry/nextjs";
import imageCompression from "browser-image-compression";
import { v4 as uuidv4 } from "uuid";

import {
  DeleteFileDocument,
  DeleteFileFromPublicBucketDocument,
  DeleteFileFromPublicBucketInput,
  DeleteFileFromPublicBucketQuery,
  DeleteFileFromPublicBucketQueryVariables,
  DeleteFileQuery,
  DeleteFileQueryVariables,
  GetPublicSignedUploadUrlDocument,
  GetPublicSignedUploadUrlQuery,
  GetPublicSignedUploadUrlQueryVariables,
  GetSignedDownloadUrlDocument,
  GetSignedDownloadUrlQuery,
  GetSignedDownloadUrlQueryVariables,
  GetSignedUploadUrlDocument,
  GetSignedUploadUrlQuery,
  GetSignedUploadUrlQueryVariables,
  MediaInfoInput,
  MediaInfoPropertiesFragment,
} from "../api/generated";
import { GENERIC_ERROR_MESSAGE } from "../constants/constants";
import { FileExtensionLists, UserFile } from "../constants/types";
import ApolloService from "../services/ApolloService";
import { isProdEnvironment } from "./feature_flag_utils";
import { MediaInfoData } from "./zod";

// Currently used for facilities and venues images only
// where we need to keep images in separate buckets based on environment
export function constructStorageUrl(
  slug: string,
  directoryName?: string
): string {
  const directory = directoryName ?? "";
  const bucketName = mapOrganizationSlugToBucketName(slug);
  const baseUrl = `https://storage.googleapis.com/${bucketName}`;

  const isFacilitiesOrVenues = ["facilities", "venues"].includes(directory);

  if (isProdEnvironment() && isFacilitiesOrVenues) {
    return `${baseUrl}/${directory}`;
  } else if (!isProdEnvironment() && isFacilitiesOrVenues) {
    return `${baseUrl}/dev/${directory}`;
  }
  return baseUrl;
}

export function getFileExtension(file: string | File): string | undefined {
  const parts =
    typeof file === "string" ? file.split(".") : file.name.split(".");
  if (parts) {
    return parts[parts.length - 1];
  }
  return undefined;
}

/**
 * A function which retrieves the extension from a file name or path, and determines if it is a valid
 * file of a specific type (image, video, etc...).
 */
export function isValidFileType(
  fName: string,
  fType: "image" | "video"
): boolean {
  const fileExtension = getFileExtension(fName);
  if (fileExtension) {
    return FileExtensionLists[fType].indexOf(fileExtension) > -1;
  }
  return false;
}

/**
 * This function uses a compression library to compress potentially large user images into much smaller,
 * more web friendly JPEG images before uploading them to Firebase. It only accepts JPG and PNG files as an input, so there
 * is logic in ImageUpload.tsx to prevent files of other types from being passed into this function. If the compression fails
 * for any reason, the original file is returned and an error is captured.
 */
export const compressAndConvertImageFile = async (
  file: File,
  contentType: string,
  uuid: string
): Promise<File> => {
  const compressionFormat = "jpeg";
  const options = {
    maxSizeMB: 0.85,
    fileType: contentType,
    useWebWorker: true,
    initialQuality: 0.75,
  };
  try {
    const compressedImage = await imageCompression(file, options);
    return new File([compressedImage], `${uuid}.${compressionFormat}`, {
      type: compressedImage.type,
    });
  } catch (e) {
    Sentry.captureMessage(`Image compression failed: ${file.name}`);
    console.error("Image compression failed", e);
  }
  return file;
};

/**
 * This function converts an externally hosted image source to a File object.
 */
export async function imageUrlToFile(src: string): Promise<File | undefined> {
  const response = await fetch(src);
  if (!response) {
    return undefined;
  }
  const blob = await response.blob();
  if (!blob) {
    return undefined;
  }
  return new File([blob], "image.jpg", { type: blob.type });
}

/**
 * This function communicates with the server to retrieve a signed URl for a specified interaction with Google Cloud Storage.
 * It supports delete, upload, and downloading, and will fetch the appropriate signed URL that the client can then GET or POST.
 */
export const getSignedUrlForFile = async (
  fileName: string,
  contentType: string,
  action: "read" | "write"
): Promise<string | undefined> => {
  switch (action) {
    case "read": {
      const { data } = await ApolloService.client.query<
        GetSignedDownloadUrlQuery,
        GetSignedDownloadUrlQueryVariables
      >({
        query: GetSignedDownloadUrlDocument,
        variables: {
          fileName,
          contentType,
        },
      });
      return data?.getSignedDownloadUrl?.signedUrl?.[0] ?? undefined;
    }
    case "write": {
      const { data } = await ApolloService.client.query<
        GetSignedUploadUrlQuery,
        GetSignedUploadUrlQueryVariables
      >({
        query: GetSignedUploadUrlDocument,
        variables: {
          fileName,
          contentType,
        },
      });
      return data?.getSignedUploadUrl?.signedUrl ?? undefined;
    }
    default:
      return undefined;
  }
};

export type PublicFileDirectoryPath = "facilities" | "programs" | "venues";

/**
 * This function communicates with the server to retrieve a public and signed URl for a specified interaction with a public Google Cloud Storage bucket.
 */
export const getPublicAndSignedUrlForFile = async (
  organization_slug: string,
  fileName: string,
  contentType: string,
  directoryPath?: PublicFileDirectoryPath // add more directory paths here as needed
): Promise<{ signed_url: string; public_url: string } | undefined> => {
  const { data } = await ApolloService.client.query<
    GetPublicSignedUploadUrlQuery,
    GetPublicSignedUploadUrlQueryVariables
  >({
    query: GetPublicSignedUploadUrlDocument,
    variables: {
      input: {
        slug: organization_slug,
        directoryPath,
        fileName,
        contentType,
      },
    },
  });
  const signed_url =
    data?.getUploadSignedUrlForPublicBucketForOrganizationSlug?.signed_url;
  const public_url =
    data?.getUploadSignedUrlForPublicBucketForOrganizationSlug?.public_url;
  if (!signed_url || !public_url) {
    return undefined;
  }
  return { signed_url, public_url };
};

/**
 * This function uploads a file to a signed URL on Google Cloud by issuing a PUT request to the signed URL.
 */
export const uploadFileToSignedUrl = async (
  signedUrl: string,
  file: File,
  contentType: string,
  originalName: string
): Promise<UserFile | undefined> => {
  const upload = await fetch(signedUrl, {
    method: "PUT",
    body: file,
    headers: { "Content-Type": contentType },
  });
  if (upload.ok) {
    let tempSignedViewUrl = await getSignedUrlForFile(
      file.name,
      contentType,
      "read"
    );

    const bucketName = isProdEnvironment()
      ? "cedar-images"
      : "cedar-images-dev";
    return {
      id: uuidv4(),
      src: `https://storage.googleapis.com/${bucketName}/${file.name}`,
      originalFileName: originalName,
      tempSignedUrl: tempSignedViewUrl,
      contentType,
      alt: file.name,
      name: file.name,
    } as UserFile;
  }
};

/**
 * This function uploads a file to a signed URL on Google Cloud by issuing a PUT request to the signed URL.
 */
export const uploadFileToPublicUrl = async (
  publicUrl: string,
  signedUrl: string,
  file: File,
  contentType: string,
  originalName: string
): Promise<UserFile | undefined> => {
  const upload = await fetch(signedUrl, {
    method: "PUT",
    body: file,
    headers: { "Content-Type": contentType },
  });
  if (upload.ok) {
    return {
      id: uuidv4(),
      src: publicUrl,
      originalFileName: originalName,
      tempSignedUrl: signedUrl,
      contentType,
      alt: file.name,
      name: file.name,
    } as UserFile;
  }
};

/**
 * This function delete a file on Google Cloud.
 */
export const deleteFile = async (fileName: string): Promise<boolean> => {
  const { data } = await ApolloService.client.query<
    DeleteFileQuery,
    DeleteFileQueryVariables
  >({
    query: DeleteFileDocument,
    variables: {
      fileName,
    },
  });
  return !!data?.deleteFile?.ok;
};

/**
 * This function deletes a file on a public Google Cloud bucket for an organization.
 */
export const deleteFileFromPublicBucket = async (
  input: DeleteFileFromPublicBucketInput
): Promise<boolean> => {
  const { data } = await ApolloService.client.query<
    DeleteFileFromPublicBucketQuery,
    DeleteFileFromPublicBucketQueryVariables
  >({
    query: DeleteFileFromPublicBucketDocument,
    variables: {
      input,
    },
  });
  return !!data?.deleteFileFromPublicBucket?.ok;
};

export const convertUploadedSignatureToUserFile = ({
  originalFileName,
  signatureDataUrl,
}: {
  originalFileName: string;
  signatureDataUrl: string;
}): UserFile => {
  const id = uuidv4();
  return {
    id,
    src: signatureDataUrl,
    originalFileName,
    tempSignedUrl: signatureDataUrl,
    contentType: "image/jpeg",
    alt: "Signature",
    name: id,
  } as UserFile;
};

export function convertUserFileToMediaInfoInput(
  file: UserFile,
  organization_id: string,
  source_relationship: string,
  index: number = 0
): MediaInfoInput {
  return {
    name: file.name,
    alt: file.alt,
    content_type: file.contentType,
    description: file.description,
    original_file_name: file.originalFileName,
    index: index,
    storage_url: decodeURIComponent(file.src),
    source_relationship,
    organization_id,
  };
}

export function convertUserFilesToMediaInfoInputs(
  user_files: UserFile[],
  organization_id: string,
  source_relationship: string
): MediaInfoInput[] {
  return user_files.map((file, index) =>
    convertUserFileToMediaInfoInput(
      file,
      organization_id,
      source_relationship,
      index
    )
  );
}

export const convertMediaInfoInputsToUserFiles = (
  mediaInfos: MediaInfoInput[]
): UserFile[] =>
  mediaInfos.map(
    ({
      storage_url,
      name,
      content_type,
      original_file_name,
      description,
      alt,
    }) => {
      const id = uuidv4();
      return {
        id,
        src: storage_url,
        tempSignedUrl: storage_url,
        name,
        contentType: content_type,
        alt: alt ?? "Signature",
        description: description ?? undefined,
        originalFileName: original_file_name ?? `${id}-signature`,
      };
    }
  );

// MediaInfoData is structure used in programs forms
export const convertMediaInfoDataToUserFile = (
  mediaInfoData: MediaInfoData
): UserFile => {
  const { id, url, originalFileName } = mediaInfoData;
  return {
    id,
    src: url,
    tempSignedUrl: url,
    name: originalFileName,
    contentType: "",
    alt: originalFileName ?? "Signature",
    description: originalFileName ?? undefined,
    originalFileName: originalFileName ?? `${id}-signature`,
  };
};

export async function prepareFileForUpload(file: File) {
  const fileExtension = getFileExtension(file);
  const isImage =
    fileExtension && FileExtensionLists.image.includes(fileExtension);
  const uuid = uuidv4();

  let preparedFile;

  if (isImage) {
    // If the file is an image, compress it down to a JPEG
    preparedFile = await compressAndConvertImageFile(file, "image/jpeg", uuid);
  } else {
    // Else create a new object for the file so we can give it a new unique name.
    // This must be done to avoid the possibility of two files having the
    // same name in GCP and overwriting one another.
    preparedFile = new File([file], `${uuid}.${fileExtension}`, {
      type: file.type,
    });
  }

  return preparedFile;
}

const MAX_FILE_SIZE = 15000000; // 15Mb

export interface UploadFilesPublicURLData {
  slug: string;
  directoryPath?: PublicFileDirectoryPath;
}

export interface UploadFilesParams<T> {
  files: T | null;
  acceptedFileFormats?: string[];
  publicUrlData?: UploadFilesPublicURLData;
  allowMultiple?: boolean;
  onStart?(filesToBeUploaded: T): void;
  onSuccess(newFiles: UserFile[]): void;
  onError(errorMessage: string): void;
}

export async function uploadFiles<T extends FileList | File[]>({
  files,
  acceptedFileFormats,
  publicUrlData,
  allowMultiple = true,
  onSuccess,
  onStart,
  onError,
}: UploadFilesParams<T>) {
  if (!files?.length) {
    return;
  }

  if (allowMultiple === false && files.length > 1) {
    onError("You can only upload one file for this input");
    return;
  }

  onStart?.(files);

  const finalUploadedFiles: UserFile[] = [];

  // Validate every file before trying to upload any of them
  for (const file of files) {
    if (file.size > MAX_FILE_SIZE) {
      onError(
        `Could not upload file "${file.name}" because it is greater than the allowed size of 15Mb.`
      );
      return;
    }

    const fileExtension = getFileExtension(file);

    if (
      !fileExtension ||
      (acceptedFileFormats &&
        !acceptedFileFormats.includes(fileExtension.toLowerCase()))
    ) {
      onError(
        `The file format of "${file.name}" with extension "${fileExtension}" is unsupported.`
      );
      return;
    }
  }

  for (const file of files) {
    // If validation was successful proceed with the upload
    try {
      const preparedFile = await prepareFileForUpload(file);
      let uploadedFile: UserFile | undefined;

      if (publicUrlData) {
        const publicAndSignedUrl = await getPublicAndSignedUrlForFile(
          publicUrlData.slug,
          preparedFile.name,
          preparedFile.type,
          publicUrlData.directoryPath
        );

        if (!publicAndSignedUrl) {
          throw new Error(
            `Failed to retrieve public and signed URL when uploading file named: ${file.name}`
          );
        }

        const signedUrl = publicAndSignedUrl.signed_url;
        const publicUrl = publicAndSignedUrl.public_url;

        uploadedFile = await uploadFileToPublicUrl(
          publicUrl,
          signedUrl,
          preparedFile,
          preparedFile.type,
          file.name
        );
      } else {
        const signedUrl = await getSignedUrlForFile(
          preparedFile.name,
          preparedFile.type,
          "write"
        );

        if (!signedUrl) {
          throw new Error(
            `Failed to retrieve signed URL when uploading file named: ${file.name}`
          );
        }

        uploadedFile = await uploadFileToSignedUrl(
          signedUrl,
          preparedFile,
          preparedFile.type,
          file.name
        );
      }

      if (!uploadedFile) {
        throw new Error(`Failed to upload file named: ${file.name}`);
      }

      finalUploadedFiles.push(uploadedFile);
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      onError(GENERIC_ERROR_MESSAGE);
      return;
    }
  }

  onSuccess(finalUploadedFiles);
}

export async function openMediaInfoInNewTab(
  mediaInfo: MediaInfoPropertiesFragment
) {
  const url = await getSignedUrlForFile(
    mediaInfo.name,
    mediaInfo.content_type,
    "read"
  );

  window.open(url, "blank");
}

export function getDisplayNameForMediaInfo(
  mediaInfo?: MediaInfoPropertiesFragment
) {
  return (
    mediaInfo?.original_file_name ??
    mediaInfo?.name ??
    mediaInfo?.description ??
    "Unknown File Name"
  );
}

export const getUserFileForFileUrl = async (
  url: string,
  contentType: string
): Promise<UserFile | undefined> => {
  const fileName = decodeURIComponent(url).split("/").pop();
  if (!fileName) {
    return;
  }
  const id = fileName.split(".")[0] ?? fileName;
  const signedUrl = await getSignedUrlForFile(fileName, contentType, "read");
  if (!signedUrl) {
    return;
  }
  return {
    id,
    src: url,
    originalFileName: fileName,
    tempSignedUrl: signedUrl,
    contentType: contentType,
    alt: fileName,
    name: fileName,
  };
};
