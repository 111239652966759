import update from "immutability-helper";
import { defineAction, setWith, TypedReducer } from "redoodle";
import * as Reselect from "reselect";

import { mountainHousePermitReasons } from "../constants/mountainhouse/constants";
import { PermitFormData, UserFile } from "../constants/types";
import { IAppState } from "./app";

// model
export interface IPermitState {
  activeStep: number;
  permitFiles: UserFile[];
  reason?: string;
  permitFormData?: PermitFormData;
}

// actions
export const ResetPermitState = defineAction("APP/PERMIT/RESET_PERMIT_STATE")();
export const SetPermitFiles = defineAction("APP/PERMIT/SET_FILES")<
  UserFile[]
>();
export const AddPermitFile = defineAction("APP/PERMIT/ADD_FILE")<UserFile[]>();
export const SetActiveStep = defineAction(
  "APP/PERMIT/SET_ACTIVE_STEP"
)<number>();
export const SetPermitReason = defineAction(
  "APP/PERMIT/SET_PERMIT_REASON"
)<string>();
export const SetPermitFormData = defineAction("PERMIT/SET_PERMIT_FORM_DATA")<
  PermitFormData | undefined
>();
export const RemovePermitFile = defineAction("PERMIT/REMOVE_FILE")<number>();

// reducer
export const permitReducer: any = TypedReducer.builder<IPermitState>()
  .withHandler(SetPermitFormData.TYPE, (state, permitFormData) =>
    setWith(state, { permitFormData })
  )
  .withHandler(SetActiveStep.TYPE, (state, activeStep) =>
    setWith(state, { activeStep })
  )
  .withHandler(SetPermitReason.TYPE, (state, reason) =>
    setWith(state, { reason })
  )
  .withHandler(SetPermitFiles.TYPE, (state, permitImages) =>
    setWith(state, { permitFiles: permitImages })
  )
  .withHandler(AddPermitFile.TYPE, (state, permitImages) =>
    update(state, { permitFiles: { $push: permitImages } })
  )
  .withHandler(RemovePermitFile.TYPE, (state, index) =>
    update(state, { permitFiles: { $splice: [[index, 1]] } })
  )
  .withHandler(ResetPermitState.TYPE, (state) =>
    update(state, { $set: initialPermitState })
  )

  .withDefaultHandler((state) => (state ? state : initialPermitState))
  .build();

// init
export const initialPermitState: IPermitState = {
  permitFiles: [],
  reason: mountainHousePermitReasons[0],
  permitFormData: undefined,
  activeStep: 0,
};

// selectors
export const permitFormDataSelector = Reselect.createSelector(
  (state: IAppState) => state.permit.permitFormData,
  (permitFormData: PermitFormData | undefined) => {
    return permitFormData;
  }
);

export const permitActiveStepSelector = Reselect.createSelector(
  (state: IAppState) => state.permit.activeStep,
  (activeStep: number) => {
    return activeStep;
  }
);

export const permitReasonSelector = Reselect.createSelector(
  (state: IAppState) => state.permit.reason,
  (reason: string | undefined) => {
    return reason;
  }
);

export const permitFilesSelector = Reselect.createSelector(
  (state: IAppState) => state.permit.permitFiles,
  (permitFiles: UserFile[]) => {
    return permitFiles;
  }
);
