import { useDispatch, useSelector } from "react-redux";
import { defineAction, setWith, TypedReducer } from "redoodle";
import * as Reselect from "reselect";

import { IAppState } from "./app";

// model
export interface IAuthState {
  token?: string;
  userId?: string;
  isManager: boolean;
  loading: boolean;
}

// actions
export const SetAuthToken = defineAction("AUTH/SET_TOKEN")<
  string | undefined
>();
export const SetAuthLoading = defineAction("AUTH/SET_LOADING")<boolean>();
export const SetUserId = defineAction("AUTH/SET_USER_ID")<string | undefined>();
export const SetIsManager = defineAction("AUTH/SET_IS_MANAGER")<boolean>();
// reducer
export const authReducer = TypedReducer.builder<IAuthState>()
  .withHandler(SetAuthToken.TYPE, (state, token) => setWith(state, { token }))
  .withHandler(SetIsManager.TYPE, (state, isManager) =>
    setWith(state, { isManager })
  )
  .withHandler(SetUserId.TYPE, (state, userId) => setWith(state, { userId }))
  .withHandler(SetAuthLoading.TYPE, (state, loading) =>
    setWith(state, { loading })
  )
  .withDefaultHandler((state) => (state ? state : initialAuthState))
  .build();

// init
export const initialAuthState: IAuthState = {
  isManager: false,
  loading: true,
};

// selectors
export const authStateSelector = Reselect.createSelector(
  (state: IAppState) => state,
  ({ auth }) => auth
);

export const isManagerSelector = Reselect.createSelector(
  (state: IAppState) => state.auth.isManager,
  (isManager: boolean) => {
    return isManager;
  }
);

export const userIdSelector = Reselect.createSelector(
  (state: IAppState) => state.auth.userId,
  (userId: string | undefined) => {
    return userId;
  }
);

export const authLoadingSelector = Reselect.createSelector(
  (state: IAppState) => state.auth.loading,
  (loading: boolean) => {
    return loading;
  }
);

// Selector hooks
export const useAuthStateSelector = () => useSelector(authStateSelector);
export const useUserIdSelector = () => useSelector(userIdSelector);
export const useSetAuthLoading = () => {
  const dispatch = useDispatch();
  return (isLoading: boolean) => dispatch(SetAuthLoading(isLoading));
};
export const useIsManagerSelector = () => useSelector(isManagerSelector);

export const useSetAuthToken = () => {
  const dispatch = useDispatch();
  return (token: string) => dispatch(SetAuthToken(token));
};
