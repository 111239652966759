import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { OrganizationMessagesFormat } from "../constants/types";
import en from "../lang/compiled-lang/en.json";
import es from "../lang/compiled-lang/es.json";
import { localeLanguageSelector } from "../store/locale";

/**
 * This hook is responsible for fetching organization specific language files. This hook uses the localeLanguage redux variable
 * that is toggled by the user in LanguageSwitcher.
 */
export const useOrganizationCompiledLanguageMessages =
  (): OrganizationMessagesFormat => {
    const [organizationMessages, setOrganizationMessages] =
      useState<OrganizationMessagesFormat>({});
    const localeLanguage = useSelector(localeLanguageSelector);
    useEffect(() => {
      switch (localeLanguage) {
        case "en":
          setOrganizationMessages(en);
          break;
        case "es":
          setOrganizationMessages(es);
          break;
        default:
          setOrganizationMessages(en);
      }
    }, [localeLanguage]);
    return organizationMessages;
  };
