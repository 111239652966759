import update from "immutability-helper";
import { useDispatch, useSelector } from "react-redux";
import { defineAction, setWith, TypedReducer } from "redoodle";
import * as Reselect from "reselect";

import {
  Facilities,
  Facilities_Types_Enum,
  FacilityType,
} from "../api/generated";
import { DEFAULT_COUNTRY_CODE } from "../constants/constants";
import { UserFile } from "../constants/types";
import { MediaInfoData } from "../utils/zod";
import { IAppState } from "./app";

// model
export type IFacilityManagementState = Pick<
  Facilities,
  | "title"
  | "description"
  | "parking"
  | "hours"
  | "is_hidden_from_public"
  | "settings"
> & {
  id?: string; // only present for edit workflow since id will not exist for create workflow
  address_line_1: string;
  address_line_2?: string;
  locality: string;
  administrative_area: string;
  postal_code: string;
  country: string;
  amenities: string[];
  images: string[];
  imagesToDelete: UserFile[];
  rules_and_regulations: string[];
  emailAttachments: MediaInfoData[];
  cancellationPolicyDocuments: MediaInfoData[];
  filesToDelete: MediaInfoData[];
  type: FacilityType | Facilities_Types_Enum | "";
  history: string;
  outside_food_and_alcohol: string;
  is_hidden_from_public: boolean | undefined | null;
};
type FacilityDetails = Pick<
  IFacilityManagementState,
  | "id"
  | "title"
  | "description"
  | "address_line_1"
  | "address_line_2"
  | "locality"
  | "administrative_area"
  | "postal_code"
  | "country"
  | "parking"
  | "type"
  | "amenities"
  | "history"
  | "outside_food_and_alcohol"
  | "is_hidden_from_public"
  | "settings"
>;

// actions
const ResetFacilityState = defineAction(
  "APP/FACILITY_MANAGEMENT/RESET_FACILITY_MANAGEMENT_STATE"
)();
const SetFacilityDetails = defineAction(
  "APP/FACILITY_MANAGEMENT/SET_DETAILS"
)<FacilityDetails>();
const SetFacilityImages = defineAction("APP/FACILITY_MANAGEMENT/SET_IMAGES")<
  string[]
>();
const AddFacilityImage = defineAction("APP/FACILITY_MANAGEMENT/ADD_IMAGE")<
  string[]
>();
const RemoveFacilityImage = defineAction(
  "APP/FACILITY_MANAGEMENT/REMOVE_IMAGE"
)<number>();
const SetFacilityImagesToDelete = defineAction(
  "APP/FACILITY_MANAGEMENT/SET_DELETE_IMAGE_FROM_PUBLIC_BUCKET"
)<UserFile[]>();
const SetFacilityHours = defineAction(
  "APP/FACILITY_MANAGEMENT/SET_HOURS"
)<string>();
const SetFacilityRules = defineAction("APP/FACILITY_MANAGEMENT/SET_RULES")<
  string[]
>();
const SetFacilityEmailAttachments = defineAction(
  "APP/FACILITY_MANAGEMENT/SET_EMAIL_ATTACHMENTS"
)<MediaInfoData[]>();
const SetFacilityCancellationPolicyDocuments = defineAction(
  "APP/FACILITY_MANAGEMENT/SET_CANCELLATION_POLICY_DOCUMENTS"
)<MediaInfoData[]>();
const AddFilesToFacilityFilesToDelete = defineAction(
  "APP/FACILITY_MANAGEMENT/ADD_FILE_TO_FILES_TO_DELETE"
)<MediaInfoData[]>();
const SetFacilityIsHiddenFromPublic = defineAction(
  "APP/FACILITY_MANAGEMENT/SET_IS_HIDDEN"
)<boolean>();
const SetFacilityStateToCurrentFacility = defineAction(
  "APP/FACILITY_MANAGEMENT/SET_FACILITY_STATE_TO_CURRENT_FACILITY"
)<IFacilityManagementState>();

// reducer
export const facilityManagementReducer: any =
  TypedReducer.builder<IFacilityManagementState>()
    .withHandler(
      SetFacilityDetails.TYPE,
      (
        state,
        {
          title,
          type,
          address_line_1,
          address_line_2,
          locality,
          administrative_area,
          postal_code,
          country,
          description,
          parking,
          history,
          outside_food_and_alcohol,
          amenities,
          is_hidden_from_public,
          settings,
        }
      ) =>
        setWith(state, {
          title,
          type,
          address_line_1,
          address_line_2,
          locality,
          administrative_area,
          postal_code,
          country,
          description,
          parking,
          history,
          outside_food_and_alcohol,
          amenities,
          is_hidden_from_public,
          settings,
        })
    )
    .withHandler(SetFacilityImages.TYPE, (state, facilityImages) =>
      setWith(state, { images: facilityImages })
    )
    .withHandler(AddFacilityImage.TYPE, (state, facilityImages) =>
      update(state, { images: { $push: facilityImages } })
    )
    .withHandler(SetFacilityImagesToDelete.TYPE, (state, facilityImages) =>
      setWith(state, { imagesToDelete: facilityImages })
    )
    .withHandler(RemoveFacilityImage.TYPE, (state, index) =>
      update(state, { images: { $splice: [[index, 1]] } })
    )
    .withHandler(SetFacilityHours.TYPE, (state, facilityHours) =>
      setWith(state, { hours: facilityHours })
    )
    .withHandler(SetFacilityRules.TYPE, (state, facilityRules) =>
      setWith(state, { rules_and_regulations: facilityRules })
    )
    .withHandler(SetFacilityEmailAttachments.TYPE, (state, emailAttachments) =>
      setWith(state, { emailAttachments })
    )
    .withHandler(
      SetFacilityCancellationPolicyDocuments.TYPE,
      (state, cancellationPolicyDocuments) =>
        setWith(state, { cancellationPolicyDocuments })
    )
    .withHandler(AddFilesToFacilityFilesToDelete.TYPE, (state, filesToDelete) =>
      update(state, { filesToDelete: { $push: filesToDelete } })
    )
    .withHandler(
      SetFacilityIsHiddenFromPublic.TYPE,
      (state, facilityIsHidden) =>
        setWith(state, { is_hidden_from_public: facilityIsHidden })
    )
    .withHandler(ResetFacilityState.TYPE, (state) =>
      update(state, { $set: initialFacilityManagementState })
    )
    .withHandler(
      SetFacilityStateToCurrentFacility.TYPE,
      (state, newFacilityState) => update(state, { $set: newFacilityState })
    )
    .withDefaultHandler((state) =>
      state ? state : initialFacilityManagementState
    )
    .build();

// init
export const initialFacilityManagementState: IFacilityManagementState = {
  title: "",
  type: "",
  description: "",
  address_line_1: "",
  locality: "",
  administrative_area: "",
  postal_code: "",
  country: DEFAULT_COUNTRY_CODE,
  parking: "",
  amenities: [],
  images: [],
  imagesToDelete: [],
  hours: "",
  rules_and_regulations: [],
  is_hidden_from_public: false,
  history: "",
  outside_food_and_alcohol: "",
  settings: {},
  emailAttachments: [],
  cancellationPolicyDocuments: [],
  filesToDelete: [],
};

// selectors
const facilityManagementStateSelector = Reselect.createSelector(
  (state: IAppState) => state,
  (state) => state.facilityManagement
);
const facilityDetailsSelector = Reselect.createSelector(
  (state: IAppState) => state.facilityManagement,
  ({
    id,
    title,
    type,
    description,
    address_line_1,
    address_line_2,
    locality,
    administrative_area,
    postal_code,
    parking,
    country,
    amenities,
    history,
    outside_food_and_alcohol,
    is_hidden_from_public,
    settings,
  }) => {
    return {
      id,
      title,
      type,
      description,
      address_line_1,
      address_line_2,
      locality,
      administrative_area,
      postal_code,
      country,
      parking,
      amenities,
      history,
      outside_food_and_alcohol,
      is_hidden_from_public,
      settings,
    };
  }
);
const facilityImagesSelector = Reselect.createSelector(
  (state: IAppState) => state.facilityManagement.images,
  (facilityImages: string[]) => {
    return facilityImages;
  }
);
const facilityImagesToDeleteSelector = Reselect.createSelector(
  (state: IAppState) => state.facilityManagement.imagesToDelete,
  (imagesToDelete: UserFile[]) => {
    return imagesToDelete;
  }
);
const facilityHoursSelector = Reselect.createSelector(
  (state: IAppState) => state.facilityManagement.hours,
  (facilityHours: string) => {
    return facilityHours;
  }
);
const facilityRulesSelector = Reselect.createSelector(
  (state: IAppState) => state.facilityManagement.rules_and_regulations,
  (facilityRules: string[]) => {
    return facilityRules;
  }
);
const facilityEmailAttachmentsSelector = Reselect.createSelector(
  (state: IAppState) => state.facilityManagement.emailAttachments,
  (emailAttachments: MediaInfoData[]) => {
    return emailAttachments;
  }
);
const facilityCancellationPolicyDocuments = Reselect.createSelector(
  (state: IAppState) => state.facilityManagement.cancellationPolicyDocuments,
  (cancellationPolicyDocuments: MediaInfoData[]) => {
    return cancellationPolicyDocuments;
  }
);
const facilityIsHiddenSelector = Reselect.createSelector(
  (state: IAppState) => !!state.facilityManagement.is_hidden_from_public,
  (facilityIsHidden: boolean) => facilityIsHidden
);

// Selector Hooks
export const useFacilityManagementStateSelector =
  (): IFacilityManagementState => useSelector(facilityManagementStateSelector);
export const useFacilityDetailsSelector = (): FacilityDetails => {
  const facilityDetails = useSelector(facilityDetailsSelector);
  return facilityDetails;
};
export const useFacilityImagesSelector = () => {
  const facilityImages = useSelector(facilityImagesSelector);
  return facilityImages;
};
export const useFacilityImagesToDeleteSelector = () => {
  const imagesToDelete = useSelector(facilityImagesToDeleteSelector);
  return imagesToDelete;
};
export const useFacilityHoursSelector = () => {
  const facilityHours = useSelector(facilityHoursSelector);
  return facilityHours;
};
export const useFacilityRulesSelector = () => {
  const facilityRules = useSelector(facilityRulesSelector);
  return facilityRules;
};
export const useFacilityEmailAttachmentsSelector = () => {
  const facilityEmailAttachments = useSelector(
    facilityEmailAttachmentsSelector
  );
  return facilityEmailAttachments;
};
export const useFacilityCancellationPolicyDocumentsSelector = () => {
  const cancellationPolicyDocuments = useSelector(
    facilityCancellationPolicyDocuments
  );
  return cancellationPolicyDocuments;
};
export const useFacilityIsHiddenSelector = () => {
  const facilityIsHidden = useSelector(facilityIsHiddenSelector);
  return facilityIsHidden;
};

export const useSetFacilityDetails = () => {
  const dispatch = useDispatch();

  return (facilityDetails: FacilityDetails) =>
    dispatch(SetFacilityDetails(facilityDetails));
};

export const useSetFacilityImages = () => {
  const dispatch = useDispatch();

  return (facilityImages: string[]) =>
    dispatch(SetFacilityImages(facilityImages));
};

export const useSetImagesToDelete = () => {
  const dispatch = useDispatch();

  return (imagesToDelete: UserFile[]) =>
    dispatch(SetFacilityImagesToDelete(imagesToDelete));
};

export const useSetFacilityHours = () => {
  const dispatch = useDispatch();

  return (facilityHours: string) => dispatch(SetFacilityHours(facilityHours));
};

export const useSetFacilityRules = () => {
  const dispatch = useDispatch();

  return (facilityRules: string[]) => dispatch(SetFacilityRules(facilityRules));
};

export const useSetFacilityEmailAttachments = () => {
  const dispatch = useDispatch();

  return (facilityEmailAttachments: MediaInfoData[]) =>
    dispatch(SetFacilityEmailAttachments(facilityEmailAttachments));
};

export const useSetFacilityCancellationPolicyDocuments = () => {
  const dispatch = useDispatch();

  return (cancellationPolicyDocuments: MediaInfoData[]) =>
    dispatch(
      SetFacilityCancellationPolicyDocuments(cancellationPolicyDocuments)
    );
};
export const useAddFilesToFacilityFilesToDelete = () => {
  const dispatch = useDispatch();

  return (filesToDelete: MediaInfoData[]) =>
    dispatch(AddFilesToFacilityFilesToDelete(filesToDelete));
};
export const useSetFacilityIsHidden = () => {
  const dispatch = useDispatch();

  return (facilityIsHidden: boolean) =>
    dispatch(SetFacilityIsHiddenFromPublic(facilityIsHidden));
};

export const useSetFacilityStateToCurrentFacility = () => {
  const dispatch = useDispatch();
  return (newFacilityState: IFacilityManagementState) =>
    dispatch(SetFacilityStateToCurrentFacility(newFacilityState));
};

export const useResetFacilityManagementState = () => {
  const dispatch = useDispatch();
  return () => dispatch(ResetFacilityState());
};
