import update from "immutability-helper";
import isEqual from "lodash.isequal";
import { useDispatch, useSelector } from "react-redux";
import { defineAction, setWith, TypedReducer } from "redoodle";
import * as Reselect from "reselect";

import {
  Booking_Statuses_Enum,
  Completion_Status_Enum,
  Facilities_Types_Enum,
  FacilityPropertiesFragment,
  FacilityType,
  Special_Events_Response_Task_Types_Enum,
  Special_Events_Responses_Tasks_Order_By,
  Venue_Types_Enum,
  VenuePropertiesFragment,
} from "../api/generated";
import { cityDepartments } from "../constants/constants";
import { IAppState } from "./app";

// model
export interface IFilterState {
  status?: string[];
  departmentsInvolved?: string[];
  facilities: FacilityPropertiesFragment[];
  venues: VenuePropertiesFragment[];
  facilityTypes: (FacilityType | Facilities_Types_Enum)[];
  venueTypes: Venue_Types_Enum[];
  type?: string[];
  tag?: string[];
  bookingStatus?: Booking_Statuses_Enum[];
  completionStatus?: Completion_Status_Enum[];
  onlyShowMyReservationsOnPublicCalendar?: boolean;
  paymentStatus?: string[];
  adminApprovalStatus?: string[];
  date: [string | null, string | null];
  assignee?: string[];
  order?: string;
  communityEventTaskOrder?: Special_Events_Responses_Tasks_Order_By;
  communityEventTaskType?: Special_Events_Response_Task_Types_Enum[];
  communityEventTasksAssignedToMe?: boolean;
  communityEventTasksAssignedByMe?: boolean;
  search?: string;
}

// actions
export const ResetFilterState = defineAction("APP/FILTER/RESET_FILTER_STATE")();
export const SetStatusFilter = defineAction("APP/FILTER/SET_STATUS_FILTER")<
  string[] | undefined
>();
export const SetFacilityFilter = defineAction("APP/FILTER/SET_FACILITY_FILTER")<
  FacilityPropertiesFragment[]
>();
export const SetFacilityTypesFilter = defineAction(
  "APP/FILTER/SET_FACILITY_TYPE_FILTER"
)<(FacilityType | Facilities_Types_Enum)[]>();
export const SetVenueTypesFilter = defineAction(
  "APP/FILTER/SET_VENUE_TYPEs_FILTER`"
)<Venue_Types_Enum[]>();
export const SetVenueFilter = defineAction("APP/FILTER/SET_VENUE_FILTER")<
  VenuePropertiesFragment[]
>();
export const SetBookingStatusFilter = defineAction(
  "APP/FILTER/SET_BOOKING_STATUS_FILTER"
)<Booking_Statuses_Enum[] | undefined>();

export const SetCommunityEventTasksAssignedToMe = defineAction(
  "APP/FILTER/SET_COMMUNITY_EVENT_TASKS_ASSIGNED_TO_ME"
)<boolean | undefined>();
export const SetCommunityEventTasksAssignedByMe = defineAction(
  "APP/FILTER/SET_COMMUNITY_EVENT_TASKS_ASSIGNED_BY_ME"
)<boolean | undefined>();
export const SetOnlyShowMyReservationsPublicCalendar = defineAction(
  "APP/FILTER/SET_ONLY_SHOW_MY_RESERVATIONS_PUBLIC_CALENDAR"
)<boolean | undefined>();
export const SetCompletionStatusFilter = defineAction(
  "APP/FILTER/SET_COMPLETION_STATUS_FILTER"
)<Completion_Status_Enum[] | undefined>();
export const SetCommunityEventTaskOrderFilter = defineAction(
  "APP/FILTER/SET_COMMUNITY_EVENT_TASK_ORDER_FILTER"
)<Special_Events_Responses_Tasks_Order_By>();
export const SetCommunityEventTaskTypeFilter = defineAction(
  "APP/FILTER/SET_COMMUNITY_EVENT_TASK_TYPE_FILTER"
)<Special_Events_Response_Task_Types_Enum[] | undefined>();
export const SetPaymentStatusFilter = defineAction(
  "APP/FILTER/SET_PAYMENT_STATUS_FILTER"
)<string[] | undefined>();
export const SetDateFilter = defineAction("APP/FILTER/SET_DATE_FILTER")<
  [string | null, string | null]
>();
export const SetAdminApprovalStatusFilter = defineAction(
  "APP/FILTER/SET_ADMIN_APPROVAL_STATUS_FILTER"
)<string[] | undefined>();
export const SetDepartmentsInvolvedFilter = defineAction(
  "APP/FILTER/SET_DEPARTMENTS_INVOLVED"
)<string[] | undefined>();
export const SetTypeFilter = defineAction("APP/FILTER/SET_TYPE_FILTER")<
  string[] | undefined
>();
export const SetTagFilter = defineAction("APP/FILTER/SET_TAG_FILTER")<
  string[] | undefined
>();

export const SetAssigneeFilter = defineAction("APP/FILTER/SET_ASSIGNEE_FILTER")<
  string[] | undefined
>();
export const SetOrderFilter = defineAction("APP/FILTER/SET_ORDER_FILTER")<
  string | undefined
>();
export const SetSearchFilter = defineAction("APP/FILTER/SET_SEARCH_FILTER")<
  string | undefined
>();

// reducer
export const filterReducer: any = TypedReducer.builder<IFilterState>()
  .withHandler(SetStatusFilter.TYPE, (state, status) =>
    setWith(state, { status })
  )
  .withHandler(SetBookingStatusFilter.TYPE, (state, bookingStatus) =>
    setWith(state, { bookingStatus })
  )
  .withHandler(SetCompletionStatusFilter.TYPE, (state, completionStatus) =>
    setWith(state, { completionStatus })
  )
  .withHandler(
    SetCommunityEventTasksAssignedToMe.TYPE,
    (state, communityEventTasksAssignedToMe) =>
      setWith(state, { communityEventTasksAssignedToMe })
  )
  .withHandler(
    SetCommunityEventTasksAssignedByMe.TYPE,
    (state, communityEventTasksAssignedByMe) =>
      setWith(state, { communityEventTasksAssignedByMe })
  )
  .withHandler(
    SetCommunityEventTaskOrderFilter.TYPE,
    (state, communityEventTaskOrder) =>
      setWith(state, { communityEventTaskOrder })
  )
  .withHandler(
    SetCommunityEventTaskTypeFilter.TYPE,
    (state, communityEventTaskType) =>
      setWith(state, { communityEventTaskType })
  )
  .withHandler(SetFacilityFilter.TYPE, (state, facilities) =>
    setWith(state, { facilities })
  )
  .withHandler(SetFacilityTypesFilter.TYPE, (state, facilityTypes) =>
    setWith(state, { facilityTypes })
  )
  .withHandler(SetVenueTypesFilter.TYPE, (state, venueTypes) =>
    setWith(state, { venueTypes })
  )
  .withHandler(SetVenueFilter.TYPE, (state, venues) =>
    setWith(state, { venues })
  )
  .withHandler(SetPaymentStatusFilter.TYPE, (state, paymentStatus) =>
    setWith(state, { paymentStatus })
  )
  .withHandler(SetDateFilter.TYPE, (state, date) => setWith(state, { date }))
  .withHandler(
    SetAdminApprovalStatusFilter.TYPE,
    (state, adminApprovalStatus) => setWith(state, { adminApprovalStatus })
  )
  .withHandler(
    SetDepartmentsInvolvedFilter.TYPE,
    (state, departmentsInvolved) => setWith(state, { departmentsInvolved })
  )
  .withHandler(SetOnlyShowMyReservationsPublicCalendar.TYPE, (state, value) =>
    setWith(state, { onlyShowMyReservationsOnPublicCalendar: value })
  )
  .withHandler(SetTypeFilter.TYPE, (state, type) => setWith(state, { type }))
  .withHandler(SetOrderFilter.TYPE, (state, order) => setWith(state, { order }))
  .withHandler(SetTagFilter.TYPE, (state, tag) => setWith(state, { tag }))
  .withHandler(SetAssigneeFilter.TYPE, (state, assignee) =>
    setWith(state, { assignee })
  )
  .withHandler(SetSearchFilter.TYPE, (state, search) =>
    setWith(state, { search })
  )
  .withHandler(ResetFilterState.TYPE, (state) =>
    update(state, { $set: initalFilterState })
  )
  .withDefaultHandler((state) => (state ? state : initalFilterState))
  .build();

// init
export const initalFilterState: IFilterState = {
  type: [],
  status: [],
  facilities: [],
  venues: [],
  facilityTypes: [],
  venueTypes: [],
  bookingStatus: [],
  completionStatus: [],
  paymentStatus: [],
  adminApprovalStatus: [],
  date: [null, null],
  search: undefined,
  departmentsInvolved: cityDepartments,
};

// selectors

export const hasFiltersSetSelector = Reselect.createSelector(
  (state: IAppState) => state.filter,
  (filterState: IFilterState) => {
    return !isEqual(filterState, initalFilterState);
  }
);

export const facilitiesFilterSelector = Reselect.createSelector(
  (state: IAppState) => state.filter.facilities,
  (facilities: FacilityPropertiesFragment[]) => {
    return facilities;
  }
);
export const facilityTypesFilterSelector = Reselect.createSelector(
  (state: IAppState) => state.filter.facilityTypes,
  (faciltyTypes: (FacilityType | Facilities_Types_Enum)[]) => {
    return faciltyTypes;
  }
);

export const venueTypesFilterSelector = Reselect.createSelector(
  (state: IAppState) => state.filter.venueTypes,
  (venueTypes: Venue_Types_Enum[]) => {
    return venueTypes;
  }
);
export const onlyShowMyReservationsPublicCalendarSelector =
  Reselect.createSelector(
    (state: IAppState) => state.filter.onlyShowMyReservationsOnPublicCalendar,
    (onlyShowMyReservationsOnPublicCalendar: boolean | undefined) => {
      return !!onlyShowMyReservationsOnPublicCalendar;
    }
  );
export const venuesFilterSelector = Reselect.createSelector(
  (state: IAppState) => state.filter.venues,
  (venues: VenuePropertiesFragment[]) => {
    return venues;
  }
);
export const searchFilterSelector = Reselect.createSelector(
  (state: IAppState) => state.filter.search,
  (searchFilter: string | undefined) => {
    return searchFilter;
  }
);

export const typeFilterSelector = Reselect.createSelector(
  (state: IAppState) => state.filter.type,
  (typeFilter: string[] | undefined) => {
    return typeFilter;
  }
);

export const tagFilterSelector = Reselect.createSelector(
  (state: IAppState) => state.filter.tag,
  (tagFilter: string[] | undefined) => {
    return tagFilter;
  }
);

export const assigneeFilterSelector = Reselect.createSelector(
  (state: IAppState) => state.filter.assignee,
  (assigneeFilter: string[] | undefined) => {
    return assigneeFilter;
  }
);

export const statusFilterSelector = Reselect.createSelector(
  (state: IAppState) => state.filter.status,
  (statusFilter: string[] | undefined) => {
    return statusFilter;
  }
);

export const bookingStatusFilterSelector = Reselect.createSelector(
  (state: IAppState) => state.filter.bookingStatus,
  (statusFilter: Booking_Statuses_Enum[] | undefined) => {
    return statusFilter;
  }
);

export const completionStatusFilterSelector = Reselect.createSelector(
  (state: IAppState) => state.filter.completionStatus,
  (statusFilter: Completion_Status_Enum[] | undefined) => {
    return statusFilter;
  }
);

export const communityEventTaskOrderFilterSelector = Reselect.createSelector(
  (state: IAppState) => state.filter.communityEventTaskOrder,
  (taskOrder: Special_Events_Responses_Tasks_Order_By | undefined) => {
    return taskOrder;
  }
);

export const communityEventTasksAssignedToMeFilterSelector =
  Reselect.createSelector(
    (state: IAppState) => state.filter.communityEventTasksAssignedToMe,
    (communityEventTasksAssignedToMe: boolean | undefined): boolean => {
      return !!communityEventTasksAssignedToMe;
    }
  );

export const communityEventTasksAssignedByMeFilterSelector =
  Reselect.createSelector(
    (state: IAppState) => state.filter.communityEventTasksAssignedByMe,
    (communityEventTasksAssignedByMe: boolean | undefined): boolean => {
      return !!communityEventTasksAssignedByMe;
    }
  );

export const communityEventTaskTypeFilterSelector = Reselect.createSelector(
  (state: IAppState) => state.filter.communityEventTaskType,
  (taskType: Special_Events_Response_Task_Types_Enum[] | undefined) => {
    return taskType;
  }
);

export const dateFilterSelector = Reselect.createSelector(
  (state: IAppState) => state.filter.date,
  (date: [string | null, string | null]) => {
    return date;
  }
);

export const paymentStatusFilterSelector = Reselect.createSelector(
  (state: IAppState) => state.filter.paymentStatus,
  (statusFilter: string[] | undefined) => {
    return statusFilter;
  }
);

export const adminApprovalStatusFilterSelector = Reselect.createSelector(
  (state: IAppState) => state.filter.adminApprovalStatus,
  (statusFilter: string[] | undefined) => {
    return statusFilter;
  }
);

export const departmentsInvolvedFilterSelector = Reselect.createSelector(
  (state: IAppState) => state.filter.departmentsInvolved,
  (departmentsInvolvedFiler: string[] | undefined) => {
    return departmentsInvolvedFiler;
  }
);
/* Selector Hooks */
export const useFacilitiesFilterSelector = () =>
  useSelector(facilitiesFilterSelector);
export const useFacilityTypesFilterSelector = () =>
  useSelector(facilityTypesFilterSelector);
export const useVenueTypesFilterSelector = () =>
  useSelector(venueTypesFilterSelector);

export const useVenuesFilterSelector = () => useSelector(venuesFilterSelector);
export const useBookingStatusFilterSelector = () =>
  useSelector(bookingStatusFilterSelector);
export const useOnlyShowMyReservationsSchedulerSelector = () =>
  useSelector(onlyShowMyReservationsPublicCalendarSelector);

/* Setter Hooks */
export const useSetBookingStatusFilter = () => {
  const dispatch = useDispatch();
  return (bookingStatues?: Booking_Statuses_Enum[]) =>
    dispatch(SetBookingStatusFilter(bookingStatues));
};
export const useSetFacilityTypesFilter = () => {
  const dispatch = useDispatch();
  return (facilityTypes: (FacilityType | Facilities_Types_Enum)[]) =>
    dispatch(SetFacilityTypesFilter(facilityTypes ?? []));
};
export const useSetVenueTypesFilter = () => {
  const dispatch = useDispatch();
  return (venueTypes: Venue_Types_Enum[]) =>
    dispatch(SetVenueTypesFilter(venueTypes ?? []));
};
export const useSetFacilityFilter = () => {
  const dispatch = useDispatch();
  return (facilities: FacilityPropertiesFragment[]) =>
    dispatch(SetFacilityFilter(facilities));
};
