import * as Sentry from "@sentry/nextjs";
// eslint-disable-next-line import/no-named-as-default
import posthog from "posthog-js";

import config from "../common/config";
import {
  isLocalEnvironment,
  isStagingOrProd,
} from "../utils/feature_flag_utils";

async function sentryInit(orgId: string) {
  // Integrations currently only enabled on staging and prod envs
  let integrations: any[] = [];

  // Only enable Posthog integration on staging and prod
  if (isStagingOrProd()) {
    if (config.NEXT_PUBLIC_SENTRY_PROJECT_ID) {
      const sentryProjectID = Number(config.NEXT_PUBLIC_SENTRY_PROJECT_ID);
      integrations.push(
        new posthog.SentryIntegration(
          posthog,
          "kaizen-labs",
          sentryProjectID,
          undefined, // optional: but necessary if you want to set a severity allowlist
          ["error", "info"] // optional: here is set to handle captureMessage (info) and captureException (error)
        )
      );
    }
  }

  // We don't need to run Sentry on local env
  if (!isLocalEnvironment()) {
    Sentry.init({
      dsn: config.NEXT_PUBLIC_SENTRY_DSN,
      environment: config.NEXT_PUBLIC_ENV,
      integrations,
      autoSessionTracking: true,
      tracesSampleRate: 1.0,

      initialScope: {
        tags: {
          orgId,
        },
      },
    });
  } else {
    console.log(`Sentry not running on ${config.NEXT_PUBLIC_ENV}`);
  }
}

const SentryClient = async (orgId: string) => {
  if (config.NEXT_PUBLIC_SENTRY_DSN) {
    await sentryInit(orgId);
  } else {
    console.warn(`Sentry not running due to missing config`);
  }
};

export default SentryClient;
