import React from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";

import { useOrganizationCompiledLanguageMessages } from "../../hooks/useOrganizationCompiledLanguageFile";
import { localeLanguageSelector } from "../../store/locale";

interface IntlWrapperProps {
  children: React.ReactNode;
}

/**
 * This is a wrapper around the React IntlProvider that is used to pass in the language messages that the
 * hooks and provider rely on.
 */
const IntlWrapper = ({ children }: IntlWrapperProps) => {
  const localeLanguage = useSelector(localeLanguageSelector);
  const messages = useOrganizationCompiledLanguageMessages();
  return (
    <IntlProvider
      locale={localeLanguage}
      defaultLocale="en"
      messages={messages}
    >
      <>{children}</>
    </IntlProvider>
  );
};

export default IntlWrapper;
