import {
  Booking_Statuses_Enum,
  Sports_Enum,
  Time_Zones_Enum,
} from "../api/generated";

export interface SupportedActionsDiscoveryType {
  label: string;
  title: string;
  description: string;
  image_url: string;
  timeToComplete: number;
  route: string;
  comingSoon?: boolean;
}

export const daysOfTheWeek = [
  "Sun",
  "Mon",
  "Tues",
  "Wed",
  "Thurs",
  "Fri",
  "Sat",
];

export const daysOfTheWeekFull = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export type SortOrder = "asc" | "desc";

export type AdminViewMode = "all" | "upcoming" | "past";
export const daysOfTheMonthMap: Record<number, string> = {
  1: "1st",
  2: "2nd",
  3: "3rd",
  4: "4th",
  5: "5th",
  6: "6th",
  7: "7th",
  8: "8th",
  9: "9th",
  10: "10th",
  11: "11th",
  12: "12th",
  13: "13th",
  14: "14th",
  15: "15th",
  16: "16th",
  17: "18th",
  18: "18th",
  19: "19th",
  20: "20th",
  21: "21st",
  22: "22nd",
  23: "23rd",
  24: "24th",
  25: "25th",
  26: "26th",
  27: "27th",
  28: "28th",
  29: "29th",
  30: "30th",
  31: "31st",
};
export const cityDepartments = [
  "City Clerk",
  "Community Services",
  "Human Resources",
  "Parks & Recreation",
  "Finance",
  "City Manager",
  "City Council",
  "Fire",
  "Public Works",
  "Police",
];

export const cityFacilities = [
  "City Hall",
  "Senior Center",
  "Family Resource Center",
  "Police Department",
  "Public Works Corp Yard",
  "Community Services",
  "Other",
];

// enum for facility reserve button types
export enum FacilityReserveButtonType {
  Pavilion = "reserve-button-pavilion",
  Room = "reserve-button-room",
  Field = "reserve-button-field",
  Pass = "reserve-button-pass",
}

export enum UserAccountType {
  GOOGLE = "Google Account",
  FACEBOOK = "Facebook Account",
  EMAIL_PASSWORD = "Email & Password Account",
}

interface DepartmentHelpResult {
  departmentName: string;
  departmentDescription: string;
}

export const departmentHelpResults: DepartmentHelpResult[] = [
  {
    departmentName: "City Clerk",
    departmentDescription:
      "Handles all general inquiries related to permits, recreation, tourism, and maintenance.",
  },
  {
    departmentName: "Community Development",
    departmentDescription:
      "Handles commercial permitting, special events, and general planning.",
  },
  {
    departmentName: "Human Resources",
    departmentDescription:
      "Handles staff recruiting and internal people operations.",
  },
  {
    departmentName: "Community Services",
    departmentDescription:
      "Handles facility rentals, park bookings, summer camps, and outdoor fields.",
  },
  {
    departmentName: "Finance",
    departmentDescription: "Handles procurement, staff payroll, and budgeting.",
  },
  {
    departmentName: "City Council",
    departmentDescription: "The lawmaking body of the city.",
  },
  {
    departmentName: "City Manager",
    departmentDescription: "Handles executive functions of the city.",
  },
  {
    departmentName: "Fire",
    departmentDescription:
      "An external department that handles all fire related matters and emergencies.",
  },
  {
    departmentName: "Public Works",
    departmentDescription:
      "Handles rules and regulations for the built environment.",
  },
  {
    departmentName: "Police",
    departmentDescription: "Handles all police related matters.",
  },
];

export enum DiscoverEntity {
  PARK = "Park",
  PROGRAM = "Program",
  CAMP = "Camp",
  COURSE = "Course",
  EVENT = "Event",
  ROOM = "Room",
  BUSINESS = "Business",
}

export const tagColorOptions: string[] = [
  "#b60205",
  "#d93f0b",
  "#fbca04",
  "#0e8a16",
  "#006b75",
  "#1d76db",
  "#0052cc",
  "#5319e7",
];

export const reservationCols = [
  "Name",
  "Email Address",
  "Facilities",
  "Venues",
  "Date Submitted",
  "Start Date",
  "Status",
  "Payment",
  "Approval",
];

export const userNotificationCols = [
  "Notification Type",
  "Description",
  "Status",
];

export const userCols = [
  "First Name",
  "Last Name",
  "Phone Number",
  "Email Address",
  "Resident",
  "Coach",
];

export const settingsTableCols = ["Setting", "Description"];
export const reportsCol = ["Report Type", "Description"];

export const communityEventUserCols = [
  "First Name",
  "Last Name",
  "Email Address",
  "Role",
];

export const facilityCols: string[] = [
  "Name",
  "Facility Type",
  "# Venues",
  // TODO: Implement Active Bookings
  // "# Active Bookings",
  "Public visibility",
];

export const USER_DASHBOARD_MEMBERSHIPS_REGISTRATIONS_COLS = [
  "Purchase Date",
  "Registrant name",
  "Membership name",
  "Membership tier",
  "Valid dates",
  "Membership status",
  "Requirements",
] as const;
export type UserDashboardMembershipRegistrationsColumns =
  typeof USER_DASHBOARD_MEMBERSHIPS_REGISTRATIONS_COLS[number];

export const membershipCols: string[] = [
  "Membership name",
  "# Membership tiers",
  "Viewable to",
  "Active status",
  "Actions",
];

export const membershipGroupCols: string[] = [
  "Membership group",
  "# Memberships applied to",
  "Actions",
];

export const membershipTiersCols: string[] = [
  "Membership tier",
  "Membership group",
  "Price",
  "Actions",
];

export const ADMIN_DASHBOARD_MEMBERSHIPS_REGISTRATIONS_COLS = [
  "Purchase Date",
  "Member name",
  "Membership tier",
  "Payment status",
  "Card identifier",
  "Card activated",
  "Valid dates",
  "Membership status",
  "Requirements",
  "Actions",
] as const;
export type AdminDashboardMembershipRegistrationsColumns =
  typeof ADMIN_DASHBOARD_MEMBERSHIPS_REGISTRATIONS_COLS[number];

export interface DiscoverEntityType {
  title: string;
  description: string;
  location?: string;
  url: string;
  image_url: string;
  id: string;
  availableTime?: string;
  tagline?: string;
  type: DiscoverEntity;
}

export interface ActivitySelectionType {
  id: string;
  title: string;
  description: string;
  ages: string;
  availableTime: string;
  tagline: string;
  dayOfTheWeek: string;
  residentPrice: string;
  nonResidentPrice: string;
}

export interface HouseholdMemberFormData {
  first_name: string;
  last_name: string;
  date_of_birth: Date | null;
  phone_number: string;
  email: string;
}

// For scheduler grid
export const calendarHours = [
  "12AM",
  "1AM",
  "2AM",
  "3AM",
  "4AM",
  "5AM",
  "6AM",
  "7AM",
  "8AM",
  "9AM",
  "10AM",
  "11AM",
  "12PM",
  "1PM",
  "2PM",
  "3PM",
  "4PM",
  "5PM",
  "6PM",
  "7PM",
  "8PM",
  "9PM",
  "10PM",
  "11PM",
];

export const NUM_HOURS_IN_ONE_DAY = 24;
export const NUM_MINUTES_IN_ONE_HOUR = 60;
export const NUM_SECONDS_IN_ONE_MINUTE = 60;
export const NUM_MILLIS_IN_ONE_SECOND = 1000;
export const NUM_MILLIS_IN_ONE_MINUTE =
  NUM_SECONDS_IN_ONE_MINUTE * NUM_MILLIS_IN_ONE_SECOND;

export const DEFAULT_COUNTRY_CODE = "US";
export enum Day_Of_Week_Index {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export const REPORT_FAIL_TEXT =
  "Sorry! We were unable to run this report. Please contact an administrator if this issue persists.";

export const NUM_DAYS_IN_SIX_WEEK_PERIOD = 7 * 6;
export const DEFAULT_MIN_INTERVAL_MINUTES = 30;
export const DEFAULT_MAX_INTERVAL_MINUTES = 60 * 24;

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const PHONE_NUMBER_REGEX =
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

export const EMAIL_VALIDATOR_PATTERN = {
  value: EMAIL_REGEX,
  message: "Invalid e-mail address",
};

export const PHONE_NUMBER_VALIDATOR_PATTERN = {
  value: PHONE_NUMBER_REGEX,
  message: "Invalid phone number",
};
export const PERMISSIONS_ERROR_TEXT =
  "You do not have the requisite permissions to perform this action.";

export const BACK_UP_MEMBERSHIP_IMAGE_URL =
  "https://images.unsplash.com/photo-1628625194933-ac2b3c0109e7?q=80&w=3448&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

export const DEFAULT_GET_AVAILABLE_FACILITIES_BOOKING_STATUSES = [
  Booking_Statuses_Enum.Booked,
  Booking_Statuses_Enum.Pending,
];
export const GENERIC_ERROR_MESSAGE =
  "An unexpected error has occured. Please try again or contact an administrator if this continues.";

export const SPORTS_ENUM_MEMBERS = Object.values(Sports_Enum);

// Stripe's standard fee structure charges a flat fee of 30 cents per
// transaction, plus 2.9% of the total amount charged.
// See https://stripe.com/pricing
export const STRIPE_FEE_CARD_FIXED_CENTS = 30;
export const STRIPE_FEE_CARD_PERCENTAGE = 0.029;

// Stripe's ACH fee is a percentage of the total with no fixed portion, 1¢ at a
// minimum and capped at $5
// See https://stripe.com/pricing See
// https://support.stripe.com/questions/rounding-rules-for-stripe-fees
export const STRIPE_FEE_ACH_FIXED_CENTS = 0;
export const STRIPE_FEE_ACH_PERCENTAGE = 0.008;
export const STRIPE_FEE_ACH_MINIMUM = 1;
export const STRIPE_FEE_ACH_MAXIMUM = 500;

export const DEFAULT_TIME_ZONE: Time_Zones_Enum = Time_Zones_Enum.Denver;

export const NA_STRING = "N/A";
