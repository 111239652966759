import { defineAction, setWith, TypedReducer } from "redoodle";
import * as Reselect from "reselect";

import { IAppState } from "./app";

// model
export interface IDiscoveryState {
  selectedImageIndex: number;
}

// actions
export const SetSelectedImageIndex = defineAction(
  "APP/DISCOVERY/SET_SELECTED_IMAGE_INDEX"
)<number>();
export const ResetDiscovery = defineAction("APP/DISCOVERY/RESET_DISCOVERY")();

// reducer
export const discoveryReducer: any = TypedReducer.builder<IDiscoveryState>()
  .withHandler(SetSelectedImageIndex.TYPE, (state, selectedImageIndex) =>
    setWith(state, { selectedImageIndex })
  )
  .withHandler(ResetDiscovery.TYPE, (state) =>
    setWith(state, {
      selectedImageIndex: 0,
    })
  )
  .withDefaultHandler((state) => (state ? state : initialDiscoveryState))
  .build();

// init
export const initialDiscoveryState: IDiscoveryState = {
  selectedImageIndex: 0,
};

// selectors
export const selectedImageIndexSelector = Reselect.createSelector(
  (state: IAppState) => state.discovery.selectedImageIndex,
  (selectedImageIndex: number) => {
    return selectedImageIndex;
  }
);
