import update from "immutability-helper";
import { defineAction, setWith, TypedReducer } from "redoodle";
import * as Reselect from "reselect";

import * as ActivityForms from "../components/shared/Programs/Manager/Activities/Forms/exports";
import * as ProgramForms from "../components/shared/Programs/Manager/Forms/exports";
import { IAppState } from "./app";

// model
export interface IProgramsManagementState {
  programGeneralInformation: ProgramForms.ProgramGeneralInformationFormData | null;
  programDefaults: ProgramForms.ProgramDefaultsFormData | null;
  programDisclaimers: ProgramForms.ProgramDisclaimersFormData | null;
  programFormDisclaimers: ProgramForms.ProgramFormAssociationsFormData | null;
  programFormDocuments: ProgramForms.ProgramFormAssociationsFormData | null;
  activityGeneralInformation: ActivityForms.ActivityGeneralInformationFormData | null;
  activityScheduleAndLocation: ActivityForms.ActivityScheduleAndLocationFormData | null;
  activityRegistration: ActivityForms.ActivityRegistrationFormData | null;
  activityPricing: ActivityForms.ActivityPricingFormData | null;
}

// actions
export const ResetProgramsManagementState = defineAction(
  "APP/PROGRAMS_MANAGEMENT/RESET_STATE"
)();

export const ResetProgramFormData = defineAction(
  "APP/PROGRAMS_MANAGEMENT/RESET_PROGRAM_FORM_DATA"
)();

export const ResetActivityFormData = defineAction(
  "APP/PROGRAMS_MANAGEMENT/RESET_ACTIVITY_FORM_DATA"
)();

export const SetProgramGeneralInformation = defineAction(
  "APP/PROGRAMS_MANAGEMENT/SET_PROGRAM_GENERAL_INFORMATION"
)<ProgramForms.ProgramGeneralInformationFormData>();

export const SetProgramDefaults = defineAction(
  "APP/PROGRAMS_MANAGEMENT/SET_PROGRAM_DEFAULTS"
)<ProgramForms.ProgramDefaultsFormData>();

// Old version, which creates forms directly using name/description
export const SetProgramDisclaimers = defineAction(
  "APP/PROGRAMS_MANAGEMENT/SET_PROGRAM_DISCLAIMERS"
)<ProgramForms.ProgramDisclaimersFormData>();

// New version, which associates existing forms to program using form IDs
export const SetProgramFormDisclaimers = defineAction(
  "APP/PROGRAMS_MANAGEMENT/SET_PROGRAM_FORM_DISCLAIMERS"
)<ProgramForms.ProgramFormAssociationsFormData>();

export const SetProgramFormDocuments = defineAction(
  "APP/PROGRAMS_MANAGEMENT/SET_PROGRAM_FORM_DOCUMENTS"
)<ProgramForms.ProgramFormAssociationsFormData>();

export const SetActivityGeneralInformation = defineAction(
  "APP/PROGRAMS_MANAGEMENT/SET_ACTIVITY_GENERAL_INFORMATION"
)<ActivityForms.ActivityGeneralInformationFormData>();

export const SetActivityScheduleAndLocation = defineAction(
  "APP/PROGRAMS_MANAGEMENT/SET_ACTIVITY_SCHEDULE_AND_LOCATION"
)<ActivityForms.ActivityScheduleAndLocationFormData>();

export const SetActivityRegistration = defineAction(
  "APP/PROGRAMS_MANAGEMENT/SET_ACTIVITY_REGISTRATION"
)<ActivityForms.ActivityRegistrationFormData>();

export const SetActivityPricing = defineAction(
  "APP/PROGRAMS_MANAGEMENT/SET_ACTIVITY_PRICING"
)<ActivityForms.ActivityPricingFormData>();

// reducer
export const programsManagementReducer: any =
  TypedReducer.builder<IProgramsManagementState>()
    .withHandler(
      SetProgramGeneralInformation.TYPE,
      (state, programGeneralInformation) =>
        setWith(state, { programGeneralInformation })
    )
    .withHandler(SetProgramDefaults.TYPE, (state, programDefaults) =>
      setWith(state, { programDefaults })
    )
    .withHandler(SetProgramDisclaimers.TYPE, (state, programDisclaimers) =>
      setWith(state, { programDisclaimers })
    )
    .withHandler(
      SetProgramFormDisclaimers.TYPE,
      (state, programFormDisclaimers) =>
        setWith(state, { programFormDisclaimers })
    )
    .withHandler(SetProgramFormDocuments.TYPE, (state, programFormDocuments) =>
      setWith(state, { programFormDocuments })
    )
    .withHandler(
      SetActivityGeneralInformation.TYPE,
      (state, activityGeneralInformation) =>
        setWith(state, { activityGeneralInformation })
    )
    .withHandler(
      SetActivityScheduleAndLocation.TYPE,
      (state, activityScheduleAndLocation) =>
        setWith(state, { activityScheduleAndLocation })
    )
    .withHandler(SetActivityRegistration.TYPE, (state, activityRegistration) =>
      setWith(state, { activityRegistration })
    )
    .withHandler(SetActivityPricing.TYPE, (state, activityPricing) =>
      setWith(state, { activityPricing })
    )
    .withHandler(ResetProgramFormData.TYPE, (state) =>
      setWith(state, {
        programGeneralInformation: null,
        programDefaults: null,
        programDisclaimers: null,
        programFormDisclaimers: null,
        programFormDocuments: null,
      })
    )
    .withHandler(ResetActivityFormData.TYPE, (state) =>
      setWith(state, {
        activityGeneralInformation: null,
        activityScheduleAndLocation: null,
        activityRegistration: null,
        activityPricing: null,
      })
    )
    .withHandler(ResetProgramsManagementState.TYPE, (state) =>
      update(state, { $set: initialProgramsManagementState })
    )
    .withDefaultHandler((state) =>
      state ? state : initialProgramsManagementState
    )
    .build();

// init
export const initialProgramsManagementState: IProgramsManagementState = {
  programGeneralInformation: null,
  programDefaults: null,
  programDisclaimers: null,
  programFormDisclaimers: null,
  programFormDocuments: null,
  activityGeneralInformation: null,
  activityScheduleAndLocation: null,
  activityRegistration: null,
  activityPricing: null,
};

// selectors
export const programsManagementSelector = Reselect.createSelector(
  (state: IAppState) => state.programsManagement,
  (data: IProgramsManagementState) => {
    return data;
  }
);
