import update from "immutability-helper";
import { defineAction, setWith, TypedReducer } from "redoodle";
import * as Reselect from "reselect";

import { CitizenInquiryFormData, UserFile } from "../constants/types";
import { IAppState } from "./app";

// model
export interface IInquiryState {
  activeStep: number;
  inquiryFiles: UserFile[];
  citizenInquiryFormData?: CitizenInquiryFormData;
  participant_consent: boolean;
}

// actions
export const ResetCitizenInquiryState = defineAction(
  "APP/INQUIRY/RESET_CITIZEN_INQUIRY_STATE"
)();
export const SetCitizenInquiryFiles = defineAction(
  "APP/INQUIRY/SET_CITIZEN_INQUIRY_FILE"
)<UserFile[]>();
export const SetCitizenInquiryParticipantConsent = defineAction(
  "APP/INQUIRY/SET_PARTICIPANT_CONSENT"
)<boolean>();
export const AddCitizenInquiryFiles = defineAction("APP/INQUIRY/ADD_FILE")<
  UserFile[]
>();
export const RemoveCitizenInquiryFile = defineAction(
  "APP/INQUIRY/REMOVE_FILE"
)<number>();
export const SetCitizenInquiryActiveStep = defineAction(
  "APP/INQUIRY/SET_ACTIVE_STEP"
)<number>();
export const SetCitizenInquiryFormData = defineAction(
  "APP/INQUIRY/SET_CITIZEN_INQUIRY_FORM_DATA"
)<CitizenInquiryFormData | undefined>();

// reducer
export const inquiryReducer: any = TypedReducer.builder<IInquiryState>()
  .withHandler(
    SetCitizenInquiryFormData.TYPE,
    (state, citizenInquiryFormData) =>
      setWith(state, { citizenInquiryFormData })
  )
  .withHandler(SetCitizenInquiryActiveStep.TYPE, (state, activeStep) =>
    setWith(state, { activeStep })
  )
  .withHandler(
    SetCitizenInquiryParticipantConsent.TYPE,
    (state, participant_consent) => setWith(state, { participant_consent })
  )
  .withHandler(SetCitizenInquiryFiles.TYPE, (state, inquiryFiles) =>
    setWith(state, { inquiryFiles: inquiryFiles })
  )
  .withHandler(AddCitizenInquiryFiles.TYPE, (state, inquiryFiles) =>
    update(state, { inquiryFiles: { $push: inquiryFiles } })
  )
  .withHandler(RemoveCitizenInquiryFile.TYPE, (state, index) =>
    update(state, { inquiryFiles: { $splice: [[index, 1]] } })
  )
  .withHandler(ResetCitizenInquiryState.TYPE, (state) =>
    update(state, { $set: initialInquiryState })
  )
  .withDefaultHandler((state) => (state ? state : initialInquiryState))
  .build();

// init
export const initialInquiryState: IInquiryState = {
  inquiryFiles: [],
  citizenInquiryFormData: undefined,
  activeStep: 0,
  participant_consent: true,
};

// selectors
export const citizenInquiryFormDataSelector = Reselect.createSelector(
  (state: IAppState) => state.inquiry.citizenInquiryFormData,
  (citizenInquiryFormData: CitizenInquiryFormData | undefined) => {
    return citizenInquiryFormData;
  }
);

export const citizenInquiryActiveStepSelector = Reselect.createSelector(
  (state: IAppState) => state.inquiry.activeStep,
  (activeStep: number) => {
    return activeStep;
  }
);

export const citizenInquiryParticipantConsentSelector = Reselect.createSelector(
  (state: IAppState) => state.inquiry.participant_consent,
  (participant_consent: boolean) => {
    return participant_consent;
  }
);

export const citizenInquiryFilesSelector = Reselect.createSelector(
  (state: IAppState) => state.inquiry.inquiryFiles,
  (inquiryFiles: UserFile[]) => {
    return inquiryFiles;
  }
);
