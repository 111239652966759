export function firstOrNull<T>(arr: T[] | null | undefined): T | null {
  if (!arr) {
    return null;
  }

  return arr.length > 0 ? arr[0] : null;
}

export function lastOrNull<T>(arr: T[] | null | undefined): T | null {
  if (!arr) {
    return null;
  }

  return arr.length > 0 ? arr[arr.length - 1] : null;
}

export function compact<T>(arr: (T | null | undefined)[]): T[] {
  return arr.filter((x): x is T => x !== null && x !== undefined);
}

export function unique<T>(arr: T[]): T[] {
  return [...new Set(arr)];
}

export function partition<T>(
  arr: T[],
  predicate: (value: T) => boolean
): [T[], T[]] {
  return arr.reduce(
    (acc, value) => {
      acc[predicate(value) ? 0 : 1].push(value);
      return acc;
    },
    [[], []] as [T[], T[]]
  );
}
