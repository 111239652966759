import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import usePrevious from "../hooks/usePrevious";

export function useScrollPosition() {
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);
  return scrollPosition;
}

export function useScrollToTop() {
  const router = useRouter();
  const location = router.pathname;
  const prevLocation = usePrevious(location);

  useEffect(() => {
    if (location !== prevLocation) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location, prevLocation]);
}

export function scrollElementIntoView(elementId: string) {
  document.getElementById(elementId)?.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
}
