import update from "immutability-helper";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { defineAction, setWith, TypedReducer } from "redoodle";
import * as Reselect from "reselect";

import {
  FacilityPropertiesFragment,
  Sports_Enum,
  Venue,
  VenuePortion,
  VenuePortionForDailyOutput,
} from "../api/generated";
import {
  DocumentSubmission,
  FormSubmission,
  ReservationFormData,
  ReservationRecurrenceState,
  UserFile,
  VenuePortionPriceInfo,
} from "../constants/types";
import { isoDateStringFromJSDate } from "../utils/time_utils";
import { IAppState } from "./app";

export interface SubmitReservationFormDocumentPayload
  extends DocumentSubmission {
  formSubmissionId?: string;
  formDefinitionId: string;
  venueFormId: string;
  schemaFormDefinitionId?: string;
}

export interface RemoveReservationFormDocumentPayload {
  venueFormId: string;
}

export interface ReservationFormSubmission {
  [venueFormId: string]: FormSubmission;
}

// model
export interface IReservationState {
  facilities: FacilityPropertiesFragment[];
  selectedFacilityImage?: string;
  startDate: string | undefined;
  endDate: string | undefined;
  isForInternalUse: boolean;
  isOnBehalfOf: boolean;
  resident: boolean;
  coach?: boolean;
  organization: boolean;
  reservationFiles: UserFile[];
  formData?: ReservationFormData;
  selectedVenue?: Venue;
  selectedVenuePortions?: (VenuePortion | VenuePortionForDailyOutput)[];
  selectedPrices?: VenuePortionPriceInfo[];
  selectedTimeInterval?: [Date, Date];
  recurrenceState?: ReservationRecurrenceState;
  activeStep: number;
  reservationSports: Sports_Enum[];
  reservationFormSubmissions: ReservationFormSubmission;
}

// actions
export const ResetReservationState = defineAction(
  "APP/RESERVATION/RESET_RESERVATION_STATE"
)();

export const SetReservationFacilities = defineAction(
  "APP/RESERVATION/SET_RESERVATION_FACILITIES"
)<FacilityPropertiesFragment[]>();

export const SetSelectedFacilityImage = defineAction(
  "APP/RESERVATION/SET_SELECTED_FACILITY_IMAGE"
)<string | undefined>();

export const SetReservationForInternalUse = defineAction(
  "APP/RESERVATION/SET_RESERVATION_FOR_INTERNAL_USE"
)<boolean>();

export const SetReservationForOnBehalfOf = defineAction(
  "APP/RESERVATION/SET_RESERVATION_ON_BEHALF_OF"
)<boolean>();

export const SetReservationResident = defineAction(
  "APP/RESERVATION/SET_RESERVATION_RESIDENT"
)<boolean>();

export const SetReservationActiveStep = defineAction(
  "APP/RESERVATION/SET_RESERVATION_ACTIVE_STEP"
)<number>();

export const SetReservationRecurrenceState = defineAction(
  "APP/RESERVATION/SET_RESERVATION_RECURRENCE_STATE"
)<ReservationRecurrenceState | undefined>();

export const SetReservationSelectedVenue = defineAction(
  "APP/RESERVATION/SET_RESERVATION_SELECTED_VENUE"
)<Venue | undefined>();

export const SetReservationSelectedVenuePortions = defineAction(
  "APP/RESERVATION/SET_RESERVATION_SELECTED_VENUE_PORTIONS"
)<(VenuePortion | VenuePortionForDailyOutput)[] | undefined>();

export const SetReservationSelectedPrices = defineAction(
  "APP/RESERVATION/SET_RESERVATION_SELECTED_PRICE"
)<VenuePortionPriceInfo[] | undefined>();

export const SetReservationSelectedVenuePortionTimeInterval = defineAction(
  "APP/RESERVATION/SET_RESERVATION_SELECTED_VENUE_PORTION_TIME_INTERVAL"
)<[Date, Date] | undefined>();

export const SetReservationCoach = defineAction(
  "APP/RESERVATION/SET_RESERVATION_COACH"
)<boolean | undefined>();

export const SetReservationOrganization = defineAction(
  "APP/RESERVATION/SET_RESERVATION_ORGANIZATION"
)<boolean>();

export const SetReservationStartDate = defineAction(
  "APP/RESERVATION/SET_RESERVATION_START_DATE"
)<string | undefined>();

export const SetReservationEndDate = defineAction(
  "APP/RESERVATION/SET_RESERVATION_END_DATE"
)<string | undefined>();

export const SetReservationFormData = defineAction(
  "APP/RESERVATION/SET_RESERVATION_FORM_DATA"
)<ReservationFormData | undefined>();

export const SetReservationFiles = defineAction(
  "APP/RESERVATION/SET_RESERVATION_FILES"
)<UserFile[]>();

export const SetReservationSports = defineAction(
  "APP/RESERVATION/SET_RESERVATION_SPORTS"
)<Sports_Enum[]>();

export const AddReservationFiles = defineAction("APP/RESERVATION/ADD_FILE")<
  UserFile[]
>();

export const RemoveReservationFiles = defineAction(
  "APP/RESERVATION/REMOVE_FILE"
)<number>();

export const SubmitReservationFormDocument = defineAction(
  "APP/RESERVATION/SUBMIT_RESERVATION_FORM_DOCUMENT"
)<SubmitReservationFormDocumentPayload>();

export const RemoveReservationFormDocument = defineAction(
  "APP/RESERVATION/REMOVE_RESERVATION_FORM_DOCUMENT"
)<RemoveReservationFormDocumentPayload>();

// reducer
export const reservationReducer: any = TypedReducer.builder<IReservationState>()
  .withHandler(SetReservationFacilities.TYPE, (state, facilities) =>
    setWith(state, { facilities })
  )
  .withHandler(SetReservationStartDate.TYPE, (state, startDate) =>
    setWith(state, { startDate })
  )
  .withHandler(SetReservationEndDate.TYPE, (state, endDate) =>
    setWith(state, { endDate })
  )
  .withHandler(
    SetReservationSelectedVenuePortionTimeInterval.TYPE,
    (state, selectedTimeInterval) => setWith(state, { selectedTimeInterval })
  )
  .withHandler(SetSelectedFacilityImage.TYPE, (state, selectedFacilityImage) =>
    setWith(state, { selectedFacilityImage })
  )
  .withHandler(SetReservationSelectedVenue.TYPE, (state, selectedVenue) =>
    setWith(state, { selectedVenue })
  )
  .withHandler(
    SetReservationSelectedVenuePortions.TYPE,
    (state, selectedVenuePortion) =>
      setWith(state, { selectedVenuePortions: selectedVenuePortion })
  )
  .withHandler(SetReservationForInternalUse.TYPE, (state, isForInternalUse) =>
    setWith(state, { isForInternalUse })
  )
  .withHandler(SetReservationForOnBehalfOf.TYPE, (state, isOnBehalfOf) =>
    setWith(state, { isOnBehalfOf })
  )
  .withHandler(SetReservationResident.TYPE, (state, resident) =>
    setWith(state, { resident })
  )
  .withHandler(SetReservationCoach.TYPE, (state, coach) =>
    setWith(state, { coach })
  )
  .withHandler(SetReservationOrganization.TYPE, (state, organization) =>
    setWith(state, { organization })
  )
  .withHandler(SetReservationActiveStep.TYPE, (state, activeStep) =>
    setWith(state, { activeStep })
  )
  .withHandler(SetReservationRecurrenceState.TYPE, (state, recurrenceState) =>
    setWith(state, { recurrenceState })
  )
  .withHandler(SetReservationSelectedPrices.TYPE, (state, selectedPrices) =>
    setWith(state, { selectedPrices })
  )
  .withHandler(SetReservationFormData.TYPE, (state, formData) =>
    setWith(state, { formData })
  )
  .withHandler(ResetReservationState.TYPE, (state) =>
    update(state, { $set: initialReservationState })
  )
  .withHandler(SetReservationFiles.TYPE, (state, reservationFiles) =>
    setWith(state, { reservationFiles: reservationFiles })
  )
  .withHandler(SetReservationSports.TYPE, (state, reservationSports) =>
    setWith(state, { reservationSports })
  )
  .withHandler(AddReservationFiles.TYPE, (state, reservationFiles) =>
    update(state, { reservationFiles: { $push: reservationFiles } })
  )
  .withHandler(RemoveReservationFiles.TYPE, (state, index) =>
    update(state, { reservationFiles: { $splice: [[index, 1]] } })
  )
  .withHandler(
    SubmitReservationFormDocument.TYPE,
    (
      state,
      {
        formSubmissionId,
        venueFormId,
        formDefinitionId,
        file,
        existingDocumentId,
        schemaFormDefinitionId,
        schemaFormData,
      }
    ) => {
      return setWith(state, {
        ...state,
        reservationFormSubmissions: {
          ...state.reservationFormSubmissions,
          [venueFormId]: {
            formSubmissionId: formSubmissionId,
            formId: venueFormId,
            existingDocumentId,
            newDocument: file,
            formDefinitionId,
            venueFormId,
            schemaFormDefinitionId,
            schemaFormData,
          },
        },
      });
    }
  )
  .withHandler(RemoveReservationFormDocument.TYPE, (state, { venueFormId }) => {
    const reservationFormSubmissions = state.reservationFormSubmissions;
    delete reservationFormSubmissions[venueFormId];

    return setWith(state, {
      ...state,
      reservationFormSubmissions,
    });
  })
  .withDefaultHandler((state) => (state ? state : initialReservationState))
  .build();

// init
export const initialReservationState: IReservationState = {
  isForInternalUse: false,
  isOnBehalfOf: false,
  facilities: [],
  startDate: isoDateStringFromJSDate(new Date()),
  endDate: isoDateStringFromJSDate(DateTime.now().plus({ days: 1 }).toJSDate()),
  resident: false,
  organization: false,
  reservationFiles: [],
  activeStep: 1,
  reservationSports: [],
  reservationFormSubmissions: {},
};

// selectors
export const reservationFacilitiesSelector = Reselect.createSelector(
  (state: IAppState) => state.reservation.facilities,
  (facilities: FacilityPropertiesFragment[]) => {
    return facilities;
  }
);

export const reservationActiveStepSelector = Reselect.createSelector(
  (state: IAppState) => state.reservation.activeStep,
  (activeStep: number) => {
    return activeStep;
  }
);

export const reservationFacilityImageSelector = Reselect.createSelector(
  (state: IAppState) => state.reservation.selectedFacilityImage,
  (facilityImage: string | undefined) => {
    return facilityImage;
  }
);

// Return start date of reservation in ISO format (YYYY-MM-DD)
export const reservationStartDateSelector = Reselect.createSelector(
  (state: IAppState) => state.reservation.startDate,
  (date: string | undefined) => {
    return date;
  }
);

// Return end date of reservation in ISO format (YYYY-MM-DD)
export const reservationEndDateSelector = Reselect.createSelector(
  (state: IAppState) => state.reservation.endDate,
  (endDate: string | undefined) => {
    return endDate;
  }
);

export const reservationForInternalUseSelector = Reselect.createSelector(
  (state: IAppState) => state.reservation.isForInternalUse,
  (isForInternalUse: boolean) => {
    return isForInternalUse;
  }
);

export const reservationOnBehalfOf = Reselect.createSelector(
  (state: IAppState) => state.reservation.isOnBehalfOf,
  (isOnBehalfOf: boolean) => {
    return isOnBehalfOf;
  }
);

export const reservationResidentSelector = Reselect.createSelector(
  (state: IAppState) => state.reservation.resident,
  (resident: boolean) => {
    return resident;
  }
);

export const reservationSelectedPricesSelector = Reselect.createSelector(
  (state: IAppState) => state.reservation.selectedPrices,
  (selectedPrice: VenuePortionPriceInfo[] | undefined) => {
    return selectedPrice;
  }
);

export const reservationRecurrenceStateSelector = Reselect.createSelector(
  (state: IAppState) => state.reservation.recurrenceState,
  (recurrenceState: ReservationRecurrenceState | undefined) => {
    return recurrenceState;
  }
);

export const reservationCoachSelector = Reselect.createSelector(
  (state: IAppState) => state.reservation.coach,
  (coach: boolean | undefined) => {
    return coach;
  }
);

export const reservationOrganizationSelector = Reselect.createSelector(
  (state: IAppState) => state.reservation.organization,
  (organization: boolean) => {
    return organization;
  }
);

export const reservationSelectedVenueSelector = Reselect.createSelector(
  (state: IAppState) => state.reservation.selectedVenue,
  (selectedVenue: Venue | undefined) => {
    return selectedVenue;
  }
);

export const reservationVenuePortionsSelector = Reselect.createSelector(
  (state: IAppState) => state.reservation.selectedVenuePortions,
  (
    venuePortions: (VenuePortion | VenuePortionForDailyOutput)[] | undefined
  ) => {
    return venuePortions;
  }
);

export const reservationTimeIntervalSelector = Reselect.createSelector(
  (state: IAppState) => state.reservation.selectedTimeInterval,
  (selectedTimeInterval: [Date, Date] | undefined) => {
    return selectedTimeInterval;
  }
);

export const reservationFormDataSelector = Reselect.createSelector(
  (state: IAppState) => state.reservation.formData,
  (formData: ReservationFormData | undefined) => {
    return formData;
  }
);

export const reservationFilesSelector = Reselect.createSelector(
  (state: IAppState) => state.reservation.reservationFiles,
  (reservationFiles: UserFile[]) => {
    return reservationFiles;
  }
);

export const reservationSportsSelector = Reselect.createSelector(
  (state: IAppState) => state.reservation.reservationSports,
  (reservationSports: Sports_Enum[]) => {
    return reservationSports;
  }
);

export const reservationFormSubmissionsSelector = Reselect.createSelector(
  (state: IAppState) => state.reservation.reservationFormSubmissions,
  (reservationFormSubmissions) => {
    return reservationFormSubmissions;
  }
);

export const reservationFormSubmissionsForFormSelector =
  Reselect.createSelector(
    [
      (state: IAppState) => state.reservation.reservationFormSubmissions,
      (state: IAppState, formId: string) => formId,
    ],
    (reservationFormSubmissions, formId) =>
      reservationFormSubmissions?.[formId] ?? null
  );

// Selector hooks
export const useReservationDateSelector = () =>
  useSelector(reservationStartDateSelector);
export const useReservationEndDateSelector = () =>
  useSelector(reservationEndDateSelector);
export const useReservationSelectedVenueSelector = () =>
  useSelector(reservationSelectedVenueSelector);
export const useReservationVenuePortionsSelector = () =>
  useSelector(reservationVenuePortionsSelector);
export const useSetReservationDate = () => {
  const dispatch = useDispatch();
  return (newDate: string) => dispatch(SetReservationStartDate(newDate));
};
export const useSetReservationEndDate = () => {
  const dispatch = useDispatch();
  return (newDate: string) => dispatch(SetReservationEndDate(newDate));
};
export const useReservationSportsSelector = () => {
  const sports = useSelector(reservationSportsSelector);
  return sports;
};
export const useSetReservationSportsFilter = () => {
  const dispatch = useDispatch();
  return (sports: Sports_Enum[]) => dispatch(SetReservationSports(sports));
};
export const useSetReservationFacilitiesFilter = () => {
  const dispatch = useDispatch();
  return (facilities: FacilityPropertiesFragment[]) =>
    dispatch(SetReservationFacilities(facilities));
};
export const useReservationForInternalUse = () =>
  useSelector(reservationForInternalUseSelector);
export const useReservationForOnBehalfOf = () =>
  useSelector(reservationOnBehalfOf);
