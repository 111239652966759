/**
 * Takes in an organization slug and outputs the domain name for that organization
 *
 * @param organizationSlug the slug field off a row in the organizations table
 */
export function organizationToDomain(
  organizationSlug: string | undefined
): string | undefined {
  if (!organizationSlug) {
    return "";
  }

  switch (organizationSlug) {
    case "westminster":
      return "westminster.kaizenlabs.co";
    case "castlepines":
      return "parks.castlepinesco.gov";
    case "sanantonio":
      return "smartertogethersa.com";
    case "santarosa":
      return "santarosa.kaizenlabs.co";
    case "parker":
      return "communityevents.parkeronline.org";
    case "metroparks":
      return "recreation.yourmetroparks.net";
    case "weymouth":
      return "weyrec.com";
    case "montereypark":
      return "rec.montereypark.ca.gov";
    case "crownmountain":
      return "reservations.crownmtn.org";
    case "mountainhouse":
      return "mountainhouse.kaizenlabs.co";
    case "ccmrd":
      return "reservations.clearcreekrecreation.com";
    case "pima":
      return "parksandrecreation.pima.gov";
    case "sarasota":
      return "reservations.letsplaysarasota.com";
    case "tulare":
      return "parks.tularecounty.ca.gov";
    case "kencaryl":
      return "recreation.ken-carylranch.org";
    case "redwoodcity":
      return "tickets.rwcpaf.org";
    case "northville":
      return "reservations.northvilleparksandrec.org";
    default:
      return `${organizationSlug}.kaizenlabs.co`;
  }
}

export function mapOrganizationSlugToBucketName(slug: string) {
  switch (slug) {
    case "castlepines":
      return "castle_pines_assets";
    case "westminster":
      return "westminster_assets";
    case "weymouth":
      return "weymouth_assets";
    case "santarosa":
      return "santarosa_assets";
    case "mountainhouse":
      return "mountain_house_assets";
    case "sanantonio":
      return "san_antonio_assets";
    case "parker":
      return "parker_assets";
    case "montereypark":
      return "monterey_park_assets";
    case "evanston":
      return "evanston_assets";
    case "metroparks":
      return "metro_parks_assets";
    case "crownmountain":
      return "crown_mountain_assets";
    case "ccmrd":
      return "ccmrd_assets";
    case "pima":
      return "pima_assets";
    case "tulare":
      return "tulare_assets";
    case "kencaryl":
      return "kencaryl_assets";
    case "sarasota":
      return "sarasota_assets";
    case "redwoodcity":
      return "redwoodcity_assets";
    case "northville":
      return "northville_assets";
    default: {
      const err = new Error(`No bucket found for slug ${slug}.`);
      throw err;
    }
  }
}
