import { combineReducers, Reducer } from "redoodle";

import { authReducer, IAuthState, initialAuthState } from "./auth";
import { cartReducer, ICartState, initialCartState } from "./cart";
import {
  communityEventReducer,
  ICommunityEventState,
  initialCommunityEventState,
} from "./community-event";
import {
  discoveryReducer,
  IDiscoveryState,
  initialDiscoveryState,
} from "./discovery";
import {
  facilityManagementReducer,
  IFacilityManagementState,
  initialFacilityManagementState,
} from "./facilityManagement";
import { filterReducer, IFilterState, initalFilterState } from "./filter";
import { IInquiryState, initialInquiryState, inquiryReducer } from "./inquiry";
import { ILocaleState, initialLocaleState, localeReducer } from "./locale";
import {
  IMembershipState,
  initialMembershipState,
  membershipReducer,
} from "./membership";
import {
  initialNotificationsState,
  INotificationsState,
  notificationsReducer,
} from "./notification";
import {
  initialOrgState,
  IOrganizationState,
  organizationReducer,
} from "./organization";
import { initialPermitState, IPermitState, permitReducer } from "./permit";
import {
  initialProgramsManagementState,
  IProgramsManagementState,
  programsManagementReducer,
} from "./programsManagement";
import {
  initialReservationState,
  IReservationState,
  reservationReducer,
} from "./reservation";
import {
  IMembershipSortState,
  initialMembershipSortState,
  membershipSortReducer,
} from "./sort/memberships";
import { initialSortState, ISortState, sortReducer } from "./sort/reservations";
import {
  initialVenueManagementState,
  IVenueManagementState,
  venueManagementReducer,
} from "./venueManagement";

// model
export interface IAppState {
  permit: IPermitState;
  inquiry: IInquiryState;
  cart: ICartState;
  community_event: ICommunityEventState;
  notifications: INotificationsState;
  organization: IOrganizationState;
  reservation: IReservationState;
  auth: IAuthState;
  discovery: IDiscoveryState;
  locale: ILocaleState;
  filter: IFilterState;
  sort: ISortState;
  membership: IMembershipState;
  membershipSort: IMembershipSortState;
  facilityManagement: IFacilityManagementState;
  venueManagement: IVenueManagementState;
  programsManagement: IProgramsManagementState;
}

// reducer
export const appReducer: Reducer<IAppState> = combineReducers<IAppState>({
  permit: permitReducer,
  discovery: discoveryReducer,
  cart: cartReducer,
  inquiry: inquiryReducer,
  locale: localeReducer,
  community_event: communityEventReducer,
  reservation: reservationReducer,
  filter: filterReducer,
  sort: sortReducer,
  membership: membershipReducer,
  membershipSort: membershipSortReducer,
  notifications: notificationsReducer,
  auth: authReducer,
  organization: organizationReducer,
  facilityManagement: facilityManagementReducer,
  venueManagement: venueManagementReducer,
  programsManagement: programsManagementReducer,
});

// init
export const initialAppState = {
  permit: initialPermitState,
  organization: initialOrgState,
  cart: initialCartState,
  community_event: initialCommunityEventState,
  reservation: initialReservationState,
  discovery: initialDiscoveryState,
  locale: initialLocaleState,
  inquiry: initialInquiryState,
  filter: initalFilterState,
  sort: initialSortState,
  membership: initialMembershipState,
  membershipSort: initialMembershipSortState,
  notifications: initialNotificationsState,
  auth: initialAuthState,
  facilityManagement: initialFacilityManagementState,
  venueManagement: initialVenueManagementState,
  programsManagement: initialProgramsManagementState,
};
